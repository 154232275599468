import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { fetchVistaProvaTokensComparativo } from "../../../services/banco_redacoes";
import Loading from "../../Loading";
import ComparativeBarChart from "../graficos/ComparativeBarChart";


const initialState = {
  redacoes: [],
  correcoes: [],
  comparativo: {},
}

const VistaProvaExternaComparativo = ({ match, ...all }) => {
  const [state, setState] = useState(initialState);
  const [isloading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const { token } = match.params;

    const fetchData = async () => {
      try {
        setIsLoading(true);

        const data = await fetchVistaProvaTokensComparativo({ token });

        setState((prevState) => {
          return {
            ...prevState,
            redacoes: data.redacoes,
            correcoes: data.correcoes,
            comparativo: data.comparativo,
          };
        });
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderComparativeTable = () => {
    let tableData2 = state.correcoes[0][0].correcao_detalhada.notas_competencia;
    return (
      <div style={{ marginTop: "1rem" }}>
        <div className="tabela-gabarito">
          <table className="table-tag">
            <tbody>
              <tr className="table-header">
                <th className="table-data">Tipo</th>
                <th className="table-data">Nota</th>
                {tableData2 &&
                  tableData2.map((desvio) => (
                    <th className="table-data" >
                      {desvio.descricao}
                    </th>
                  ))}
              </tr>
              {state.redacoes.map((redacao, index) => (
                <tr className="table-row">
                  <td className="table-data">Redação {index + 1}</td>
                  <td className="table-data">{redacao.nota_final ? redacao.nota_final : "-"}</td>
                  <th className="table-data" >{redacao.nota_competencia1 ? redacao.nota_competencia1 : '-'}</th>
                  <th className="table-data" >{redacao.nota_competencia2 ? redacao.nota_competencia2 : '-'}</th>
                  <th className="table-data" >{redacao.nota_competencia3 ? redacao.nota_competencia3 : '-'}</th>
                  <th className="table-data" >{redacao.nota_competencia4 ? redacao.nota_competencia4 : '-'}</th>
                  <th className="table-data" >{redacao.nota_competencia5 ? redacao.nota_competencia5 : '-'}</th>
                </tr>
              ))}
              <tr className="table-row">
                <td className="table-data">Comparativo</td>
                <td className="table-data">
                  {state.comparativo.nota_final.diferenca_proporcional}%
                  <i
                    className={`fas ${state.comparativo.nota_final.icon}`}
                    style={{ color: `${state.comparativo.nota_final.cor}` }}
                  />
                </td>
                <td className="table-data">
                  {state.comparativo.nota_competencia1.diferenca_proporcional}%
                  <i
                    className={`fas ${state.comparativo.nota_competencia1.icon}`}
                    style={{ color: `${state.comparativo.nota_competencia1.cor}` }}
                  />
                </td>
                <td className="table-data">
                  {state.comparativo.nota_competencia2.diferenca_proporcional}%
                  <i
                    className={`fas ${state.comparativo.nota_competencia2.icon}`}
                    style={{ color: `${state.comparativo.nota_competencia2.cor}` }}
                  />
                </td>
                <td className="table-data">
                  {state.comparativo.nota_competencia3.diferenca_proporcional}%
                  <i
                    className={`fas ${state.comparativo.nota_competencia3.icon}`}
                    style={{ color: `${state.comparativo.nota_competencia3.cor}` }}
                  />
                </td>
                <td className="table-data">
                  {state.comparativo.nota_competencia4.diferenca_proporcional}%
                  <i
                    className={`fas ${state.comparativo.nota_competencia4.icon}`}
                    style={{ color: `${state.comparativo.nota_competencia4.cor}` }}
                  />
                </td>
                <td className="table-data">
                  {state.comparativo.nota_competencia5.diferenca_proporcional}%
                  <i
                    className={`fas ${state.comparativo.nota_competencia5.icon}`}
                    style={{ color: `${state.comparativo.nota_competencia5.cor}` }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>)
  }

  return (
    <>
      {isError ? <div>Erro ao carregar dados</div> :
        isloading ? <Loading /> : (
        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
          <div style={{ maxWidth: "100rem" }} className="page-container-border">
            <h1 className="text-title" style={{ marginLeft: "15px" }}>
              Vista comparativa de redações:
            </h1>
            <section style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
              <div className="col-6">
                <div>
                  <h2 className="text-title">
                    Redação 1: {state.correcoes[0][0].projeto}
                  </h2>
                </div>
                <div className="correcao" style={{borderTop: "5px solid rgba(0, 0, 0, 0.125)"}} >
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={state.correcoes[0][0]['src_imagem_original']} alt="Redacao1"
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <h2 className="text-title">
                    Redação 2: {state.correcoes[1][0].projeto}
                  </h2>
                </div>
                <div className="correcao" style={{borderTop: "5px solid rgba(0, 0, 0, 0.125)"}} >
                  <img
                    style={{
                      width: '100%',
                    }}
                    src={state.correcoes[1][0]['src_imagem_original']} alt="Redacao1"
                  />
                </div>
              </div>
            </section >
            <section style={{ padding: "15px" }}>
              {renderComparativeTable()}
              <ComparativeBarChart chartData={state.comparativo.chartData} />
            </section>
          </div>
        </div>)}
    </>
  )
}

VistaProvaExternaComparativo.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
};

export default withRouter(VistaProvaExternaComparativo);
