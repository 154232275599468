import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { fetchVistaProvaRoomData, fetchVistaProvaTokens } from "../../../services/banco_redacoes";
import CardPreVistaProva from "./CardPreVistaProva";

const VistaProvaExternaIndex = ({ match }) => {
  const { token } = match.params;
  const [tokens, setTokens] = useState([]);
  const [preVisualizacao, setPreVisualizacao] = useState([]);

  useEffect(() => {
    fetchVistaProvaTokens({ token }).then((data) => {
      setTokens(data);
    });
    fetchVistaProvaRoomData({ token }).then((data) => {
      setPreVisualizacao(data);
    });
  }, []);

  return (
    <div>
      <div className="container">
        <div className="page-container-border">
          {preVisualizacao.id && (
            <CardPreVistaProva
              nome={preVisualizacao.candidato}
              status={preVisualizacao.status_room}
              dataProva={preVisualizacao.data_termino}
              orientacoesEstudante={preVisualizacao.orientacoes}
              competencias={preVisualizacao.notas_competencia}
              notaFinal={preVisualizacao.nota_final}
              notaTotal={preVisualizacao.nota_total}
              nomeProva={preVisualizacao.projeto}
              linkProva={tokens}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(VistaProvaExternaIndex);
