import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'informed';
import Axios from 'axios';
import { TextInput } from '../..';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';
import { API_URL } from '../../../consts';
import SelectInputRedacoes from '../inputs/SelectInputRedacoes';
import { toast } from 'react-toastify';

const BancoRedacoesForm = () => {
  const [formValues, setFormValues] = useState();
  const [projeto, setProjeto] = useState();
  const [escola, setEscola] = useState();
  const [turma, setTurma] = useState();
  const [etapa, setEtapa] = useState();
  const {
    onSubmit,
    verDadosCandidato,
    fetching,
    user,
  } = useContext(BancoRedacoesContext);
  const [usuario, setUsuario] = useState();
  const [FormApi, setFormApi] = useState();

  const fetchUser = async () => {
    try {
      const params = {
        user: user.id,
      };
      const res = await Axios.get(`${API_URL}/associacao-usuario`, { params });
      if (res.data.length === 0) {
        setUsuario(null);
      } else {
        setUsuario(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProjeto = async () => {
    if (usuario && usuario[0].projeto.length > 0 && user.permissions.__all__ !== true) {
      setProjeto(usuario[0].projeto.map(x => ({
        value: x.id, label: x.descricao,
      })));
    } else {
      try {
        const res = await Axios.get(`${API_URL}/projeto/all`);
        setProjeto(res.data.map(x => ({
          value: x.id,
          label: x.descricao,
        })));
      } catch (err) {
        toast.error('Erro ao buscar projetos, tente novamente.');
      }
    }
  };

  const fetchEscolas = async () => {
    if (usuario && usuario[0].escola.length > 0 && user.permissions.__all__ !== true) {
      setEscola(usuario[0].escola.map(x => ({
        value: x.id, label: x.nome,
      })));
    } else {
      try {
        const res = await Axios.get(`${API_URL}/inep/escolas`);
        setEscola(res.data.map(x => ({
          value: x.id, label: x.nome,
        })));
      } catch (err) {
        toast.error('Erro ao buscar escolas, tente novamente.');
      }
    }
  };

  const fetchEtapas = async () => {
    if (usuario && usuario[0].etapa.length > 0 && user.permissions.__all__ !== true) {
      setEtapa(usuario[0].etapa.map(x => ({
        value: x.id, label: x.nome,
      })));
    } else {
      try {
        const res = await Axios.get(`${API_URL}/inep/etapas?projetos__id=${formValues.values.projeto}`);
        setEtapa(res.data.map(x => ({
          value: x.id, label: x.nome,
        })));
      } catch (err) {
        toast.error('Erro ao buscar etapas, tente novamente.');
      }
    }
  };

  const fetchTurmas = async () => {
    if (usuario && usuario[0].turma.length > 0 && user.permissions.__all__ !== true) {
      setTurma(usuario[0].turma.filter(x => x.etapa === parseInt(formValues.values.etapa)).map(x => ({
        value: x.id, label: x.nome,
      })));
    } else {
      try {
        const params = {
          etapa: formValues.values.etapa,
          escola: formValues.values.escola,
          projetos__id: formValues.values.projeto,
        };
        const res = await Axios.get(`${API_URL}/inep/turmas`, { params });
        setTurma(res.data.map(x => ({
          value: x.id, label: x.nome,
        })));
      } catch (err) {
        toast.error('Erro ao buscar turmas, tente novamente.');
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchProjeto();
  }, [usuario]);

  const handleChange = (field) => {
    if (field === 'Escolas') {
      FormApi.setValue('escola', '');
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEscola();
      setEtapa();
      setTurma();
      fetchEscolas();
    }
    if (field === 'Etapas') {
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEtapa();
      setTurma();
      fetchEtapas();
    }
    if (field === 'Turmas') {
      FormApi.setValue('turma', '');
      setTurma();
      fetchTurmas();
    }
  };

  const handleFocus = (fieldNecessario, fieldSelecionado) => {
    if (!formValues.values[fieldNecessario]) {
      toast.error(`Selecione o campo ${fieldNecessario} antes de selecionar o campo ${fieldSelecionado}`);
    }
  };

  return (
    <Form
      onChange={(formState) => setFormValues(formState)}
      getApi={(formApi) => setFormApi(formApi)}
      onSubmit={onSubmit}
      className="redacao-form mt-4 d-inline-block w-100"
    >
      <div className="d-flex justify-content-center w-100 mb-4">
        <div className="col card-correcoes">
          <SelectInputRedacoes
            label="Projeto"
            field="projeto"
            options={projeto}
            onChange={() => handleChange('Escolas')}
          />
        </div>
        <div className="col card-correcoes">
          <SelectInputRedacoes
            label="Escola"
            field="escola"
            options={escola}
            onChange={() => {
              fetchEtapas();
              handleChange('Etapas');
            }}
            onFocus={() => handleFocus('projeto', 'escola')}
          />
        </div>
        <div className="col card-correcoes">
          <SelectInputRedacoes
            label="Etapa"
            field="etapa"
            options={etapa}
            onChange={() => handleChange('Turmas')}
            onFocus={() => handleFocus('escola', 'etapa')}
          />
        </div>
        <div className="col card-correcoes">
          <SelectInputRedacoes
            label="Turma"
            field="turma"
            options={turma}
            onFocus={() => handleFocus('etapa', 'turma')}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center w-100 mb-4">
        <div className="col card-correcoes">
          <TextInput
            field="mascara_redacao"
            placeholder="Máscara da Redação"
            className="form-control"
          />
        </div>
        {verDadosCandidato && (
          <>
            <div className="col card-correcoes">
              <TextInput
                field="cpf"
                placeholder="CPF"
                className="form-control"
              />
            </div>
            <div className="col card-correcoes">
              <TextInput
                field="inscricao"
                placeholder="Cód. Inscrição"
                className="form-control"
              />
            </div>
          </>
        )}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button type="submit" disabled={fetching} className="btn btn-primary">
          {fetching ? 'BUSCANDO...' : 'BUSCAR'}
        </button>
      </div>
    </Form>
  );
};

export default BancoRedacoesForm;
