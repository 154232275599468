import React from "react";
import PropType from "prop-types";
import HistoricoCorrecaoHeader from "./HistoricoCorrecaoHeader";
import HistoricoCorrecaoContext from "../../../contexts/HistoricoCorrecaoContext";
import HistoricoCorrecaoContent from "./HistoricoCorrecaoContent";
import HistoricoRespostaContent from "./HistoricoRespostaContent";
import { get } from "lodash";

const HistoricoCorrecao = ({
  index,
  correcao,
  verNomeAvaliadorHistorico,
  verHeader,
}) => (
  <HistoricoCorrecaoContext.Provider
    value={{
      index,
      correcao,
      verNomeAvaliadorHistorico,
    }}
  >
    <div key={correcao.id} className="tabela-historico">
      {verHeader && <HistoricoCorrecaoHeader />}
      {get(correcao, "show_indicador_data") ? (
        <HistoricoRespostaContent />
      ) : (
        <HistoricoCorrecaoContent />
      )}
    </div>
  </HistoricoCorrecaoContext.Provider>
);

HistoricoCorrecao.propTypes = {
  verNomeAvaliadorHistorico: PropType.bool.isRequired,
  index: PropType.number.isRequired,
  verHeader: PropType.bool.isRequired,
  correcao: PropType.shape({
    id: PropType.number,
    tipo: PropType.string,
    avaliador: PropType.string,
  }).isRequired,
};

export default HistoricoCorrecao;
