import { Form } from 'informed';
import { get } from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { TextAreaInput } from '../..';
import PermissionContext from '../../../contexts/PermissionContext';
import RecursoContext from '../../../contexts/RecursoContext';
import Loading from '../../Loading';
import SelectInput from '../../SelectInput';
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext';


const RecursosDetails = () => {
  const { hasPermission } = useContext(PermissionContext);

  const {
    recurso,
    loadingRecurso,
    errors,
    formState,
    handleDeferir,
    handleChangeForm,
    handleGetApi,
    handleDivulgar,
    formApi,
    tiposDeferimento,
    submitting,
  } = useContext(RecursoContext);
  
  const shouldEnableNotaDeferida = () => {
    const tipoDeferimentoEscolhido = get(formState, 'values.tipo_deferimento');
    
    const tipoDeferimento = tiposDeferimento.find(t => +t.id === +tipoDeferimentoEscolhido);
    return get(tipoDeferimento, 'permitido_alterar_nota', false);
  };
  

  const isDisabled = () => {
    const values = get(formState, 'values', {});
    let requiredFields = {};
    if (get(recurso, 'status.acao') === 'DIVULGAR') {
      requiredFields = {
        resposta_formatada: values.resposta_formatada,
      };
    } else {
      requiredFields = {
        resposta: values.resposta,
        tipo_deferimento: values.tipo_deferimento,
      };      
    }
    return !Object.values(requiredFields).every(field => field);
  };
  

  if (loadingRecurso) {
    return <Loading />;
  }
  
  if (hasPermission('divulgar_recurso') && get(recurso, 'status.acao') === 'DIVULGAR') {
    return (
      <>
        <Form
          onSubmit={handleDivulgar}
          onChange={handleChangeForm}
          getApi={handleGetApi}
          className="mt-4"
        >
          <span className="m-0 primary-label">Resposta ao Recurso:</span>
          <TextAreaInput
            field="resposta_formatada"
            error={errors.answers}
            placeholder="Descreva a resposta ao recurso..."
            className="mt-2"
          />
          <button
            disabled={isDisabled() || submitting}
            className={`btn mt-3 bg-dark-default-color w-100
              ${isDisabled() || submitting ? 'disabled' : ''}`
            }
            type="submit"
          >
            RESPONDER
          </button>
        </Form>
        <Link
          className="d-flex align-items-center btn bg-warning-color justify-content-center w-100 mt-2"
          to="/recursos"
        >
          VOLTAR
        </Link>
      </>
    );
  }

  if (hasPermission('deferir_recurso') && get(recurso, 'status.acao') === 'DEFERIR') {
    return (
      <>
        <Form
          onSubmit={handleDeferir}
          onChange={handleChangeForm}
          getApi={handleGetApi}
        >
          <div className="mt-3">
            <span className="m-0 primary-label">Orientações ao corretor:</span>
            <TextAreaInput
              field="resposta"
              error={errors.answers}
              placeholder="Descreva as orientações..."
              className="mt-2"
            />
          </div>
          <div className="mt-3">
            <span className="m-0 primary-label">Classificação</span>
            <SelectInput
              className="mt-2"
              field="tipo_deferimento"
              options={tiposDeferimento.map(t => ({ value: t.id, label: t.nome }))}
              onChange={shouldEnableNotaDeferida}
            />
          </div>          
          <button
            disabled={isDisabled() || submitting}
            className={`btn mt-3 bg-dark-default-color w-100
              ${isDisabled() || submitting ? 'disabled' : ''}`
            }
            type="submit"
          >
            RESPONDER
          </button>
          <Link
            className="d-flex align-items-center btn bg-warning-color justify-content-center w-100 mt-2"
            to="/recursos"
          >
            VOLTAR
          </Link>
        </Form>
      </>
    );
  }

  return (
    <Link
      className="d-flex align-items-center btn bg-warning-color justify-content-center w-100 mt-2"
      to="/recursos"
    >
      VOLTAR
    </Link>
  );
};

export default RecursosDetails;
