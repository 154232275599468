import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { API_URL } from '../../../consts';
import { toast } from 'react-toastify';
import '../../../assets/css/containers/modalRecurso.css';
import { fetchRecursoResult } from '../../../services/recursos';
import { get } from 'lodash';

const ModalRecurso = ({ isOpen, onClose, dataModal, fetchSelectedPage }) => {
  const [textArea, setTextArea] = useState('');
  const [recurso, setRecurso] = useState(null);

  useEffect(() => {
    fetchModalRecurso();
  }, []);

  if (!isOpen) return null;

  const sendResource = () => {
    const params = {
      redacao: dataModal.id,
      pergunta: textArea,
    }
    Axios.post(`${API_URL}/recursos/recurso`, params)
      .then((response) => {
        toast.success('Recurso criado com sucesso!');
        onClose();
      }).catch((err) => {
        const error = err.response.data[0];
        toast.error(`Ocorreu um erro ao criar o recurso: ${error}`);
      });
    fetchModalRecurso();
    fetchSelectedPage();
  }

  const fetchModalRecurso = async () => {
    const result = await fetchRecursoResult(dataModal.recurso_status.id);
    setRecurso(result);
  }
  
  return (
    <>
      {dataModal && dataModal.recurso_status && dataModal.recurso_status.id ? recurso && (
        <>
          <div className='overlay' onClick={onClose}>
            <div className='modal_container' onClick={(e) => e.stopPropagation()}>
              <div>
                  <h2 className='tittle_modal'>Projeto: {get(dataModal, "projeto_name")}</h2>
                  <h2 className='tittle_modal'>Aluno: {get(dataModal, "candidato.nome")}</h2>
              </div>
              <hr className='line_break' />
              <div>
                <h2 className='tittle_modal'>Acompanhamento do recurso.</h2>
              </div>
              <div>
                <p className='describe_modal'>Conteúdo do recurso:</p>                 
              </div>
              <div>
                <p className="box-highlight">
                  <p style={{minWidth: '500px', textAlign: 'center'}}>{get(recurso ,"pergunta")}</p>
                </p>
                <small className="question-subtitle"> {`Criado por ${get(recurso, 'responsavel', '')} em: ${get(recurso, 'criado_em', '')}`}</small>
              </div>
              <div>
                <p className='describe_modal'>Resposta do Recurso:</p>                 
              </div>
              {recurso && recurso.status && recurso.status.id === 3 ?
                (<div>
                  <p className="box-highlight">
                    <p style={{minWidth: '500px', textAlign: 'center'}}>{get(recurso, "resposta_formatada")}</p>
                  </p>
                  <small className="question-subtitle"> Divulgado em: {get(recurso, "divulgado_em", "-")}</small>
                </div>
              ):(
                <div>
                  <p className="box-highlight">
                    <p style={{textAlign: "justify"}}>O seu recurso ainda está em análise.</p>
                  </p>
                </div>
              )}
              <hr className='line_break' />
              <div className='section_buttons'>
                <button className='m-2 btn btn-secondary' onClick={onClose}>
                  <p className='mb-0'>Fechar</p>
                </button>
              </div>
            </div>
          </div>
        </>
      ):(
        <>
          <div className='overlay' onClick={onClose}>
              <div className='modal_container' onClick={(e) => e.stopPropagation()}>
                <div>
                  <h2 className='tittle_modal'>Projeto: {get(dataModal, "projeto_name")}</h2>
                  <h2 className='tittle_modal'>Aluno: {get(dataModal, "candidato.nome")}</h2>
                </div>
                <hr className='line_break' />
                <div>
                  <h2 className='tittle_modal'>Apresentar recurso.</h2>
                </div>
                <div>
                  <p className='describe_modal'>Deseja apresentar um recurso junto com seu estudante? Se sim, justifique sua decisão:</p>
                </div>
                <div>
                  <textarea
                    className='input_text'
                    placeholder='Escreva sua justificativa...'
                    value={textArea}
                    onChange={(e) => setTextArea(e.target.value)}
                  />
                </div>
                <hr className='line_break' />
                <div className='section_buttons'>
                  <button className='m-2 btn btn-secondary' onClick={onClose}>
                    <p className='mb-0'>Cancelar</p>
                  </button>
                  <button className='m-2 btn btn-primary' onClick={sendResource}>
                    <p className='mb-0'>Confirmar</p>
                  </button>
                </div>
              </div>
            </div>
        </>
      )}
    </>
  );
};

export default ModalRecurso;
