import React from 'react';
import { CONTATO } from '../consts';
import { getYear } from '../utils';

class Footer extends React.Component {
  render() {
    const isIframe = JSON.parse(localStorage.getItem('iframe')) || false;
    if (isIframe) {
      return <></>;
    }
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4">
            <span>Copyright © {getYear()} </span>
            </div>
            <div className="col-xs-12 col-sm-4">
              <span>
                <span className="mr-2">
                  <i className="fa fa-envelope" aria-hidden="true" />
                </span>
                <a href={`mailto:${CONTATO.EMAIL}`}>{CONTATO.EMAIL}</a>
              </span>
            </div>
            <div className="col-xs-12 col-sm-4">
              <span>
                <span className="mr-2">
                  <i className="fa fa-phone" aria-hidden="true" />
                </span>
                <a href={`tel:${CONTATO.TELEFONE}`}>{CONTATO.TELEFONE}</a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
