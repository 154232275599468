import { Option, Select } from "informed";
import PropTypes from "prop-types";
import React from "react";

class SelectInputRedacoes extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    field: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpBlock: PropTypes.string,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
    loading: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    disabled: false,
    label: "",
    options: [],
    error: "",
    helpBlock: "",
    validate: null,
    className: "",
  };

  render() {
    const {
      label,
      field,
      error,
      helpBlock,
      validate,
      disabled,
      options,
      loading,
      className,
      ...rest
    } = this.props;

    return (
      <div className={className}>
        {label && <label htmlFor={field}>{label}</label>}
        <Select
          field={field}
          validate={validate}
          className="form-control"
          disabled={loading || disabled}
          style={error ? { border: "solid 1px red" } : null}
          {...rest}
        >
          {!loading ? (
            <Option value="" disabled>
              {label}
            </Option>
          ) : (
            <Option value="" disabled>
              carregando...
            </Option>
          )}
          {options
            ? options.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))
            : null}
        </Select>
        {helpBlock ? <small>{helpBlock}</small> : null}
        {error ? <small style={{ color: "red" }}>{error}</small> : null}
      </div>
    );
  }
}

export default SelectInputRedacoes;
