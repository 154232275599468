import React, { useEffect, useState } from 'react';
import { Form } from 'informed';
import { API_URL } from '../../../consts';
import Axios from 'axios';
import VerticalBarChart from '../../../components/v2/graficos/VerticalBarChart';
import { toast } from 'react-toastify';
import MediaModaMedianaChart from '../../../components/v2/graficos/MediaModaMedianaChart';
import SelectInput from '../../../components/SelectInput';
import Loading from '../../../components/Loading';

function AnaliseResultadoNotas() {
  const [data, setData] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [escolas, setEscolas] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [FormApi, setFormApi] = useState();
  const [formValues, setFormValues] = useState();
  const [fetching, setFetching] = useState(false);
  const [modasData, setModasData] = useState([]);

  const configDataVerticalBar = () => {
    const { distribution_chart } = data

    return {
      data: {
        labels: distribution_chart.serie,
        datasets: [
          {
            label: 'Valores',
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
            data: distribution_chart.data,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: "ALUNOS POR INTERVALOS DE NOTAS", // Texto do rótulo do eixo X
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: "INTERVALOS DE NOTAS", // Texto do rótulo do eixo X
              },
            },
          ],
        },
      }
    }
  };

  const configDataMediaModaMediana = () => {
    const { dispersion_chart } = data

    let datasets = [
      {
        label: "Dados",
        data: Object.entries(dispersion_chart.serie).map((pos) => ({ x: pos[0], y: pos[1] })),
        borderColor: "rgba(75, 192, 192, 0.5)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        showLine: true,
        lineTension: 0.001,
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Média",
        data: [
          { x: dispersion_chart.media.data, y: 0 },
          { x: dispersion_chart.media.data, y: dispersion_chart.media.heightY },
        ],
        showLine: true,
        lineTension: 0.5,
        borderColor: "red",
        pointStyle: "cross",
      },
      {
        label: "Mediana",
        data: [
          { x: dispersion_chart.mediana.data, y: 0 },
          { x: dispersion_chart.mediana.data, y: dispersion_chart.mediana.heightY },
        ],
        showLine: true,
        lineTension: 1,
        borderColor: "blue",
        pointStyle: "cross",
      },
    ]
    for (const modaObj of dispersion_chart.modas) {
      for (const [modaKey, modaValue] of Object.entries(modaObj)) {
        datasets.push({
          label: modaKey,
          data: [
            { x: modaValue.data, y: 0 },
            { x: modaValue.data, y: modaValue.heightY },
          ],
          showLine: true,
          lineTension: 0.5,
          borderColor: "green",
          pointStyle: "cross",
        });
      }
    }
    let curvaDispersaoData = [{ x: 0, y: 0 }]
    for (let curva in dispersion_chart.curva_dispersao) {
      curvaDispersaoData.push({ x: dispersion_chart.curva_dispersao[curva].data.toFixed(2), y: dispersion_chart.curva_dispersao[curva].heightY })
    }
    curvaDispersaoData.push({ x: dispersion_chart.max_note_aplication, y: 0 })
    datasets.push({
      label: "Curva de Simetria",
      data: curvaDispersaoData,
      borderColor: "rgba(223, 40, 247, 0.921)",
      showLine: true,
      lineTension: 0.7,
      cubicInterpolationMode: "monotone",
      hidden: true,
    })

    return {
      data: {
        datasets: datasets
      },
      options: {
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "DISTRIBUIÇÃO DE NOTAS", // Texto do rótulo do eixo X
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "ALUNOS POR NOTAS", // Texto do rótulo do eixo X
              },
            },
          ],
        },
      }
    }
  }


  const fetchData = async () => {
    setFetching(true);
    if (formValues.values.projeto && formValues.values.escola) {
      try {
        const params = {
          projeto: formValues.values.projeto,
          escola: formValues.values.escola,
          etapa: formValues.values.etapa,
          turma: formValues.values.turma,
        };
        const res = await Axios.get(`${API_URL}/v2/relatorios/analise-de-notas-v2/analise-de-notas/all_charts`, { params });
        if (res.data.length) {
          toast.error('Nenhum dado encontrado com os parametros selecionados.');
          setData(res.data);
        } else {
          setData(res.data);
          const modasData = [];
          for (const modaObj of res.data.dispersion_chart.modas) {
            for (const [modaKey, modaValue] of Object.entries(modaObj)) {
              modasData.push({ nome: modaKey, valor: modaValue.data })
            }
          }
          setModasData(modasData);
        }
      } catch (err) {
        setData([]);
        toast.error('Erro ao buscar os dados de relátorio, tente novamente.');
      }
    } else {
      toast.warn('Necessário selecionar os campos Projeto, Escola e Etapa para buscar os dados.');
    }
    setFetching(false);
  };

  const fetchProjetos = async () => {
    try {
      const res = await Axios.get(`${API_URL}/projeto/all`);
      setProjetos(res.data.map(x => ({
        value: x.id,
        label: x.descricao,
      })));
    } catch (err) {
      toast.error('Erro ao buscar projetos, tente novamente.');
    }
  };

  const fetchEscolas = async () => {
    try {
      const res = await Axios.get(`${API_URL}/inep/escolas`);
      setEscolas(res.data.map(r => ({
        value: r.id,
        label: r.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar escolas, tente novamente.');
    }
  };

  const fetchEtapas = async () => {
    try {
      const res = await Axios.get(`${API_URL}/inep/etapas?projetos__id=${formValues.values.projeto}`);
      setEtapas(res.data.map(r => ({
        value: r.id,
        label: r.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar etapas, tente novamente.');
    }
  };

  const fetchTurmas = async () => {
    try {
      const params = {
        etapa: formValues.values.etapa,
        escola: formValues.values.escola,
        projetos__id: formValues.values.projeto,
      };
      const res = await Axios.get(`${API_URL}/inep/turmas`, { params });
      setTurmas(res.data.map(x => ({
        value: x.id,
        label: x.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar turmas, tente novamente.');
    }
  };

  useEffect(() => {
    fetchProjetos();
  }, []);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleChange = (field) => {
    if (field === 'Escolas') {
      FormApi.setValue('escola', '');
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEscolas();
      setEtapas();
      setTurmas();
      fetchEscolas();
    }
    if (field === 'Etapas') {
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEtapas();
      setTurmas();
      fetchEtapas();
    }
    if (field === 'Turmas') {
      FormApi.setValue('turma', '');
      setTurmas();
      fetchTurmas();
    }
  };

  const handleFocus = (fieldNecessario, fieldSelecionado) => {
    if (!formValues.values[fieldNecessario]) {
      toast.warn(`Selecione o campo ${fieldNecessario} antes de selecionar o campo ${fieldSelecionado}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1
        style={{
          fontFamily: 'pulic-sans',
          fontSize: '38px',
          marginTop: '10px',
        }}
      >
        Análise de Notas
      </h1>
      <Form
        onChange={(formState) => setFormValues(formState)}
        getApi={(formApi) => setFormApi(formApi)}
        style={{
          display: 'flex',
          width: '80%',
          height: '120px',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '50px',
        }}
      >
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Projeto"
            field="projeto"
            options={projetos}
            onChange={() => handleChange('Escolas')}
          />
        </div>
        <div
          style={{ width: '45%', margin: '5px' }}
        >
          <SelectInput
            label="Escola"
            field="escola"
            options={escolas}
            onChange={() => handleChange('Etapas')}
            onFocus={() => handleFocus('projeto', 'escola')}
          />
        </div>
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Etapa"
            field="etapa"
            options={etapas}
            onChange={() => handleChange('Turmas')}
            onFocus={() => handleFocus('escola', 'etapa')}
          />
        </div>
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Turma"
            field="turma"
            options={turmas}
            onFocus={() => handleFocus('etapa', 'turma')}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            onClick={() => fetchData()}
            disabled={fetching}
            className="btn btn-primary"
          >
            {fetching ? 'BUSCANDO...' : 'BUSCAR'}
          </button>
        </div>
      </Form>
      {
        fetching ? <Loading /> :
          data && data.length !== 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap',
                }}
              >
                <VerticalBarChart
                  data={configDataVerticalBar().data}
                  titleChart="Vertical"
                  options={configDataVerticalBar().options}
                  width={'600px'}
                  height={'500px'}
                />
                <MediaModaMedianaChart
                  data={configDataMediaModaMediana().data}
                  titleChart="Distribuição de Notas"
                  options={configDataMediaModaMediana().options}
                  width={'650px'}
                  height={'550px'}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <table className="table table-striped"
                  style={{
                    width: '90%',
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        fontFamily: 'pulic-sans',
                      }}
                    >
                      <th scope="col">Total Redações</th>
                      <th scope="col">Total de Redações não anuladas</th>
                      <th scope="col">Média</th>
                      {data.dispersion_chart.modas.length > 0 &&
                        (modasData.map((moda) => (<th scope="col">{capitalizeFirstLetter(moda.nome)}</th>)))
                      }
                      <th scope="col">Mediana</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.dispersion_chart.total_geral}</td>
                      <td>{data.dispersion_chart.total_corrigidas}</td>
                      <td>{data.dispersion_chart.media.data.toFixed(2)}</td>
                      {data.dispersion_chart.modas.length > 0 &&
                        (modasData.map((moda) => (<td scope="col">{moda.valor}</td>)))
                      }
                      <td>{data.dispersion_chart.mediana.data}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )
      }
    </div >
  );
}

export default AnaliseResultadoNotas;