import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../components';
import AnaliseResultadoNotas from '../pages/v2/analise_resultados/AnaliseResultadoNotas';
import AnaliseResultadoNotasCompetencia from '../pages/v2/analise_resultados/AnaliseResultadoNotasCompetencia';
import AnaliseResultadoApontamento from '../pages/v2/analise_resultados/AnaliseResultadoApontamento';

const SwitchAnaliseResultados = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        permission="pode_visualizar_analise_de_resultados"
        path="/analise_resultados/notas"
        component={AnaliseResultadoNotas}
      />
      <PrivateRoute
        exact
        permission="pode_visualizar_analise_de_resultados"
        path="/analise_resultados/notas-competencia"
        component={AnaliseResultadoNotasCompetencia}
      />
      <PrivateRoute
        exact
        permission="pode_visualizar_analise_de_resultados"
        path="/analise_resultados/apontameto"
        component={AnaliseResultadoApontamento}
      />
    </Switch>
  );
};

export default SwitchAnaliseResultados;
