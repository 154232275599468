import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CardPreVistaProva = ({
  nome,
  status,
  dataProva,
  orientacoesEstudante,
  competencias,
  notaFinal,
  notaTotal,
  nomeProva,
  linkProva,
}) => {
  const [orientacoesEstudanteShow, setOrientacoesEstudanteShow] = useState(orientacoesEstudante.map(f => f.orientacoes !== null));

  const handleShow = (index) => {
    setOrientacoesEstudanteShow((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const getNotaFinal = () => {
    let color = '';
    const teste = notaFinal / notaTotal;
    if (teste > 50) {
      color = '#61B082';
    } else {
      color = '#FB8F82';
    }
    return (
      <span style={{ color: `${color}` }}>
        {parseFloat(notaFinal).toFixed(0)}
      </span>
    );
  };

  const validarMapCompetencias = () => {
    return competencias.some(comp => !!comp.nota);
  };

  return (
    <div
      style={{
        width: '100%',
        margin: 'auto',
        border: '1px solid #ccc',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            fontWeight: '700',
            fontSize: '16px',
            font: 'Inter',
          }}
        >
          {nome}
        </h2>
        <span
          style={{
            gap: '10px',
            padding: '8px',
            borderRadius: '8px',
            background: `${status === 'Em correção' ? '#F1AE3D' : '#0ABF8C'}`,
            color: '#FFFFFF',
          }}
        >
          {status}
        </span>
      </div>
      <div style={{
        padding: '16px',
        backgroundColor: 'white',
      }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ margin: '4px 0' }}>
            <p
              style={{
                fontWeight: '700',
                fontSize: '16px',
                font: 'Inter',
              }}
            >
              {nomeProva}
            </p>
          </div>
          <div style={{ margin: '4px 0', display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <p
              style={{
                fontWeight: '400',
                fontSize: '16px',
                font: 'Inter',
              }}
            >
              Data da Prova: {dataProva}
            </p>
            <p
              style={{
                fontWeight: '400',
                fontSize: '16px',
                font: 'Inter',
              }}
            >
              Resultado: {getNotaFinal()}<span style={{ fontWeight: '700' }}>/{notaTotal}</span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '16px',
          }}
        >
          {validarMapCompetencias() && competencias.map((comp, index) => (
            <table className="table-bordered table-tag" key={index}>
              <thead
                style={{
                  margin: '0',
                  textAlign: 'center',
                  background: '#CECECE',
                }}
              >
                <tr>
                  <th>{comp.descricao}</th>
                </tr>
              </thead>
              <tbody
                style={{
                  margin: '0',
                  textAlign: 'center',
                }}
              >
                <tr>
                  <td style={{ height: '45px' }}>{comp.nota || '-'}</td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>
      <div style={{
        padding: '16px',
      }}
      >
        {orientacoesEstudante.map((orientacao, index) => (
          <div
            style={{
              marginTop: '10px',
              borderBottom: '1px solid #E8E8E8',
            }}
            key={index}>
            <p
              style={{
                fontWeight: '900',
                fontSize: '16px',
                font: 'Inter',
                cursor: 'pointer',
              }}
              onClick={() => handleShow(index)}
            >
              {orientacoesEstudante[index].tipo !== 'Recurso'
                ? `Ver apontamentos da correção ${index + 1}`
                : `Orientações da correção do ${orientacao.tipo.toLowerCase()}`
              }
              {orientacoesEstudanteShow[index]
                ? <i className="fas fa-caret-up" />
                : <i className="fas fa-caret-down" />
              }
            </p>
            {orientacoesEstudanteShow[index] &&
              <p style={{
                fontWeight: '400',
                fontSize: '16px',
                font: 'Inter',
              }}
              >
                {orientacao.orientacoes}
              </p>
            }
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          justifyContent: 'end',
          margin: '1rem',
        }}
      >
        {linkProva.map((token, index) => (
            <Link
              key={token.id}
              to={`/vista-prova-externa/${token.value}/projeto/${token.projeto_id}`}
            >
              <button
                type="button"
                className="btn btn-primary m-1"
              >
                {orientacoesEstudante[index].tipo !== 'Recurso'
                  ? `Ver apontamentos da correção ${index + 1}`
                  : `Ver apontamentos da correção do ${orientacoesEstudante[index].tipo.toLowerCase()} `
                }
              </button>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CardPreVistaProva;
