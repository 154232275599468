import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Footer from "../../Footer";
import { API_URL } from "../../../consts";
import { getAxiosConfig } from "../../../services";
import RelatorioTable from "./RelatorioTable";
import { getHeaderWithSortIcon } from "../../../utils/relatorios";
import ExportExcelButton from "../../ExportExcelButton";

class RelatorioNotaCompetenciaGeral extends React.Component {
  state = {
    fetchingData: false,
    enableExport: false,
    data: [],
    params: {},
  };

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = async (params) => {
    const { dataUrl } = this.props;
    this.setState({ fetchingData: true });
    try {
      const response = await axios.get(`${API_URL}/${dataUrl}`, {
        params,
        ...getAxiosConfig(),
      });
      this.setState({
        data: response.data,
        fetchingData: false,
        enableExport: true,
      });
    } catch (e) {
      this.setState({ fetchingData: false });
    }
  };

  mapResponse = (data, params) => {
    const { columns } = this.props;
    const emptyData = columns.reduce((acc, item) => {
      if (item.columns) {
        item.columns.forEach((column) => {
          acc[column.accessor] = null;
        });
      }
      return { ...acc, [item.accessor]: null };
    }, {});
    return {
      ...data,
      ...emptyData,
      ...params,
    };
  };

  updateRelatorio = (params) => {
    this.setState({ params, enableExport: true });
    this.fetchData(params);
  };

  onNextClick = (row, next) => {
    const { history } = this.props;
    const state = {};

    next.stateFields.forEach((stateField) => {
      state[stateField] = {
        label: row.original[`${stateField}_descricao`],
        value: row.original[`${stateField}_id`],
      };
    });
    history.push({
      pathname: next.pathname,
      state,
    });
  };

  getNextButton = (row, column) => (
    <button
      type="button"
      className="redacao-hyperlink column-left"
      onClick={() => this.onNextClick(row, column.next)}
    >
      {column.Cell ? column.Cell(row) : get(row.original, column.accessor)}
    </button>
  );

  mapColumns = (columns) => {
    return columns.map((column) => {
      const columnData = {
        ...column,
        Header: column.withSortIcon
          ? getHeaderWithSortIcon(column.header, column.accessor)
          : column.header,
        columns: column.columns ? this.mapColumns(column.columns) : undefined,
        accessor: column.accessor,
        show: column.show === undefined || column.show,
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
      };
      return columnData;
    });
  };

  render() {
    const {
      title,
      columns,
      exportData,
      defaultPageSize,
      showPageSizeOptions,
      showPagination,
      customForm,
    } = this.props;
    const { data, fetchingData, params, enableExport } = this.state;
    return (
      <>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="page-container-border position-relative">
            <section className="mt-3">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <h1 className="text-title">{title}</h1>
                  <div className="pull-right">
                    <ExportExcelButton
                      url={exportData.url}
                      filename={exportData.filename}
                      params={params}
                      disabled={!enableExport}
                    />
                  </div>
                </div>
              </div>
              {customForm(this.updateRelatorio)}
              <RelatorioTable
                data={data}
                columns={this.mapColumns(columns)}
                loading={fetchingData}
                defaultPageSize={defaultPageSize}
                showPageSizeOptions={showPageSizeOptions}
                showPagination={showPagination}
              />
            </section>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

RelatorioNotaCompetenciaGeral.propTypes = {
  title: PropTypes.string.isRequired,
  dataUrl: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      withSortIcon: PropTypes.bool,
      accessor: PropTypes.string,
      show: PropTypes.bool,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  exportData: PropTypes.shape({
    filename: PropTypes.string,
    url: PropTypes.string,
    hasPermission: PropTypes.bool,
  }),
  defaultPageSize: PropTypes.number,
  showPageSizeOptions: PropTypes.bool,
  showPagination: PropTypes.bool,
  customForm: PropTypes.func,
};

RelatorioNotaCompetenciaGeral.defaultProps = {
  exportData: {},
  defaultPageSize: 20,
  showPageSizeOptions: true,
  showPagination: true,
  customForm: () => {},
};

export default withRouter(RelatorioNotaCompetenciaGeral);
