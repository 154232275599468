import React from "react";
import "../assets/css/components/Chip.css";

const Chip = ({ label, onDelete }) => {
  return (
    <div className="chip-container">
      <span className="chip-label">{label}</span>
      <span className="chip-delete" onClick={onDelete}>
        &times;
      </span>
    </div>
  );
};

export default Chip;
