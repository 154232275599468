import React, { useState } from "react";

const SpecialComponent = ({ x, y, arrowText, arrow, div, readOnly, handleTableArrow, observacoes, handleRemoveSpecialComponent }) => {

  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('');
  const [existText, setExistText] = useState(true);

  if (arrowText && existText) {
    setTextAreaValue(arrowText);
    setExistText(false);
  }

  const handleClick = () => {
    setShowSearch(!showSearch);
  };

  const handleAdd = (optionText, id) => {
    if (readOnly) {
      setTextAreaValue(optionText);
    } else {
      setTextAreaValue((prevState) => prevState + optionText + '. ');
    }
    arrow.attrs.obj = textAreaValue;
    arrow.attrs.idDescricao = id;
  };

  const handleSearch = (e) => {
    const texto = e.target.value;
    setSearchText(texto);
  };

  const handleSave = () => {
    arrow.attrs.obj = textAreaValue;
    handleTableArrow();
    document.body.removeChild(div);
  };

  const handleCancel = () => {
    if (handleRemoveSpecialComponent) {
      handleRemoveSpecialComponent(arrow);
    }
    document.body.removeChild(div);
  };

  const searchOptions = observacoes.filter((observacao) => observacao.text.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <div style={{
      position: "absolute",
      top: `${y + 10}px`,
      left: `${x - 200}px`,
      zIndex: "0",
    }}
      className="toDelete"
    >
      <div class="form-floating">
        <textarea
          readOnly={readOnly}
          className="textAreaField form-control"
          accessKey="textAreaField"
          placeholder={readOnly ? "Selecione a observação abaixo:" : "Digite sua observação aqui."}
          style={{
            width: "200px",
            height: "100px",
            borderRadius: "8px",
            padding: "6px",
          }}
          rows="4"
          cols="50"
          onChange={(e) => setTextAreaValue(e.target.value)}
          value={textAreaValue}
        />
        <button
          onClick={handleSave}
          style={{
            position: "absolute",
            top: "5px",
            right: "-30px",
            color: "#fff",
            background: "green",
            borderRadius: "15px",
            width: "27px",
            height: "27px",
            border: "none",
          }}
        >
          ✓
        </button>
        <button
          onClick={handleCancel}
          style={{
            position: "absolute",
            top: "50px",
            right: "-30px",
            color: "#fff",
            fontWeight: "bold",
            background: "red",
            borderRadius: "15px",
            width: "27px",
            height: "27px",
            border: "none",
          }}
        >
          X
        </button>
      </div>
      {observacoes.length > 0 ? (
        <button
          className="btn-primary"
          onClick={handleClick}
          style={{
            position: "absolute",
            right: "0",
            minWidth: "200px",
            height: "30px",
            backgroundColor: "transparent",
            border: "none",
            borderRadius: "8px",
          }}
        >
          {showSearch ? 'Fechar exibição' : 'Exibir Textos Predefinidos'}
        </button>
      ) : (
        <button
          className="btn-primary"
          style={{
            position: "absolute",
            right: "0",
            minWidth: "200px",
            height: "30px",
            backgroundColor: "transparent",
            border: "none",
            borderRadius: "8px",
          }}
          disabled={true}
        >
          Não existem textos predefinidos
        </button>
      )}


      {showSearch && (
        <div
          style={{
            width: "200px",
            marginTop: "45px",
          }}
        >
          <input
            className="form-control"
            style={{
              width: "200px",
              height: "40px",
              padding: "5px",
              borderRadius: "8px",
            }}
            type="text"
            placeholder="Pesquisar"
            onChange={handleSearch}
          />
          <div>
            {searchOptions.length > 0 && (
              <div
                style={{
                  backgroundColor: "#fff",
                  maxHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                {searchOptions.map((option, index) => (
                  <div
                    className="bg-default-color-xs"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      flexDirection: "column",
                      width: "100%",
                      padding: "5px",
                      border: "1px solid #ccc",
                      marginBottom: "2px",
                      borderRadius: "8px",
                    }}
                    key={index}
                    onClick={() => handleAdd(option.text, option.id)}
                  >
                    <span>{option.text}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecialComponent;
