import React from 'react';
import PropTypes from 'prop-types';

const CellLoadingCorrecoes = ({ color }) => (
	<i style={{ color, fontSize: 48 }} className="fas fa-circle-notch fa-spin m-5" />
);

CellLoadingCorrecoes.propTypes = {
	color: PropTypes.string,
};

CellLoadingCorrecoes.defaultProps = {
	color: '#033F7B',
};

export default CellLoadingCorrecoes;
