import { get, set } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import HistoricosDesviosCorrecoesContext from '../../../contexts/HistoricosDesviosCorrecoesContext';
import permissionContext from '../../../contexts/PermissionContext';
import RecursoContext from '../../../contexts/RecursoContext';
import HistoricoCorrecoes from '../historico_correcoes/HistoricoCorrecoes';
import AnswerHeader from '../questions/AnswerHeader';
import QuestionHeader from '../questions/QuestionHeader';
import Axios from 'axios';
import { API_URL } from '../../../consts';

const RecursoHeader = () => {
  const { recurso, loadingRecurso } = useContext(RecursoContext);
  const { hasPermission } = useContext(permissionContext);
  const { historicosDesviosCorrecoes, loadingHistoricosDesviosCorrecoes } = useContext(HistoricosDesviosCorrecoesContext);
  const nomeCandidato = get(recurso, 'candidato') || '';
  const [statusCorrecao, setStatusCorrecao] = useState({ status: '', nota: null });

  const getCorrecaoRecurso = () => {
    Axios.get(`${API_URL}/v2/banco-redacoes/get_correcao_recurso?mascara_redacao=${recurso.correcao && recurso.correcao.redacao}`)
      .then((response) => {
        setStatusCorrecao(response.data);
      }).catch((err) => {
        const error = err.response.data[0];
      });
  }

  useEffect(() => {
    if (recurso && Object.keys(recurso).length !== 0) {
      getCorrecaoRecurso();
    }
  }, [recurso]);

  if (loadingRecurso) {
    return (<></>);
  }

  return (
    <>
      <div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
        <QuestionHeader
          title={`${nomeCandidato} # ${get(recurso, 'redacao_id')}`}
          subtitle={recurso.criado_em}
        />
        {get(recurso, 'tipo_deferimento') && (
          <div>
            <span
              style={{
                gap: '10px',
                padding: '8px',
                borderRadius: '8px',
                background: `${get(recurso, 'tipo_deferimento.nome') === 'Indeferido' ? '#FB8F82' : '#61B082'}`,
                color: '#FFFFFF',
              }}
            >
              {get(recurso, 'tipo_deferimento.nome')}
            </span>
          </div>
        )}
      </div>
      <p className="mt-3">
        <span className="primary-label">Grade de correção: </span>
        <HistoricoCorrecoes
          historicoCorrecoes={historicosDesviosCorrecoes}
          verNomeAvaliadorHistorico={false}
          fetching={loadingHistoricosDesviosCorrecoes}
          showNotaFinal={false}
          redacao={recurso.redacao || {}}
          verHeader={false}
        />
      </p>
      <div className="question-box">
        <QuestionHeader
          title={`Fundamentação do recurso:`}
          subtitle={`Criado por ${get(recurso, 'responsavel', '')} em: ${recurso.criado_em}`}
        />
        <p className="box-highlight">
          <p style={{ textAlign: "justify" }}>{recurso.pergunta}</p>
        </p>
      </div>
      {get(recurso, 'resposta') && (
        <div className="mt-3">
          <div className="answer-box">
            <QuestionHeader
              title={`Orientações ao corretor:`}
              subtitle={`Criado por ${get(recurso, 'resolvido_por', '')} em: ${recurso.resolvido_em}`}
            />
            <p className="box-highlight">
              <p style={{ textAlign: "justify" }}>{recurso.resposta}</p>
            </p>
          </div>
        </div >
      )}
      {
        hasPermission('pode_divulgar_recurso') && get(recurso, 'resposta_formatada') && (
          <div className="mt-3">
            <div className="answer-box">
              <QuestionHeader
                title={`Resposta ao Recurso:`}
                subtitle={`Criado por ${get(recurso, 'divulgado_por', '')} em: ${recurso.divulgado_em}`}
              />
              <p className="box-highlight">
                <p style={{ textAlign: "justify" }}>{recurso.resposta_formatada} </p>
              </p>
            </div>
          </div>
        )
      }
      {
        get(recurso, 'tipo_deferimento') && (
          <div>
            <p className="mt-3">
              <span className="primary-label">Resultado: </span>
            </p>
            <p className="box-highlight">
              {get(recurso, 'tipo_deferimento.nome') !== 'Indeferido' ? (
                < span className="font-weight-600 mr-1">
                  Correção enviada para a fila.<br />
                  Status da correção: {statusCorrecao && statusCorrecao.status}<br />
                  Nota: {statusCorrecao && statusCorrecao.nota ? statusCorrecao.nota : 'não disponível'}<br />
                </span>) : (
                < span className="font-weight-600 mr-1">
                  Nota anterior mantida.
                </span>)
              }
            </p>
          </div>
        )
      }
      {/* <p className="mt-3">
        <span className="primary-label">Data limite de resposta: </span>
        <span className="font-weight-200">{recurso.data_limite || '-'}</span>
      </p> */}
    </>
  );
};

export default RecursoHeader;
