import React from "react";

export const StyledPage = ({ children }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>{children}</div>
  );
};

export const StyledContainer = ({ children }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "966px",
        minHeight: "980px",
        padding: "40px",
        borderRadius: "12px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {children}
    </div>
  );
};

export const StyledTitle = ({ children }) => {
  return (
    <h1
      style={{
        fontFamily: "Public Sans, sans-serif",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#262930",
        paddingBottom: "16px",
        borderBottom: "1px solid #F5F5F5",
      }}
    >
      {children}
    </h1>
  );
};

export const StyledEnunciado = ({ children }) => {
  return (
    <p
      style={{
        fontFamily: "Public Sans",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        textAlign: "left",
        marginTop: "16px",
      }}
    >
      {children}
    </p>
  );
};

export const StyledBoxButtons = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        gap: "25px",
        marginTop: "12px",
      }}
    >
      {children}
    </div>
  );
};

export const StyledTagCreateProject = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "32px",
        padding: "4px 8px",
        gap: "0px",
        borderRadius: "4px",
        backgroundColor: "#1976d2",
        margin: "24px 0px",
      }}
    >
      {children}
    </div>
  );
};

export const StyledTextTag = ({ children }) => {
  return (
    <p
      style={{
        fontFamily: "Public Sans",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#FFFFFF",
      }}
    >
      {children}
    </p>
  );
};

export const StyledFlexRow = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        gap: "16px",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const StyledBoxInput = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const StyledBackgroundInput = ({ children }) => {
  return (
    <div
      style={{
        width: "525px",
        minHeight: "49px",
        maxHeight: "49px",
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        padding: "8px 12px",
        gap: "10px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #EEEEEE",
      }}
    >
      {children}
    </div>
  );
};

export const StyledLabel = ({ children }) => {
  return (
    <label
      style={{
        fontFamily: "Public Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16.45px",
        textAlign: "left",
        padding: "0px",
        color: "#4A4F58",
      }}
    >
      {children}
    </label>
  );
};

export const StyledInputText = (props) => {
  return (
    <input
      {...props}
      style={{
        width: "100%",
        height: "32px",
        padding: "8px 12px",
        gap: "10px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #EEEEEE",
      }}
    />
  );
};

export const StyledInputTextObservacao = (props) => {
  return (
    <input
      {...props}
      style={{
        width: "100%",
        height: "32px",
        padding: "8px 12px",
        gap: "10px",
        backgroundColor: "#FFFFFF",
        border: "0px",
        outline: "0px",
      }}
    />
  );
};

export const StyledInputTextObservacaoDiv = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
        height: "34px",
        border: "1px solid #EEEEEE",
        borderRadius: "8px",
      }}
    >
      {children}
    </div>
  );
};

export const StyledInputNotas = (props) => {
  return (
    <input
      {...props}
      style={{
        width: "65px",
        height: "32px",
        padding: "8px 12px",
        gap: "10px",
        margin: "0px 10px 0px 0px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #218EC6",
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        appearance: "none",
      }}
    />
  );
};

export const StyledLinhaDivisoria = () => {
  return (
    <hr
      style={{
        width: "100%",
        gap: "0px",
        border: "1px solid #F5F5F5",
        opacity: "0px",
      }}
    />
  );
};
// Custom Switch Component
export const Switch = ({ checked, onChange, name }) => {
  return (
    <label style={{ position: "relative", display: "inline-block", width: "42px", height: "26px" }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        name={name}
        style={{ opacity: 0, width: 0, height: 0 }}
      />
      <span
        style={{
          position: "absolute",
          cursor: "pointer",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: checked ? "#52d869" : "#ccc",
          borderRadius: "26px",
          transition: "0.4s"
        }}
      />
      <span
        style={{
          position: "absolute",
          content: "",
          height: "24px",
          width: "24px",
          left: checked ? "16px" : "2px",
          bottom: "1px",
          backgroundColor: "white",
          borderRadius: "50%",
          transition: "0.4s"
        }}
      />
    </label>
  );
};

// Custom FormControl and FormControlLabel Components
export const FormControl = ({ children, style }) => {
  return <div style={style}>{children}</div>;
};

export const FormControlLabel = ({ control, label }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
      {control}
      <span style={{ marginLeft: "8px" }}>{label}</span>
    </div>
  );
};

// Custom Button Component
export const Button = ({ children, onClick, variant, color, type }) => {
  const backgroundColor = variant === "contained" ? (color === "primary" ? "#1976d2" : "#f50057") : "transparent";
  const colorStyle = variant === "contained" ? "white" : color === "primary" ? "#1976d2" : "#f50057";
  const border = variant === "outlined" ? `1px solid ${colorStyle}` : "none";
  
  return (
    <button
      type={type}
      onClick={onClick}
      style={{
        backgroundColor,
        color: colorStyle,
        border,
        padding: "8px 16px",
        borderRadius: "4px",
        cursor: "pointer",
        marginRight: "8px",
      }}
    >
      {children}
    </button>
  );
};

//Delete Skill Modal
export const ModalOverlay = ({ children }) => {
  return(
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: '#00000080',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99',
      }}
    >
      {children}
    </div>
  )
}

export const ExcludeSkillModal = ({ children }) => {
  return(
      <div
        style={{
          height: "200px",
          width: "300px",
          padding: "20px 10px",
          borderRadius: "1rem",
          zIndex: "100",
          color: "black",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 8px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
        >
        {children}
      </div>
  )
}