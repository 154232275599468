/* eslint-disable */
import React from "react"
import Rodal from "rodal"
import { get } from 'lodash';
import PropTypes from "prop-types"
import ProgressBar from './ProgressBar';
import PlagiusAddresses from './PlagiusAddresses';

class ModalPlagius extends React.Component {
  static propTypes = {
    visible: PropTypes.any,
    onClose: PropTypes.any,
    title: PropTypes.string,
    content: PropTypes.oneOfType( [PropTypes.string, PropTypes.object] ),
    showFooter: PropTypes.bool,
  };

  static defaultProps = {
    title: "Título",
    content: "Confirmação",
    showFooter: true,
  };

  render () {
    const { visible, onClose, title, content, showFooter } = this.props
    return (
      <Rodal visible={visible} onClose={onClose} className="plagius-modal">
        <div className="modalConfirmacao">
          <div className="plagius">
            <p className="plagius-section">
              <strong>Suspeitas na Internet</strong>
              <br /><small> Percentual do texto com expressões localizadas na internet</small>
            </p>
            <ProgressBar align="right" progress={get( content, 'suspeitas_na_internet' )} color="info" />
            <p className="plagius-section">
              <strong>Suspeitas confirmadas</strong>
              <br /><small> Confirmada existência dos trechos suspeitos nos endereços encontrados</small>
            </p>
            <ProgressBar align="right" progress={get( content, 'suspeitas_confirmadas' )} color="info" />
            <p className="plagius-section">
              <strong>Texto analisado</strong>
              <br /><small> Percentual do texto efetivamente analisado (frases curtas, caracteres especiais, texto quebrado não são analisados).</small>
            </p>
            <ProgressBar align="right" progress={get( content, 'texto_analisado' )} color="info" />
            <p className="plagius-section">
              <strong>Sucesso da análise</strong>
              <br /><small> Percentual das pesquisas com sucesso. Indica a qualidade da análise. Quanto maior, melhor.</small>
            </p>
            <ProgressBar align="right" progress={get( content, 'sucesso_da_analise' )} color="info" />

            <p className="plagius-section mb-2">
              <strong>Endereços encontrados</strong>
            </p>
            <PlagiusAddresses addresses={get( content, 'enderecos' ) || []} />
          </div>
          {showFooter && (
            <div>
              <div className="botoesConfirmacao">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Fechar
                </button>
              </div>
            </div>
          )}
        </div>
      </Rodal>
    )
  }
}
export default ModalPlagius
