import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL } from '../../../consts'
import BancoRedacoesContext from '../../../contexts/BancoRedacoesContext'
import CellLoadingCorrecoes from '../../CellLoadingCorrecoes'
import ModalRecurso from '../modal_recurso/ModalRecurso'
import ModalPlagius from '../../ModalPlagius'

const BancoRedacoesProjetoList = () => {
  const { redacao, user } = useContext( BancoRedacoesContext )

  const [redacaoState, setRedacaoState] = useState()
  const [page, setPage] = useState( 1 )
  const [isFetching, setIsFetching] = useState( false )
  const [isNextDisabled, setIsNextDisabled] = useState( false )
  const [isPreviousDisabled, setIsPreviousDisabled] = useState( false )
  const [modalOpen, setModalOpen] = useState( false )
  const [dataModal, setDataModal] = useState()
  const [currentPlagiusHtml, setCurrentPlagiusHtml] = useState()
  const [showModalPlagius, setShowModalPlagius] = useState( false )

  useEffect( () => {
    setRedacaoState( redacao )
    setIsPreviousDisabled( true )
    if ( !redacao.next ) setIsNextDisabled( true )
  }, [] )

  useEffect( () => {
    if ( redacaoState !== undefined ) {
      const { previous, next } = redacaoState
      if ( next && previous ) {
        const urlObj = new URL( next || previous )
        const actualPage = parseInt( urlObj.searchParams.get( 'page' ) ) + ( next ? -1 : 1 )
        setPage( actualPage )
        setIsPreviousDisabled( !previous )
        setIsNextDisabled( !next )
      }

    }
  }, [redacaoState] )

  const handleUrl = ( urlObj ) => {
    if ( API_URL !== 'http://localhost:8000' ) {
      return urlObj.toString().replace( 'http', 'https' )
    }
    return urlObj.toString()
  }

  const fetchPreviousPage = async () => {
    const { previous } = redacaoState
    const urlObj = new URL( previous )
    let previousPage = urlObj.searchParams.get( 'page' )
    if ( !previousPage ) previousPage = '1'
    urlObj.searchParams.set( 'page', previousPage )
    const url = handleUrl( urlObj )
    try {
      setIsFetching( true )
      Axios.get( url ).then( ( res ) => {
        setRedacaoState( res.data )
        setIsFetching( false )
      } )
    } catch ( err ) {
      console.error( err )
      setIsFetching( false )
    };
  }

  const fetchSelectedPage = async () => {
    const { total_pages, next, previous } = redacaoState
    if ( page < 1 || page > total_pages ) {
      return toast.error( 'Página inexistente' )
    }
    const urlObj = new URL( next || previous )
    urlObj.searchParams.set( 'page', String( page ) )
    const url = handleUrl( urlObj )
    try {
      setIsFetching( true )
      Axios.get( url ).then( ( res ) => {
        setRedacaoState( res.data )
        setIsFetching( false )
      } )
    } catch ( err ) {
      console.error( err )
      setIsFetching( false )
    }
  }

  const fetchNextPage = async () => {
    const { next } = redacaoState
    const urlObj = new URL( next )
    const nextPage = urlObj.searchParams.get( 'page' )
    urlObj.searchParams.set( 'page', String( nextPage ) )
    const url = handleUrl( urlObj )
    try {
      setIsFetching( true )
      Axios.get( url ).then( ( res ) => {
        setRedacaoState( res.data )
        setIsFetching( false )
      } )
    } catch ( err ) {
      console.error( err )
      setIsFetching( false )
    }
  }

  const openModalRecurso = async ( data ) => {
    setDataModal( data )
    setModalOpen( !modalOpen )
  }

  const plagiusHandler = ( data: any ) => {
    setCurrentPlagiusHtml( data.retorno_plagio_response )
    setShowModalPlagius( true )
  }

  return (
    <div>
      {isFetching
        ? (
          <div className="d-flex justify-content-center">
            <CellLoadingCorrecoes />
          </div>
        )
        : (
          <>
            {redacaoState && <>
              <h1 className='no-border-text-title'>Projeto: {redacaoState.results[0].projeto_name}</h1>
              <h1 className='no-border-text-title'>{redacaoState.results[0].projeto_descricao}</h1>
            </>}
            <div className='containerBancoRedacao'>
              <div className="tabela-historico">
                <table className="table-tag">
                  <tbody>
                    <tr className="table-header">
                      <th className="table-data">Nome Candidato</th>
                      <th className="table-data">Escola</th>
                      <th className="table-data">Série</th>
                      <th className="table-data">Turma</th>
                      <th className="table-data">Nota</th>
                      <th className="table-data">Situação</th>
                      <th className="table-data">Vista de Prova</th>
                      <th className="table-data">Plágio</th>
                      {redacaoState
                        && redacaoState.results[0].show_comparativo_column && <th className="table-data">Comparativo de Redaçoes</th>}
                      {user.permissions.add_recurso && <th className="table-data">Recurso</th>}
                    </tr>
                    {redacaoState
                      && redacaoState.results.map( data => (
                        <tr className="table-row">
                          <td className="table-data">
                            {get( data, 'candidato.nome', '-' )}
                          </td>
                          <td className="table-data">{data.candidato.co_escola}</td>
                          <td className="table-data">{data.candidato.co_etapa}</td>
                          <td className="table-data">{data.candidato.co_turma}</td>
                          <td className="table-data">{data.nota}</td>
                          <td className="table-data">{data.situacao}</td>
                          <td className="table-data">
                            <Link
                              className="btn btn-more-eye"
                              to={`/vista-prova-externa/${data.token}`}
                              blank
                            >
                              <i className="fa fa-eye" />
                            </Link>
                          </td>
                          <td className="table-data">
                            <button style={{ border: "none", background: "transparent", color: parseFloat( data.retorno_porcento_plagio ) > 50.00 ? "red" : "blue" }} onClick={() => plagiusHandler( data )}>
                              {data.retorno_porcento_plagio}%
                            </button>
                          </td>
                          {data.show_comparativo_column && ( <td className="table-data">
                            <Link
                              className="btn btn-more-eye"
                              to={`/vista-prova-comparativo/${data.token}`}
                              blank
                            >
                              <i className="fa fa-eye" />
                            </Link>
                          </td> )}
                          {user.permissions.add_recurso && data.nota &&
                            <td className="table-data">
                              <button
                                onClick={() => openModalRecurso( data )}
                                type="button"
                                className="m-2 btn w-100"
                                style={{ backgroundColor: data.recurso_status.color, color: "white" }}
                              >
                                <p style={{ fontSize: '12px', margin: '0px', width: '100%' }}>{data.recurso_status.descricao}</p>
                              </button>
                            </td>
                          }
                        </tr>
                      ) )}
                  </tbody>
                </table>
                {modalOpen && <ModalRecurso isOpen={modalOpen} onClose={openModalRecurso} dataModal={dataModal} fetchSelectedPage={fetchSelectedPage} />}
              </div>
            </div>
          </>
        )}
      {redacaoState && (
        <div className="d-flex flex-row align-items-center justify-content-center mt-4">
          <button type="button" onClick={() => fetchPreviousPage()} disabled={isFetching || isPreviousDisabled} className="m-2 btn btn-primary">
            PÁGINA ANTERIOR
          </button>
          <span>Pág.</span>
          <input
            className="m-2 rounded text-center"
            style={{ width: '50px' }}
            type="number"
            onChange={e => setPage( e.target.valueAsNumber )}
            value={page}
          />
          <span>{`de ${redacaoState.total_pages}`}</span>
          <button type="button" onClick={() => fetchSelectedPage()} disabled={isFetching} className="m-2 btn btn-primary">
            BUSCAR
          </button>
          <button type="button" onClick={() => fetchNextPage()} disabled={isFetching || isNextDisabled} className="m-2 btn btn-primary">
            PRÓXIMA PÁGINA
          </button>
        </div>
      )}
      <ModalPlagius
        visible={showModalPlagius}
        onClose={() => setShowModalPlagius( false )}
        title=""
        content={currentPlagiusHtml}
        buttonFunction={() => alert( false )}
        textSim="Sim"
        textNao="Cancelar"
      />
    </div>
  )
}

export default BancoRedacoesProjetoList
