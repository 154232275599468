import { get } from "lodash";
import { toast } from "react-toastify";

export const lineToObject = (shape) => {
  const attrs = shape.getAttrs();
  const shouldSave = [
    "strokeWidth",
    "stroke",
    "offsetX",
    "offsetY",
    "points",
    "x",
    "y",
    "obj", // texto da observação
    "id",
    "descricao",
    "idObj", // id do texto da observação
    "circle",
    "idPos",
    "typeArrow",
  ];
  const object = {};
  shouldSave.forEach((attr) => {
    object[attr] = attrs[attr];
  });
  return object;
};

export const arrowToObject = (shape) => {
  const attrs = shape.getAttrs();
  const shouldSave = [
    "strokeWidth",
    "stroke",
    "pointerLength",
    "pointerWidth",
    "x",
    "y",
    "points",
    "scaleX",
    "scaleY",
    "rotation",
    "obj", // texto da observação
    "id",
    "descricao",
    "typeArrow",
  ];
  const object = {};
  shouldSave.forEach((attr) => {
    object[attr] = attrs[attr];
  });
  return object;
};

export const getPointsDistance = (p1, p2) =>
  Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

const TOAST_DURATION = 6000;
export const toastConfig = {
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: TOAST_DURATION,
  hideProgressBar: true,
};

export const getShapes = (data) => {
  let shapes;
  try {
    shapes = JSON.parse(data);
    if (!Array.isArray(shapes.arrows)) {
      shapes.arrows = [];
    }
    if (!Array.isArray(shapes.lines)) {
      shapes.lines = [];
    }
  } catch (_) {
    shapes = {
      lines: [],
      arrows: [],
    };
  }
  return shapes;
};

export const getShapesWithSetas = (linhas, setas) => {
  let shapes;
  try {
    const correcao = JSON.parse(linhas);
    const parsedSetas = JSON.parse(setas);
    const arrows = Array.isArray(parsedSetas.arrows) ? parsedSetas.arrows : [];
    const lines = Array.isArray(correcao.lines) ? correcao.lines : [];

    shapes = {
      lines,
      arrows,
    };
  } catch (_) {
    shapes = {
      lines: [],
      arrows: [],
    };
  }
  return shapes;
};

export const getListSettings = () => {
  const listSettings = localStorage.getItem("listSettings") || {};
  try {
    return JSON.parse(listSettings);
  } catch (_) {
    return {};
  }
};

export const setPageSettings = (page, settings) => {
  const listSettings = getListSettings();
  listSettings[page] = settings;
  localStorage.setItem("listSettings", JSON.stringify(listSettings));
};

export const getYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const shapeStringToConvert = (str) => {
  let shapedString = "";
  shapedString = str.replace(",", ".");
  shapedString = shapedString.replace("%", "");
  return shapedString;
};

export const operatorChoiceFilterMethod = (filter, row) => {
  const operators = {
    "===": (a, b) => +a === +b,
    "<=": (a, b) => +a <= +b,
    ">=": (a, b) => +a >= +b,
  };
  const opperator = get(filter, "value.opperator", "===");
  const value = String(get(filter, "value.text", ""));
  const rowValue = String(row[filter.id]);
  if (!value) {
    return true;
  }
  if (!rowValue) {
    return false;
  }
  return operators[opperator](
    shapeStringToConvert(rowValue),
    shapeStringToConvert(value)
  );
};

export const defaultFilter = ({ value, id }, row) => {
  if (value) {
    return +row[id] === +value.value;
  }
  return true;
};

export const alertasFilter = ({ value, id }, row) => {
  if (value) {
    if (row[id]) {
      return row[id].includes(value.value);
    }
    return false;
  }
  return true;
};

export const correcoesHabilitadasFilter = ({ value }, row) => {
  if (value) {
    return value.map((op) => row[op.value]).every((d) => d);
  }
  return true;
};

export const sortLocalizedFloat = (a, b) => {
  try {
    const floatA = parseFloat(a.replace(",", "."));
    const floatB = parseFloat(b.replace(",", "."));
    return floatA - floatB;
  } catch (_) {
    return -1;
  }
};

export const sortLocalizedPercentage = (a, b) => {
  try {
    return sortLocalizedFloat(a.split("%")[0], b.split("%")[0]);
  } catch (_) {
    return -1;
  }
};
