/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable react/no-access-state-in-setstate */
import Konva from "konva";
import { get } from "lodash";
import PropTypes, { func } from "prop-types";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import CorrecaoBase from "../base/ComponentBase";
import OrtografiaIcon from "../components/OrtografiaIcon";
import {
  arrowToObject,
  getPointsDistance,
  lineToObject,
  toastConfig,
} from "../utils";
import ReactDOM from "react-dom";
import SpecialComponent from "./SpecialComponent";
import { TextAreaInput } from "../components";

const CANVAS_WIDTH = 6200;
const CANVAS_HEIGHT = 10650;
const DELETE_KEY = 46;
const BACKSPACE_KEY = 8;

const strokeOptions = {
  brush: {
    color: "yellow",
    width: 20,
  },
  pencil: {
    color: "#D05050",
    width: 5,
  },
};


class Redacao extends CorrecaoBase {
  constructor(props) {
    super(props);
    this.drawExistingShapes = this.drawExistingShapes.bind(this);
    this.drawExistingLine = this.drawExistingLine.bind(this);
    this.drawExistingArrow = this.drawExistingArrow.bind(this);
    this.drawArrow = this.drawArrow.bind(this);
    this.changeTool = this.changeTool.bind(this);
    this.toggleZoom = this.toggleZoom.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.saveShapes = this.saveShapes.bind(this);
    this.initCanvas = this.initCanvas.bind(this);
    this.handleBrightnessChange = this.handleBrightnessChange.bind(this);
    this.removeShape = this.removeShape.bind(this);
    this.fitBackgroundImage = this.fitBackgroundImage.bind(this);
    this.lines = [];
    this.arrows = [];
    this.scale = 1;
    this.drawHistory = [];
  }

  state = {
    tool: null,
    zoom: false,
    ortografia: 0,
    rotation: 0,
    x: 0,
    y: 0,
    coor: { x: 0, y: 0 },
    countIdArrows: 1,
    setasDesenhadas: [],
  };


  componentDidMount() {
    const { src, simpleToken } = this.props;

    if (src) {
      this.initCanvas();
    }
    document
      .getElementById("container" + simpleToken)
      .addEventListener("contextmenu", (event) => event.preventDefault());
    document.addEventListener("keydown", this.removeLastShapeListener);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.removeLastShapeListener);
  }

  componentDidUpdate(prevProps) {
    const { src, show } = this.props;
    const { tool } = this.state;

    if (prevProps.src !== src && src) {
      this.initCanvas();
    }

    if (show) {
      const draggable = tool === "selector";
      this.lines.forEach((line) => line.draggable(draggable));
      this.arrows.forEach((line) => line.draggable(draggable));
      if (!draggable && this.stage) {
        this.stage.find("Transformer").destroy();
      }
    }
  }

  getStrokeOptions() {
    const { arrows } = this.props;
    const arrowsOptions = arrows.reduce((acc, arrow) => {
      const field = `arrow${arrow.id}`;
      return { ...acc, [field]: arrow };
    }, {});
    return { ...strokeOptions, ...arrowsOptions };
  }

  removeLastShapeListener = (event) => {
    if (event.code === "KeyZ" && (event.ctrlKey || event.metaKey)) {
      if (this.drawHistory.length > 0) {
        const lastShape = this.drawHistory.pop();
        this.removeShape(lastShape);
      } else {
        toast.error(
          "Não existe nenhuma marcação na redação para ser removida",
          toastConfig
        );
      }
    }
  };

  onDrawInit() {
    const { tool } = this.state;
    if (tool) {
      if (tool === "brush" || tool === "pencil") {
        this.isPaint = true;
        this.lastPointerPosition = this.stage.getPointerPosition();
        this.currentLine = new Konva.Line({
          points: [],
          strokeWidth: this.getStrokeOptions()[tool].width,
          stroke: this.getStrokeOptions()[tool].color,
          globalCompositeOperation: "multiply",
        });
        this.layer.add(this.currentLine);
        this.layer.draw();
      } else if (tool.includes("arrow")) {
        this.drawArrow(tool);
      }
    }
  }

  onDraw() {
    const { tool } = this.state;

    if (tool === "brush" || tool === "pencil") {
      if (this.isPaint) {
        const points = this.currentLine.getPoints();
        const x =
          this.stage.getPointerPosition().x / this.scale -
          this.stage.x() / this.scale;
        const y =
          this.stage.getPointerPosition().y / this.scale -
          this.stage.y() / this.scale;
        points.push(x);
        points.push(y);
        this.currentLine.getPoints(points);
        this.layer.draw();
      }
    }
  }

  onDrawEnd() {
    const { tool } = this.state;

    if (tool === "brush" || tool === "pencil") {
      if (this.currentLine) {
        this.isPaint = false;
        this.lines.push(this.currentLine);
        this.addCursorEventListeners(this.currentLine);
        this.drawHistory.push(this.currentLine);
        this.currentLine = null;
      }
    }
  }

  onLineClick(e) {
    const { tool } = this.state;

    if (tool === "selector") {
      if (e.target.className !== "Line" && e.target.className !== "Arrow") {
        this.stage.find("Transformer").destroy();
        this.selectedShape = null;
        this.layer.draw();
        return;
      }

      this.stage.find("Transformer").destroy();

      const transformer = new Konva.Transformer({
        resizeEnabled: false,
        rotateEnabled: false,
      });

      this.layer.add(transformer);
      transformer.attachTo(e.target);
      this.selectedShape = e.target;
      this.layer.draw();
    } else if (tool === "trash") {
      this.removeShape(e.target);
      this.drawHistory = this.drawHistory.filter((d) => d !== e.target);
    }
    this.handleTableArrow();
  }

  getExistingShapes() {
    const { saveInLocalStorage, shapes } = this.props;
    if (saveInLocalStorage) {
      return JSON.parse(
        localStorage.getItem("shapes") || '{ "lines": [], "arrows": [] }'
      );
    }

    return shapes;
  }

  saveShapes() {
    localStorage.setItem(
      "shapes",
      JSON.stringify({
        lines: this.lines.map(lineToObject),
        arrows: this.arrows.map(arrowToObject),
      })
    );
  }

  handleRemoveSpecialComponent = (shape) => {
    this.removeShape(shape);
    this.drawHistory = this.drawHistory.filter((d) => d !== shape);
  }

  removeShape(shape) {
    const circles = this.stage.find("Circle").toArray();
    const idNumbers = this.stage.find("Text").toArray();
    if (shape.className === "Line" || shape.className === "Arrow") {
      this.stage.find("Transformer").destroy();
      const circle = circles.find((item) => {
        return item && item.attrs.id && shape.attrs.id === item.attrs.id;
      });
      const idNumber = idNumbers.find((item) => {
        return item && item.attrs.id && shape.attrs.id === item.attrs.id;
      });
      if (circle) circle.destroy();
      if (idNumber) idNumber.destroy();
      shape.remove();
      this.layer.draw();
      this.lines = this.lines.filter((line) => line !== shape);
      this.arrows = this.arrows.filter((arrow) => arrow !== shape);
      const stateName = `${get(shape, "attrs.name", "")}Count`;
      const count = get(this.state, stateName, 0) - 1;
      const tool = get(shape, "attrs.name", "");
      if (shape.className === "Arrow") {
        this.props.removeDeviationArrow(this.getStrokeOptions()[tool]);
      }
      this.setState({
        ortografia: this.arrows.length,
        [stateName]: count,
      });
      this.handleTableArrow();
    }
  }

  drawExistingLine(config) {
    const line = new Konva.Line({
      globalCompositeOperation: "multiply",
      ...config,
    });
    this.lines.push(line);
    this.layer.add(line);
    this.addCursorEventListeners(line);
  }

  handleMouseMove = (e) => {
    const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;
    const x = e.clientX + scrollX;
    const y = e.clientY + scrollY;
    this.setState({ coor: { x, y } });
  };

  drawArrow(tool) {
    const initialPoint = this.stage.getPointerPosition();
    const x = initialPoint.x / this.scale - this.stage.x() / this.scale;
    const y = initialPoint.y / this.scale - this.stage.y() / this.scale;
    const size = this.getStrokeOptions()[tool].comprimento;
    const height = +this.getStrokeOptions()[tool].espessura;
    const pointerAjustment = size / 5;
    const group = new Konva.Group();
    this.layer.add(group);
    const arrow = new Konva.Arrow({
      points: [x - size, y - size, x - pointerAjustment, y - pointerAjustment],
      pointerLength: size / 2,
      pointerWidth: height * 2,
      stroke: this.getStrokeOptions()[tool].cor,
      strokeWidth: height,
      globalCompositeOperation: "multiply",
      name: tool,
      opacity: 1,
      obj: "",
      id: this.state.countIdArrows,
      idDescricao: null,
      descricao: this.getStrokeOptions()[tool].descricao,
      circle: { x: x - 30, y: y - 60 },
      idPos: { x: x - 45, y: y - 70 },
      typeArrow: this.getStrokeOptions()[tool].id,
    });
    group.add(arrow);
    const circle = new Konva.Circle({
      id: this.state.countIdArrows,
      x: x - 30,
      y: y - 60,
      radius: 15,
      fill: this.getStrokeOptions()[tool].cor,
      opacity: 1,
      strokeWidth: height,
      globalCompositeOperation: "multiply",
    });
    group.add(circle);
    const idNumber = new Konva.Text({
      id: this.state.countIdArrows,
      x: x - 45,
      y: y - 70,
      text: `${this.state.countIdArrows}`,
      fontSize: 24,
      fill: "white",
      align: "center",
      width: circle.radius() * 2,
      opacity: 1,
      fontWeight: 600,
    });
    group.add(idNumber);
    this.setState((prev) => ({ ...prev, countIdArrows: this.state.countIdArrows + 1 }));

    if (this.getStrokeOptions()[tool].setas_observacao) {
      arrow.on("click", () => {
        if (this.state.tool === "selector") {
          const div = document.createElement('div');
          document.body.appendChild(div);
          ReactDOM.render(<SpecialComponent
            x={this.state.coor.x}
            y={this.state.coor.y}
            arrowText={arrow.attrs.obj}
            arrow={arrow}
            div={div}
            setaDesvioId={this.getStrokeOptions()[tool].id}
            readOnly={!this.getStrokeOptions()[tool].permite_editar_observacoes}
            handleTableArrow={this.handleTableArrow}
            observacoes={this.getStrokeOptions()[tool].observacoes}
          />, div);
        }
      });
      const div = document.createElement('div');
      document.body.appendChild(div);
      ReactDOM.render(<SpecialComponent
        x={this.state.coor.x}
        y={this.state.coor.y}
        arrow={arrow}
        div={div}
        setaDesvioId={this.getStrokeOptions()[tool].id}
        readOnly={!this.getStrokeOptions()[tool].permite_editar_observacoes}
        handleTableArrow={this.handleTableArrow}
        observacoes={this.getStrokeOptions()[tool].observacoes}
        handleRemoveSpecialComponent={this.handleRemoveSpecialComponent}
      />, div);
    }
    let textarea = document.createElement("textarea");
    arrow.on("mouseover", () => {
      document.body.appendChild(textarea);
      if (arrow.attrs.obj) {
        textarea.value = arrow.attrs.obj;
        textarea.style.position = "absolute";
        textarea.style.top = this.state.coor.y + "px";
        textarea.style.left = this.state.coor.x + "px";
        textarea.style.background = "#332F2E";
        textarea.style.color = "white";
        textarea.style.borderRadius = "8px";
        textarea.style.padding = "9px";
        textarea.style.height = "auto";
        textarea.style.zIndex = "9999";
        textarea.style.overflow = "scroll";
        textarea.style.textAlign = "justify";
        textarea.disabled = true;
        // textarea.addEventListener("keydown", (e) => {
        //   // hide textarea on enter
        //   if (e.keyCode === 13) {
        //     arrow.attrs.obj = textarea.value;
        //     text.text();
        //     document.body.removeChild(textarea);
        //   }
        // });
      }
    });
    arrow.on("mouseout", () => {
      document.body.removeChild(textarea);
      this.layer.draw();
    });
    this.layer.add(arrow);
    this.layer.draw();
    this.arrows.push(arrow);
    this.addCursorEventListeners(arrow);
    this.drawHistory.push(arrow);
    const stateName = `${tool}Count`;
    const count = get(this.state, stateName, 0) + 1;
    this.props.addDeviationArrow(this.getStrokeOptions()[tool]);
    this.setState({
      ortografia: this.arrows.length,
      [stateName]: count,
    });
    this.handleTableArrow();
  }

  drawExistingArrow(config) {
    const group = new Konva.Group();
    this.layer.add(group);

    const arrow = new Konva.Arrow({
      globalCompositeOperation: "multiply",
      descricao: config.descricao,
      ...config,
    });
    group.add(arrow);
    const circle = new Konva.Circle({
      x: config.circle.x,
      y: config.circle.y,
      radius: 15,
      fill: config.stroke,
      opacity: 1,
      strokeWidth: config.strokeWidth,
      globalCompositeOperation: "multiply",
    });
    group.add(circle);
    const idNumber = new Konva.Text({
      x: config.idPos.x,
      y: config.idPos.y,
      text: config.id,
      fontSize: 24,
      fill: "white",
      align: "center",
      width: circle.radius() * 2,
      opacity: 1,
      fontWeight: 600,
    });
    group.add(idNumber);

    let textarea = document.createElement("textarea");

    group.on("mouseover", () => {
      document.body.appendChild(textarea);

      if (arrow.attrs.obj) {
        textarea.value = arrow.attrs.obj;
        textarea.style.position = "absolute";
        textarea.style.top = (this.state.coor.y + 10) + "px";
        textarea.style.left = (this.state.coor.x - 200) + "px";

        textarea.style.background = "#332F2E";
        textarea.style.color = "white";

        textarea.style.borderRadius = "8px";
        textarea.style.padding = "9px";
        textarea.style.height = "auto";
        textarea.style.width = "280px";
        textarea.style.zIndex = "9999";

        textarea.style.overflow = "scroll";
        textarea.style.textAlign = "justify";
        textarea.disabled = true;
      }
    });
    group.on("mouseout", () => {
      document.body.removeChild(textarea);
      this.layer.draw();
    });

    this.arrows.push(arrow);
    this.addCursorEventListeners(arrow);
  }

  addCursorEventListeners(shape) {
    shape.on("mouseover", () => {
      const { tool } = this.state;
      if (tool === "selector") {
        this.setState({ currentAction: "grab" });
      } else if (tool === "trash") {
        this.setState({ currentAction: "trash" });
      }
    });

    shape.on("mousedown", () => {
      const { tool } = this.state;
      if (tool === "selector") {
        this.setState({ currentAction: "grabbing" });
      }
    });

    shape.on("mouseup", () => {
      const { tool } = this.state;
      if (tool === "selector") {
        this.setState({ currentAction: "grab" });
      }
    });
  }

  zoomTo(pointerPosition, zoomFactor) {
    const { scaleRateOnZoom } = this.props;

    const oldScale = this.stage.scaleX();
    const mousePointTo = {
      x: pointerPosition.x / oldScale - this.stage.x() / oldScale,
      y: pointerPosition.y / oldScale - this.stage.y() / oldScale,
    };

    this.scale =
      zoomFactor * -1 > 0
        ? oldScale * scaleRateOnZoom
        : oldScale / scaleRateOnZoom;
    this.stage.scale({ x: this.scale, y: this.scale });

    const newPos = {
      x: -(mousePointTo.x - pointerPosition.x / this.scale) * this.scale,
      y: -(mousePointTo.y - pointerPosition.y / this.scale) * this.scale,
    };

    this.stage.position(newPos);
    this.stage.batchDraw();
  }

  zoomIn() {
    this.zoomTo({ x: 400, y: 0 }, -10);
  }

  zoomOut() {
    this.zoomTo({ x: 400, y: 0 }, +10);
  }

  addZoomEventListeners() {
    const container = this.stage.container();

    container.addEventListener("wheel", (event) => {
      const { zoom } = this.state;

      if (zoom) {
        event.preventDefault();
        const pointerPosition = this.stage.getPointerPosition();

        if (pointerPosition) {
          this.zoomTo(pointerPosition, event.deltaY);
        }
      }
    });

    let lastDist = 0;

    container.addEventListener(
      "touchmove",
      (event) => {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];

        if (touch1 && touch2) {
          const dist = getPointsDistance(
            {
              x: touch1.clientX,
              y: touch1.clientY,
            },
            {
              x: touch2.clientX,
              y: touch2.clientY,
            }
          );

          if (!lastDist) {
            lastDist = dist;
          }

          const scale = (this.stage.getScaleX() * dist) / lastDist;
          this.stage.scaleX(scale);
          this.stage.scaleY(scale);
          this.stage.draw();
          lastDist = dist;
        }
      },
      false
    );

    container.addEventListener(
      "touchend",
      () => {
        lastDist = 0;
      },
      false
    );
  }

  drawExistingShapes() {
    const { lines, arrows } = this.getExistingShapes();
    lines.forEach(this.drawExistingLine);
    arrows.forEach(this.drawExistingArrow);
    this.handleTableArrow();
    this.stage.draw();
  }

  rotacionarRedacao = () => {
    const angulo = this.draw.rotation() === 180 ? -180 : 180;
    this.draw.x(angulo === 180 ? this.backgroundImage.width : 0);
    this.draw.y(angulo === 180 ? this.backgroundImage.height : 0);

    this.draw.rotate(angulo);
    this.stage.draw();
  };

  downloadImagem = () => {
    const container = this.stage.container();
    const scale = container.offsetWidth / this.backgroundImage.width || 1;

    const a = document.createElement("a");
    a.href = this.stage.toDataURL({
      width: this.backgroundImage.width * scale,
      height: this.backgroundImage.height * scale,
    });
    a.download = "redacao.png";
    a.click();
  };

  initCanvas() {
    const { angulo_imagem } = this.props;
    this.lastPointerPosition = null;

    const width = CANVAS_WIDTH;
    const height = CANVAS_HEIGHT;
    const { src, simpleToken } = this.props;

    this.stage = new Konva.Stage({
      container: "container" + simpleToken,
      width,
      height,
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.canvas = document.createElement("canvas");
    this.canvas.width = this.stage.width();
    this.canvas.height = this.stage.height();

    this.context = this.canvas.getContext("2d");
    const { context } = this;
    this.context.strokeStyle = "red";
    this.context.lineJoin = "round";
    this.context.lineWidth = 20;

    this.backgroundImage = new Image();

    this.backgroundImage.onload = () => {
      this.draw = new Konva.Image({
        image: this.canvas,
        x: 0,
        y: 0,
        rotation: 0,
        preventDefault: false,
      });

      context.drawImage(this.backgroundImage, 0, 0);

      this.layer.add(this.draw);
      this.stage.draw();
      if (angulo_imagem === 180) {
        this.rotacionarRedacao();
      }
      this.drawExistingShapes();
      this.addZoomEventListeners();
      const { onInitCanvas } = this.props;
      onInitCanvas(() => {
        this.setState({ loading: false });
        this.fitBackgroundImage();
      });
    };
    this.backgroundImage.onerror = () => {
      const { onInitCanvas } = this.props;
      onInitCanvas();
      this.setState({ error: true, loading: false });
    };
    // this.backgroundImage.crossOrigin = 'anonymous';


    this.backgroundImage.src = src;
    this.isPaint = false;
    this.stage.on("contentMousedown.proto", () => this.onDrawInit());
    window.addEventListener("mouseup", () => this.onDrawEnd());
    this.stage.on("contentMousemove.proto", () => this.onDraw());
    this.stage.on("click", (e) => this.onLineClick(e));
    this.stage.on("tap", (e) => this.onLineClick(e));
    this.stage.on("mouseover", () => {
      const { tool } = this.state;
      let currentAction;

      switch (tool) {
        case "selector":
          currentAction = "grab";
          break;
        case "brush":
        case "pencil":
        case "trash":
        case "arrow":
          currentAction = tool;
          break;
        case "arrow1":
          currentAction = tool;
          break;
        case "arrow2":
          currentAction = tool;
          break;
        default:
          currentAction = "default";
      }

      this.setState({ currentAction });
    });
    this.stage.on("mousedown", () => {
      const { tool } = this.state;
      if (tool === "selector") {
        this.setState({ currentAction: "grabbing" });
      }
    });
    this.stage.on("mouseup", () => {
      const { tool } = this.state;
      if (tool === "selector") {
        this.setState({ currentAction: "grab" });
      }
    });
    this.stage.on("mouseleave", () => {
      this.setState({ currentAction: "default" });
    });

    // Adiciona evento de remover desenho ao pressionar DELETE ou BACKSPACE
    const container = this.stage.container();
    container.tabIndex = 1;
    container.focus();
    container.addEventListener("keypress", (event) => {
      if (
        (event.keyCode === DELETE_KEY || event.keyCode === BACKSPACE_KEY) &&
        this.selectedShape
      ) {
        event.preventDefault(); // Impede a ação "voltar" do navegador ao pressionar BACKSPACE
        this.removeShape(this.selectedShape);
      }
    });
  }

  changeTool(event) {
    const { tool } = this.state;
    const newTool =
      event.currentTarget.name !== tool ? event.currentTarget.name : "";

    this.setState({
      tool: newTool,
    });

    const isSelector = newTool === "selector";
    this.stage.draggable(isSelector);
    if (!isSelector) {
      this.stage.find("Transformer").destroy();
      this.selectedShape = null;
    }
    this.handleTableArrow()
  }

  handleTableArrow = () => {
    this.setState({ setasDesenhadas: this.arrows.map(arrow => arrow.attrs) });
  }

  toggleZoom() {
    const { zoom } = this.state;
    this.setState({ zoom: !zoom });
    this.fitBackgroundImage();
  }

  handleBrightnessChange(event) {
    const brightness = event.target.value;
    this.setState({ brightness });
    this.draw.brightness(brightness);
    this.layer.batchDraw();
  }

  fitBackgroundImage() {
    const container = this.stage.container();
    this.scale = container.offsetWidth / this.backgroundImage.width || 1;
    this.stage.height(this.backgroundImage.height * this.scale);
    const mousePointTo = {
      x: 0 / this.scale - this.stage.x() / this.scale,
      y: 0 / this.scale - this.stage.y() / this.scale,
    };

    this.stage.scale({ x: this.scale, y: this.scale });

    const newPos = {
      x: -(mousePointTo.x - 0 / this.scale) * this.scale,
      y: -(mousePointTo.y - 0 / this.scale) * this.scale,
    };

    this.stage.position(newPos);
    this.stage.x(0);
    this.stage.y(0);
    this.stage.batchDraw();
  }

  forceFocusOnFristTextField() {
    const elementoComAccessKey = document.querySelector(`[accesskey="textAreaField"]`);
    if (elementoComAccessKey) {
      elementoComAccessKey.focus();
    }
  }

  forceDeleteTextsFieldsWhenMouseLeave(e) {
    const elementoComAccessKey = document.querySelectorAll(".toDelete");
    let canDelete = true;
    try {
      const mouseIsOverSobrepostos = Array.from(elementoComAccessKey).some((elemento) => {
        if (elemento.contains(e.relatedTarget)) {
          canDelete = false;
        }
        return elemento.contains(e.relatedTarget);
      })
    } catch {
      canDelete = false;
    }
    if (canDelete && elementoComAccessKey.length !== 0) {
      const elementosArray = Array.from(elementoComAccessKey);
      elementosArray.forEach((elemento) => {
        elemento.parentNode.removeChild(elemento);
      });
    }
  }

  render() {
    const {
      saveInLocalStorage,
      show,
      fetchImagemError,
      readOnly,
      tamanho_coluna_redacao,
      scrollTools,
      arrows,
      isAbleToEdit,
      canDownloadImage,
      canRotacionar,
      simpleToken,
      exibir_campo_observacao,
    } = this.props;
    const { tool, error, zoom, currentAction, setasDesenhadas } = this.state;
    const hiddenSize =
      tamanho_coluna_redacao >= 8 ? "sm" : tamanho_coluna_redacao;

    return (
      <React.Fragment>
        {(fetchImagemError || error) && <p>Erro ao carregar imagem.</p>}
        <section
          onMouseMove={this.handleMouseMove}
          className="correcao"
          style={{ display: !show || error ? "none" : "block" }}
          onMouseLeave={(e) => this.forceDeleteTextsFieldsWhenMouseLeave(e)}
        >
          <div
            className="correcao-ferramentas"
            style={{
              display: readOnly ? "none" : "grid",
              overflow: scrollTools ? "auto" : "inherit",
            }}
          >
            <ul className="correcao-ferramentas-lista">
              <ReactTooltip place="bottom" type="dark" effect="solid" />
              <li>
                <button
                  type="button"
                  className={`btn btn-ferramentas select ${tool === "selector" ? "active" : null
                    }`}
                  onClick={this.changeTool}
                  name="selector"
                  data-tip="MOVER"
                >
                  Mover
                </button>
              </li>
              {isAbleToEdit && (
                <>
                  {arrows.map((arrow) => (
                    <Fragment
                      key={`arrow${arrow.id}`}>
                      <ReactTooltip place="bottom" type="dark" effect="solid" />
                      <li className="hidden-xs">
                        <button
                          type="button"
                          className={`btn btn-ferramentas ortografia ${tool === `arrow${arrow.id}` ? "active" : null
                            }`}
                          onClick={this.changeTool.bind(this)}
                          name={`arrow${arrow.id}`}
                          data-tip={arrow.tooltip}
                        >
                          <OrtografiaIcon fill={arrow.cor} />
                          <span
                            className="count-arrow"
                            style={{ backgroundColor: arrow.cor }}
                          >
                            {this.state[`arrow${arrow.id}Count`] || 0}
                          </span>
                        </button>
                        <p className="ferramenta-text">{arrow.descricao}</p>
                      </li>
                    </Fragment>
                  ))}
                  <li className={`hidden-xs hidden-${hiddenSize}`}>
                    <button
                      type="button"
                      className={`btn btn-ferramentas brush ${tool === "brush" ? "active" : null
                        }`}
                      onClick={this.changeTool}
                      name="brush"
                      data-tip="MARCA TEXTO"
                    >
                      Marcar
                    </button>
                  </li>
                  <li className={`hidden-xs hidden-${hiddenSize}`}>
                    <button
                      type="button"
                      className={`btn btn-ferramentas pencil ${tool === "pencil" ? "active" : null
                        }`}
                      onClick={this.changeTool}
                      name="pencil"
                      data-tip="CANETA"
                    >
                      Caneta
                    </button>
                  </li>
                  <li className={`hidden-xs hidden-${hiddenSize}`}>
                    <button
                      type="button"
                      className={`btn btn-ferramentas trash ${tool === "trash" ? "active" : null
                        }`}
                      onClick={this.changeTool}
                      name="trash"
                      data-tip="APAGAR MARCAÇÃO"
                    >
                      Apagar
                    </button>
                  </li>
                </>
              )}
              <li>
                <button
                  type="button"
                  className={`btn btn-ferramentas zoom ${zoom ? "active" : null
                    }`}
                  data-tip="AUMENTAR ZOOM"
                  name="zoom"
                  onClick={this.zoomIn}
                >
                  Zoom +
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`btn btn-ferramentas zoom- ${zoom ? "active" : null
                    }`}
                  data-tip="DIMINUIR ZOOM"
                  name="zoom"
                  onClick={this.zoomOut}
                >
                  Zoom -
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-ferramentas reset"
                  onClick={this.fitBackgroundImage}
                  data-tip="CENTRALIZAR PÁGINA"
                >
                  Centralizar
                </button>
              </li>
              {canRotacionar && (
                <li>
                  <button
                    type="button"
                    className="btn btn-ferramentas rotate"
                    onClick={this.rotacionarRedacao}
                    data-tip="Rotacionar"
                  >
                    Rotacionar
                  </button>
                </li>
              )}
              {canDownloadImage && (
                <li>
                  <button
                    type="button"
                    className="btn btn-ferramentas download"
                    onClick={this.downloadImagem}
                    data-tip="Download"
                  >
                    Download
                  </button>
                </li>
              )}
              {saveInLocalStorage && (
                <li>
                  <button
                    type="button"
                    className="btn btn-ferramentas"
                    onClick={this.saveShapes}
                  />
                </li>
              )}
            </ul>
          </div>
          <div
            className="correcao-imagem"
            style={{
              display: show ? "block" : "none",
              borderTop: readOnly ? "5px solid #D1D1D1" : "0px",
            }}
          >
            <div id={"container" + simpleToken} className={currentAction} />
          </div>
        </section>
        {
          exibir_campo_observacao && 
          (
            <section>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  marginTop: '1rem',
                }}
              >
                <TextAreaInput
                  label="Orientações ao estudante"
                  field="orientacao_ao_estudante"
                  placeholder="Digite aqui"
                  rows="4"
                />
              </div>
            </section>
          )
        }
        {this.forceFocusOnFristTextField()}
        {(setasDesenhadas.length > 0 || this.arrows.length > 0) && <section
          className="correcao"
          style={{ display: !show || error ? "none" : "block", overflow: "auto", maxHeight: "30%", marginTop: "15px", borderTop: "5px solid #D1D1D1" }}
        >
          <div
            className="correcao-imagem"
            style={{
              display: show ? "block" : "none",
              borderTop: readOnly ? "5px solid #D1D1D1" : "0px",
              fontSize: "14px !important",
              padding: "1.1rem !important",
              fontWeight: "600 !important",
              letterSpacing: "1px",
              textAlign: "justify",
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col-12" scope="col" colSpan="3" style={{ textAlign: "center" }}>LISTA DE OBSERVAÇÕES</th>
                </tr>
                <tr>
                  <th className="col-1" scope="col">Id</th>
                  <th className="col-3" scope="col">Tipo</th>
                  <th className="col-8" scope="col">Descrição</th>
                </tr>
              </thead>
              <tbody>
                {setasDesenhadas.map((arrow) => (
                  <tr>
                    <th scope="row" >
                      <div style={{
                        color: 'white',
                        background: `${arrow.stroke}`,
                        borderRadius: "30px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '25px',
                      }}>
                        {arrow.id}
                      </div>
                    </th>
                    <td>
                      <div
                        style={{
                          color: `${arrow.stroke}`,
                          borderColor: `${arrow.stroke}`,
                          borderRadius: "20px",
                          border: "1px solid",
                          gap: "4px",
                          background: "#FFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px 16px",
                          maxWidth: "300px",
                        }}
                      >
                        {arrow.descricao}
                      </div>
                    </td>
                    <td>{arrow.obj ? arrow.obj : ""}</td>
                  </tr>))
                }
              </tbody>
            </table>
          </div>
        </section>}
      </React.Fragment>
    );
  }
}

Redacao.propTypes = {
  shapes: PropTypes.shape({
    arrows: PropTypes.array,
    lines: PropTypes.array,
  }),
  src: PropTypes.string,
  saveInLocalStorage: PropTypes.bool,
  arrowTool: PropTypes.bool,
  scaleRateOnZoom: PropTypes.number,
  readOnly: PropTypes.bool,
  show: PropTypes.bool,
  onInitCanvas: PropTypes.func,
  tamanho_coluna_redacao: PropTypes.string,
  scrollTools: PropTypes.bool,
  isAbleToEdit: PropTypes.bool,
  canDownloadImage: PropTypes.bool,
  canRotacionar: PropTypes.bool,
};

Redacao.defaultProps = {
  shapes: {
    lines: [],
    arrows: [],
  },
  src: null,
  arrowTool: true,
  saveInLocalStorage: false,
  scaleRateOnZoom: 1.1,
  readOnly: false,
  show: false,
  scrollTools: false,
  canRotacionar: true,
  tamanho_coluna_redacao: "sm",
  onInitCanvas: (callback) => {
    if (callback) callback();
  },
  isAbleToEdit: true,
  canDownloadImage: true,
  arrows: [],
};

export default Redacao;


