import React, { useEffect, useState } from 'react';
import { Form } from 'informed';
import { API_URL } from '../../../consts';
import Axios from 'axios';
import VerticalBarChart from '../../../components/v2/graficos/VerticalBarChart';
import { toast } from 'react-toastify';
import SelectInput from '../../../components/SelectInput';
import PolarChart from '../../../components/v2/graficos/PolarChart';
import RadarChart from '../../../components/v2/graficos/RadarChart';


function AnaliseResultadoNotasCompetencia() {
  const [data, setData] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [escolas, setEscolas] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [FormApi, setFormApi] = useState();
  const [formValues, setFormValues] = useState();
  const [fetching, setFetching] = useState(false);

  const configDataMediaApontamentos = () => {
    if (data.length !== 0) {
      return {
        data: {
          labels: [
            `${data.competencias[0].nome}`,
            `${data.competencias[1].nome}`,
            `${data.competencias[2].nome}`,
            `${data.competencias[3].nome}`,
            `${data.competencias[4].nome}`,
          ],
          datasets: [
            {
              label: 'Competencias',
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255,99,132,1)',
                'rgba(255, 205, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
              data: [
                data.competencias[0].apontamentos_media,
                data.competencias[1].apontamentos_media,
                data.competencias[2].apontamentos_media,
                data.competencias[3].apontamentos_media,
                data.competencias[4].apontamentos_media,
              ],
            },
          ],
        },
      };
    }
  };
  const configDataMediaApontamentosTotal = () => {
    if (data.length !== 0) {
      return {
        data: {
          labels: [
            `${data.competencias[0].nome}`,
            `${data.competencias[1].nome}`,
            `${data.competencias[2].nome}`,
            `${data.competencias[3].nome}`,
            `${data.competencias[4].nome}`,
          ],
          datasets: [
            {
              label: 'Competencias',
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255,99,132,1)',
                'rgba(255, 205, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
              data: [
                data.competencias[0].apontamentos_total_media,
                data.competencias[1].apontamentos_total_media,
                data.competencias[2].apontamentos_total_media,
                data.competencias[3].apontamentos_total_media,
                data.competencias[4].apontamentos_total_media,
              ],
            },
          ],
        },
      };
    }
  };
  // const configDataMediaApontamentos = () => {
  //   if (data.length !== 0) {
  //     return {
  //       data: {
  //         labels: [
  //           `${data.competencias[0].nome}`,
  //           `${data.competencias[1].nome}`,
  //           `${data.competencias[2].nome}`,
  //           `${data.competencias[3].nome}`,
  //           `${data.competencias[4].nome}`,
  //         ],
  //         datasets: [
  //           {
  //             label: 'Competencias',
  //             backgroundColor: 'rgba(54, 162, 235, 0.2)',
  //             borderColor: 'rgba(54, 162, 235, 1)',
  //             borderWidth: 1,
  //             data: [
  //               data.competencias[0].apontamentos_media,
  //               data.competencias[1].apontamentos_media,
  //               data.competencias[2].apontamentos_media,
  //               data.competencias[3].apontamentos_media,
  //               data.competencias[4].apontamentos_media,
  //             ],
  //           },
  //         ],
  //       },
  //     };
  //   }
  // };
  // const configDataMediaApontamentosTotal = () => {
  //   if (data.length !== 0) {
  //     return {
  //       data: {
  //         labels: [
  //           `${data.competencias[0].nome}`,
  //           `${data.competencias[1].nome}`,
  //           `${data.competencias[2].nome}`,
  //           `${data.competencias[3].nome}`,
  //           `${data.competencias[4].nome}`,
  //         ],
  //         datasets: [
  //           {
  //             label: 'Competencias',
  //             backgroundColor: 'rgba(54, 162, 235, 0.2)',
  //             borderColor: 'rgba(54, 162, 235, 1)',
  //             borderWidth: 1,
  //             data: [
  //               data.competencias[0].apontamentos_total_media,
  //               data.competencias[1].apontamentos_total_media,
  //               data.competencias[2].apontamentos_total_media,
  //               data.competencias[3].apontamentos_total_media,
  //               data.competencias[4].apontamentos_total_media,
  //             ],
  //           },
  //         ],
  //       },
  //     };
  //   }
  // };
  const configDataRedacaoApontamentos = () => {
    if (data.length !== 0) {
      return {
        data: {
          labels: [
            `${data.competencias[0].nome}`,
            `${data.competencias[1].nome}`,
            `${data.competencias[2].nome}`,
            `${data.competencias[3].nome}`,
            `${data.competencias[4].nome}`,
          ],
          datasets: [
            {
              label: 'Apontamentos',
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(75,192,192,0.4)',
              hoverBorderColor: 'rgba(75,192,192,1)',
              data: [
                data.competencias[0].apontamentos,
                data.competencias[1].apontamentos,
                data.competencias[2].apontamentos,
                data.competencias[3].apontamentos,
                data.competencias[4].apontamentos,
              ],
            },
            {
              label: 'Total de Apontamentos',
              backgroundColor: 'rgba(255,99,132,0.2)',
              borderColor: 'rgba(255,99,132,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255,99,132,0.2)',
              hoverBorderColor: 'rgba(255,99,132,1)',
              data: [
                data.competencias[0].apontamentos_total,
                data.competencias[1].apontamentos_total,
                data.competencias[2].apontamentos_total,
                data.competencias[3].apontamentos_total,
                data.competencias[4].apontamentos_total,
              ],
            },
            {
              type: 'line',
              label: 'Total de Redações',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1))',
              borderWidth: 1,
              data: [
                data.competencias[0].nr_redacao,
                data.competencias[1].nr_redacao,
                data.competencias[2].nr_redacao,
                data.competencias[3].nr_redacao,
                data.competencias[4].nr_redacao,
              ],
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    }
  };

  const fetchData = async () => {
    setFetching(true);
    if (formValues.values.projeto && formValues.values.escola) {
      try {
        const params = {
          projeto: formValues.values.projeto,
          escola: formValues.values.escola,
          etapa: formValues.values.etapa,
          turma: formValues.values.turma,
        };
        const res = await Axios.get(`${API_URL}/v2/relatorios/analise-de-apontamentos-v2/analise-de-apontamentos/get_all_data_charts`, { params });
        if (res.data.competencias.length === 0 ) {
          throw new Error();
        } else {
          setData(res.data);
        }
      } catch (err) {
        setData([]);
        toast.error('Nenhum dado encontrado com os parametros selecionados.');
      }
    } else {
      toast.warn('Necessário selecionar os campos Projeto, Escola e Etapa para buscar os dados.');
    }
    setFetching(false);
  };

  const fetchProjetos = async () => {
    try {
      const res = await Axios.get(`${API_URL}/projeto/all`);
      setProjetos(res.data.map(x => ({
        value: x.id,
        label: x.descricao,
      })));
    } catch (err) {
      toast.error('Erro ao buscar projetos, tente novamente.');
    }
  };
  const fetchEscolas = async () => {
    try {
      const res = await Axios.get(`${API_URL}/inep/escolas`);
      setEscolas(res.data.map(r => ({
        value: r.id,
        label: r.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar escolas, tente novamente.');
    }
  };
  const fetchEtapas = async () => {
    try {
      const res = await Axios.get(`${API_URL}/inep/etapas?projetos__id=${formValues.values.projeto}`);
      setEtapas(res.data.map(r => ({
        value: r.id,
        label: r.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar etapas, tente novamente.');
    }
  };
  const fetchTurmas = async () => {
    try {
      const params = {
        etapa: formValues.values.etapa,
        escola: formValues.values.escola,
        projetos__id: formValues.values.projeto,
      };
      const res = await Axios.get(`${API_URL}/inep/turmas`, { params });
      setTurmas(res.data.map(x => ({
        value: x.id,
        label: x.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar turmas, tente novamente.');
    }
  };

  useEffect(() => {
    if (data) {
      configDataMediaApontamentos();
      configDataRedacaoApontamentos();
      configDataMediaApontamentosTotal();
    }
  }, [data]);

  useEffect(() => {
    fetchProjetos();
  }, []);

  const handleChange = (field) => {
    if (field === 'Escolas') {
      FormApi.setValue('escola', '');
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEscolas();
      setEtapas();
      setTurmas();
      fetchEscolas();
    }
    if (field === 'Etapas') {
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEtapas();
      setTurmas();
      fetchEtapas();
    }
    if (field === 'Turmas') {
      FormApi.setValue('turma', '');
      setTurmas();
      fetchTurmas();
    }
  };

  const handleFocus = (fieldNecessario, fieldSelecionado) => {
    if (!formValues.values[fieldNecessario]) {
      toast.warn(`Selecione o campo ${fieldNecessario} antes de selecionar o campo ${fieldSelecionado}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1
        style={{
          fontFamily: 'pulic-sans',
          fontSize: '38px',
          marginTop: '10px',
        }}
      >
        Análise de Apontamento
      </h1>
      <Form
        onChange={(formState) => setFormValues(formState)}
        getApi={(formApi) => setFormApi(formApi)}
        style={{
          display: 'flex',
          width: '80%',
          height: '120px',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '50px',
        }}
      >
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Projeto"
            field="projeto"
            options={projetos}
            onChange={() => handleChange('Escolas')}
          />
        </div>
        <div
          style={{ width: '45%', margin: '5px' }}
        >
          <SelectInput
            label="Escola"
            field="escola"
            options={escolas}
            onChange={() => handleChange('Etapas')}
            onFocus={() => handleFocus('projeto', 'escola')}
          />
        </div>
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Etapa"
            field="etapa"
            options={etapas}
            onChange={() => handleChange('Turmas')}
            onFocus={() => handleFocus('escola', 'etapa')}
          />
        </div>
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Turma"
            field="turma"
            options={turmas}
            onFocus={() => handleFocus('etapa', 'turma')}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            onClick={() => fetchData()}
            disabled={fetching}
            className="btn btn-primary"
          >
            {fetching ? 'BUSCANDO...' : 'BUSCAR'}
          </button>
        </div>
      </Form>
      {
        data && data.length !== 0  && (
          <>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <VerticalBarChart
                data={configDataRedacaoApontamentos().data}
                titleChart="Redações com apontamentos"
                options={configDataRedacaoApontamentos().options}
                width="750px"
                height="450px"
              />
              <RadarChart
                data={configDataMediaApontamentos().data}
                titleChart="Porcentagem de apontamentos por total de Redações"
                options={configDataMediaApontamentos().options}
                width="750px"
                height="500px"
              />
              <PolarChart
                data={configDataMediaApontamentosTotal().data}
                titleChart="Média de apontamentos por Redação com apontamentos"
                options={configDataMediaApontamentosTotal().options}
                width="750px"
                height="500px"
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <table className="table table-striped"
                style={{
                  width: '90%',
                }}
              >
                <thead>
                  <tr
                    style={{
                      fontFamily: 'pulic-sans',
                    }}
                  >
                    <th scope="col">Competência</th>
                    <th scope="col">Total de Redações</th>
                    <th scope="col">Redação com apontamentos</th>
                    <th scope="col">Procentagem de apontamentos por total de Redações</th>
                    <th scope="col">Total de apontamentos</th>
                    <th scope="col">Média de apontamentos por Redação com apontamentos</th>
                  </tr>
                </thead>
                <tbody>
                  {data.competencias.map(r => (
                    <tr>
                      <td>{r.nome}</td>
                      <td>{r.nr_redacao}</td>
                      <td>{r.apontamentos}</td>
                      <td>{r.apontamentos_media + "%"}</td>
                      <td>{r.apontamentos_total}</td>
                      <td>{r.apontamentos_total_media}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )
      }
    </div>
  );
}

export default AnaliseResultadoNotasCompetencia;
