import axios from 'axios';
import { API_URL } from '../consts';
import { getAxiosConfig } from './auth';

const fetchBancoRedacoes = params => axios
    .get(`${API_URL}/v2/banco-redacoes`, { params, ...getAxiosConfig() })
        .then(response => response.data);

export const fetchVistaProva = params => axios
    .get(`${API_URL}/v2/vista-prova`, { params, ...getAxiosConfig() })
        .then(response => response.data);

export const fetchVistaProvaTokens = params => axios
    .get(`${API_URL}/v2/vista-prova/tokens`, { params, ...getAxiosConfig() })
        .then(response => response.data);

export const fetchVistaProvaRoomData = params => axios
.get(`${API_URL}/v2/vista-prova-room/data`, { params, ...getAxiosConfig() })
    .then(response => response.data);

export const fetchVistaProvaSesiTokens = params => axios
    .get(`${API_URL}/v2/vista-prova-sesi/get_token`, { params })
        .then(response => response.data);

export const fetchVistaProvaTokensComparativo = params => axios
        .get(`${API_URL}/v2/vista-prova/tokens_comparativo`, { params, ...getAxiosConfig() })
            .then(response => response.data);

export default fetchBancoRedacoes;
