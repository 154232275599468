import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { fetchVistaProvaSesiTokens } from "../../../services/banco_redacoes";
import { URL_INSCRICOES } from '../../../consts';

const VistaProvaExternaSesi = ({ match }) => {
  const { inscricao } = match.params;

  useEffect(() => {
    fetchVistaProvaSesiTokens({ inscricao }).then((data) => {
      const url = `${URL_INSCRICOES}/vista-prova-externa/${data}`;
      if (data) {
        window.location.replace(url);
      }
    });
  }, []);

  return (
    <div>
      <div className="container">
        <div className="page-container-border">
          <div className="row">
            <div className="col">
              <h1 className="text-title">Vista de Prova</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(VistaProvaExternaSesi);
