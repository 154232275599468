import React from "react";
import { get } from "lodash";
import axios from "axios";
import RelatorioNotaCompetenciaGeral from "../../../../components/v2/relatorio/RelatorioNotaCompetenciaGeral";
import { API_URL } from "../../../../consts";
import { ComponentBase } from "../../../../base";
import SelectInput from "../../../../components/v2/inputs/SelectInput";

const mapFilters = (c) => ({
  value: c.id,
  label: c.descricao || c.nome,
});

class RelatorioNotas extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      projetos: [],
      escolas: [],
      turmas: [],
      etapas: [],
      fetchingProjeto: false,
      fetchingEscolas: false,
      fetchingTurmas: false,
      fetchingEtapas: false,
      escolaId: null,
      projetoId: null,
      etapaId: null,
      turmaId: null,
    };
  }

  componentDidMount() {
    this.fetchProjetos();
    this.fetchEscolas();
  }

  columns = [
    {
      header: "Máscara",
      accessor: "redacao_id",
      minWidth: 130,
    },
    {
      header: "Nome Candidato",
      accessor: "nome",
      minWidth: 100,
    },
    {
      header: "CPF",
      accessor: "cpf",
      minWidth: 140,
    },
    {
      header: "Escola",
      accessor: "nome_escola",
      minWidth: 140,
    },
    {
      header: "Turma",
      accessor: "nome_turma",
      minWidth: 140,
    },
    {
      header: "Etapa",
      accessor: "etapa",
      minWidth: 140,
    },
    {
      header: "Nota Final",
      accessor: "nota_final",
      minWidth: 140,
    },
    {
      header: "Projeto",
      accessor: "projeto",
      minWidth: 140,
    },
    {
      header: "C1: Nota Competência 1",
      accessor: "c1_nota_competencia_1",
      minWidth: 140,
    },
    {
      header: "C1: Nota Competência 2",
      accessor: "c1_nota_competencia_2",
      minWidth: 140,
    },
    {
      header: "C1: Nota Competência 3",
      accessor: "c1_nota_competencia_3",
      minWidth: 140,
    },
    {
      header: "C1: Nota Competência 4",
      accessor: "c1_nota_competencia_4",
      minWidth: 140,
    },
    {
      header: "C1: Nota Competência 5",
      accessor: "c1_nota_competencia_5",
      minWidth: 140,
    },
    {
      header: "C1: Nota Competência 6",
      accessor: "c1_nota_competencia_6",
      minWidth: 140,
    },
    {
      header: "C1: Situação",
      accessor: "c1_situacao",
      minWidth: 140,
    },
    {
      header: "C1: Nota",
      accessor: "c1_nota",
      minWidth: 140,
    },
    {
      header: "C1: Nome Corretor",
      accessor: "c1_nome_corretor",
      minWidth: 140,
    },
    {
      header: "C2: Nota Competência 1",
      accessor: "c2_nota_competencia_1",
      minWidth: 140,
    },
    {
      header: "C2: Nota Competência 2",
      accessor: "c2_nota_competencia_2",
      minWidth: 140,
    },
    {
      header: "C3: Nota Competência 3",
      accessor: "c2_nota_competencia_3",
      minWidth: 140,
    },
    {
      header: "C2: Nota Competência 4",
      accessor: "c2_nota_competencia_4",
      minWidth: 140,
    },
    {
      header: "C2: Nota Competência 5",
      accessor: "c2_nota_competencia_5",
      minWidth: 140,
    },
    {
      header: "C2: Nota Competência 6",
      accessor: "c2_nota_competencia_6",
      minWidth: 140,
    },
    {
      header: "C2: Nota",
      accessor: "c2_nota",
      minWidth: 140,
    },
    {
      header: "C2: Nome Corretor",
      accessor: "c2_nome_corretor",
      minWidth: 140,
    },
  ];

  fetchProjetos() {
    this.setState({ fetchingProjeto: true });
    axios
      .get(`${API_URL}/projeto/all`)
      .then((res) => {
        this.setState({
          projetos: res.data.map(mapFilters),
          fetchingProjeto: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchEtapas() {
    this.setState({ fetchingEtapas: true });
    axios
      .get(`${API_URL}/inep/etapas?projetos__id=${this.state.projetoId}`)
      .then((res) => {
        this.setState({
          etapas: res.data.map(mapFilters),
          fetchingEtapas: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchEscolas() {
    this.setState({ fetchingEscolas: true });
    axios
      .get(`${API_URL}/inep/escolas`)
      .then((res) => {
        this.setState({
          escolas: res.data.map(mapFilters),
          fetchingEscolas: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }


  fetchTurmas() {
    this.setState({ fetchingTurmas: true });
    axios
      .get(
        `${API_URL}/inep/turmas?etapa=${this.state.etapaId}&escola=${this.state.escolaId}&projetos__id=${this.state.projetoId}`
      )
      .then((res) => {
        this.setState({
          turmas: res.data.map(mapFilters),
          fetchingTurmas: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onCustomFormChange = (escolaId, projetoId, etapaId, turmaId, callback) => {
    const params = {
      co_projeto: projetoId,
      co_escola_id: escolaId,
      co_etapa_id: etapaId,
      co_turma_id: turmaId,
    };
    callback({ ...params });
    this.setState({ dataParams: { ...params } });
  };

  renderCustomForm = (callback) => {
    const {
      projetos,
      escolas,
      etapas,
      turmas,
      fetchingProjeto,
      fetchingEscolas,
      fetchingTurmas,
      fetchingEtapas,
      escolaId,
      projetoId,
      etapaId,
      turmaId,
    } = this.state;

    return (
      <div className="row mb-4">
        <div className="col-md-12">
          <SelectInput
            label="Projeto"
            onChange={(event) => {
              this.setState({ projetoId: get(event, "value") });
            }}
            options={projetos}
            isLoading={fetchingProjeto}
          />
        </div>
        <div className="col-md-4">
          <SelectInput
            label="Escola"
            onChange={(event) => {
              this.fetchEtapas();
              this.setState({ escolaId: get(event, "value") });
            }}
            options={escolas}
            isLoading={fetchingEscolas}
          />
        </div>
        <div className="col-md-4">
          <SelectInput
            label="Etapa"
            onChange={(event) => {
              this.setState({ etapaId: get(event, "value") });
              if (event) {
                this.fetchTurmas(event);
              }
            }}
            options={etapas}
            isLoading={fetchingEtapas}
          />
        </div>
        <div className="col-md-4">
          <SelectInput
            label="Turma"
            onChange={(event) => {
              this.setState({ turmaId: get(event, "value") });
            }}
            options={turmas}
            isLoading={fetchingTurmas}
            noOptionsMessage={
              fetchingTurmas
                ? "Nenhuma escola e etapa selecionada"
                : "Selecione uma escola e uma etapa para carregar as opções"
            }
          />
        </div>
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-primary mt-4"
            onClick={() => {
              this.onCustomFormChange(
                escolaId,
                projetoId,
                etapaId,
                turmaId,
                callback
              );
            }}
          >
            APLICAR FILTROS
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { dataParams } = this.state;

    const exportData = {
      url: `${API_URL}/projeto/relatorio-notas-competencias/excel`,
      filename: "relatorio_notas.xlsx",
    };

    return (
      <RelatorioNotaCompetenciaGeral
        title="Relatório de notas"
        dataUrl="projeto/relatorio-notas-competencias"
        columns={this.columns}
        exportData={exportData}
        params={dataParams}
        defaultPageSize={10}
        customForm={this.renderCustomForm}
        showPageSizeOptions
        showPagination
      />
    );
  }
}

export default RelatorioNotas;
