import React, { useContext } from "react";
import { get } from "lodash";
import shortid from "shortid";
import HistoricoCorrecaoContext from "../../../contexts/HistoricoCorrecaoContext";

const HistoricoRespostaContent = () => {
  const { correcao } = useContext(HistoricoCorrecaoContext);

  return (
    <div style={{ marginTop: "1rem" }}>
      <table className="table-tag">
        <tbody>
          <tr className="table-header" style={{ padding: "0.8rem" }}>
            <th className="table-data">Soma</th>
            {correcao.notas_competencia &&
              correcao.notas_competencia.map((desvio) => (
                <th className="table-data" key={shortid.generate()}>
                  {desvio.descricao}
                </th>
              ))}
          </tr>
          {!correcao.show_situacao && (
            <tr className="table-row">
              <td className="table-data">{correcao.nota_final}</td>
              {correcao.notas_competencia &&
                correcao.notas_competencia.map((desvio) => (
                  <th className="table-data" key={shortid.generate()}>
                    {get(desvio, "nota")}
                  </th>
                ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HistoricoRespostaContent;
