import React from "react";
import PropType from "prop-types";
import BancoRedacoesForm from "./BancoRedacoesForm";
import ImagemRedacao from "./ImagemRedacao";
import BancoRedacoesContext from "../../../contexts/BancoRedacoesContext";
import BancoRedacoesProjetoList from "./BancoRedacoesProjetoList";

const BancoRedacoes = ({
  verHistoricoCandidato,
  verDadosCandidato,
  onBackClick,
  redacao,
  fetchImagemError,
  fetching,
  historicoCorrecoes,
  fetchingHistoricoCorrecoes,
  verNomeAvaliadorHistorico,
  downloadImagemOriginal,
  onSubmit,
  desvios,
  projeto,
  user,
}) => (
  <BancoRedacoesContext.Provider
    value={{
      verHistoricoCandidato,
      verDadosCandidato,
      onBackClick,
      redacao,
      fetchImagemError,
      fetching,
      historicoCorrecoes,
      fetchingHistoricoCorrecoes,
      verNomeAvaliadorHistorico,
      downloadImagemOriginal,
      onSubmit,
      desvios,
      projeto,
      user,
    }}
  >
    {projeto ? (
      <BancoRedacoesProjetoList />
    ) : redacao ? (
      <ImagemRedacao />
    ) : (
      <BancoRedacoesForm />
    )}
  </BancoRedacoesContext.Provider>
);

BancoRedacoes.propTypes = {
  verHistoricoCandidato: PropType.bool.isRequired,
  verDadosCandidato: PropType.bool.isRequired,
  onBackClick: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired,
  redacao: PropType.shape({
    id: PropType.number,
    srcImagemOriginal: PropType.string,
    participante: PropType.string,
    cpf: PropType.string,
    inscricao: PropType.string,
  }),
  historicoCorrecoes: PropType.arrayOf(
    PropType.shape({
      id: PropType.number,
      tipo: PropType.string,
      avaliador: PropType.string,
    })
  ).isRequired,
  desvios: PropType.arrayOf(
    PropType.shape({
      id: PropType.number,
      tipo: PropType.string,
      avaliador: PropType.string,
    })
  ).isRequired,
  fetchImagemError: PropType.bool.isRequired,
  fetching: PropType.bool.isRequired,
  fetchingHistoricoCorrecoes: PropType.bool.isRequired,
  verNomeAvaliadorHistorico: PropType.bool.isRequired,
  downloadImagemOriginal: PropType.bool.isRequired,
};

BancoRedacoes.defaultProps = {
  redacao: undefined,
};

export default BancoRedacoes;
