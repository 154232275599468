import React, { Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Form, TextArea } from "informed";
import { toast } from "react-toastify";
import { get } from "lodash";
import ReactTable from "react-table";
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import {
  RadioInput,
  RadioBoxInput,
  Footer,
  EnviarSairButton,
  PularSairButton,
  PularBuscarMaisUmButton,
  EnviarBuscarMaisUmButton,
  DetalhesOcorrencia,
  BreadcrumbCorrecao,
  Loading,
  ModalGabarito,
  ModalConfirmacao,
  TextAreaInput,
} from "../components";
import Redacao from "../containers/Redacao";
import { API_URL, BASE_URL, CORRECAO, StatusCorrecao } from "../consts";
import { getAxiosConfig } from "../services";
import { lineToObject, toastConfig, getShapesWithSetas } from "../utils";
import dictionary from "../dictionary";
import CorrecaoBase from "./CorrecaoBase";
import NewTicketCard from "../components/v2/ticket/page/NewTicketCard";
import TextInputGroup from "../components/TextInputGroup";
import Decimal from 'decimal.js';
import HistoricoCorrecoes from "../components/v2/historico_correcoes/HistoricoCorrecoes";
import { fetchRecursoOrientacoes, fetchRecursoResult } from "../services/recursos";
import QuestionHeader from "../components/v2/questions/QuestionHeader";

const MAX_TENTATIVAS_SALVAR = 5;
const INTERVALO_TENTATIVAS_SALVAR = 1500;
const initialState = {
  actionType: null,
  ticketContentType: null,
  fetchCorrecaoSuccess: false,
  fetchImagemSuccess: false,
  submittingPular: false,
  hasAlertaDDH: false,
  formState: {},
  visible: false,
  visibleExercicioFinal: false,
  corrigidas: 0,
  historicoFetched: false,
  historicoCorrecoes: [],
  pendentesFetched: false,
  correcoesPendentes: [],
  disableCompetencia: false,
  disableSituacao: false,
  shapes: {
    lines: [],
    arrows: [],
  },
  accordions: {
    ocorrenciaRespondida: false,
    correcoesPendentes: false,
    competencias: true,
    situacoes: false,
    criarOcorrencia: false,
    correcoesAnteriores: false,
    problemaImagem: false,
    orientacoesAoCorretor: true,
    enviar: false,
  },
  values: {},
  activeAccordion: "main",
  sair: false,
  limpouCompetencias: false,
  gabarito: [],
  showRedacao: false,
  submitting: false,
  ocorrenciaRespondida: null,
  peso_competencia: 0,
  possui_competencia_5: false,
  link_imagem_recortada: null,
  loadingGabarito: true,
  pendente: undefined,
  alertaDDH: false,
  sairAposSalvarCorrecao: false,
  mostrarAbandonarBanca: false,
  grades: [],
  alternativas: {},
  indicadores: [],
  arrows: [],
  setasDesvio: [],
  notasIndicadores: {},
  resposta_esperada: "",
  situacoes: [],
  projetoPreferencias: {},
};

class Correcao extends CorrecaoBase {
  constructor(props) {
    super(props);
    this.fetchCorrecao = this.fetchCorrecao.bind(this);
    this.fetchOcorrencia = this.fetchOcorrencia.bind(this);
    this.handleAccordion = this.handleAccordion.bind(this);
    this.showAccordion = this.showAccordion.bind(this);
    this.showMainAccordion = this.showMainAccordion.bind(this);
    this.restoreValues = this.restoreValues.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.getPostData = this.getPostData.bind(this);
    this.salvarCorrecao = this.salvarCorrecao.bind(this);
    this.salvarOcorrencia = this.salvarOcorrencia.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onCloseLogout = this.onCloseLogout.bind(this);
    this.updateSubmitButtonsDisabledProp = this.updateSubmitButtonsDisabledProp.bind(
      this
    );
    this.onInitCanvas = this.onInitCanvas.bind(this);
    this.handlePular = this.handlePular.bind(this);
    this.resizableRedacao = this.resizableRedacao.bind(this);
    this.addDeviationArrow = this.addDeviationArrow.bind(this);
    this.removeDeviationArrow = this.removeDeviationArrow.bind(this);
    this.state = { ...initialState };
    this.tentativasSalvar = 0;
  }

  componentDidMount() {
    this.fetchCorrecao();
    this.fetchSituacoes();
    this.fetchDiarias();
    this.fetchCorrigidas();
    if (this.hasFeature("limitar_tempo_avaliador")) {
      this.fetchContador();
    }
    this.setState({
      tamanho_coluna_competencia:
        localStorage.getItem("tamanho_coluna_competencia") === null
          ? 4
          : parseInt(localStorage.getItem("tamanho_coluna_competencia"), 10),
      tamanho_coluna_redacao:
        localStorage.getItem("tamanho_coluna_redacao") === null
          ? 8
          : parseInt(localStorage.getItem("tamanho_coluna_redacao"), 10),
    });
  }

  fetchProjetoPreferencias(projeto_id) {
    try {
      const params = { projeto_id };
      axios.get(`${API_URL}/projeto/preferencias`, { params })
        .then((res) => {
          this.setState({ projetoPreferencias: res.data });
        });
    } catch (error) {
      console.error(error);
    }
  }

  fetchCorrecoesPendentes() {
    const { tipo_auditoria_id } = this.state;
    const params = {
      tipo: tipo_auditoria_id,
    };
    const headers = getAxiosConfig();
    const config = {
      params,
      ...headers,
    };
    axios.get(`${API_URL}/correcoes/pendentes`, config).then((response) => {
      const correcoesPendentes = response.data;
      this.setState({ pendentesFetched: true, correcoesPendentes });
    });
  }

  fetchGrade(projeto) {
    const data = {
      ...getAxiosConfig(),
      params: { projeto },
    };
    return axios.get(`${API_URL}/grades/`, data).then((response) => {
      const alternativas = {};
      let notaInicial = 0;
      const indicadores = [];
      response.data.forEach((g) => {
        notaInicial = g.nota_inicial;
        g.competencias.forEach((c) => {
          c.indicadores.forEach((i) => {
            indicadores.push(i);
            i.alternativas.forEach((a) => {
              alternativas[a.id] = a;
            });
          });
        });
      });
      const notasIndicadores = indicadores.reduce((acc, indicador) => {
        return { ...acc, [indicador.id]: Decimal(indicador.nota_inicial).toFixed() };
      }, {});
      this.setState({
        grades: response.data,
        alternativas,
        indicadores,
        notaFinal: notaInicial,
        notaInicial,
        notasIndicadores,
        exibir_campo_observacao: response.data[0].exibir_campo_observacao,
      });
    });
  }

  fetchDeviationArrow(projeto) {
    const data = {
      ...getAxiosConfig(),
      params: { projeto },
    };
    return axios
      .get(`${API_URL}/grades/setas_desvio`, data)
      .then((response) => {
        this.setState({ arrows: response.data });
      });
  }

  componentDidUpdate(prevProps, nextState) {
    const prevId = get(prevProps, "match.params.id");
    const currentId = get(this.props, "match.params.id");


    if (prevId !== currentId) {
      this.formApi.setValues({});
      this.componentDidMount();
    }
    if (
      this.state.tamanho_coluna_competencia !==
      nextState.tamanho_coluna_competencia
    ) {
      localStorage.setItem(
        "tamanho_coluna_competencia",
        this.state.tamanho_coluna_competencia
      );
      localStorage.setItem(
        "tamanho_coluna_redacao",
        this.state.tamanho_coluna_redacao
      );
    }
  }

  onValueChange(values) {
    const {
      limpouCompetencias,
      activeAccordion,
      alternativas,
      setasDesvio,
      notaInicial,
    } = this.state;
    let marcouCompetencia = false;
    let notaFinal = 0;
    Object.keys(values).forEach((key) => {
      if (key && key.includes("indicador")) {
        marcouCompetencia = marcouCompetencia || values[key] !== null;
        const alternativa = get(alternativas, values[key], {});
        const nota_alternativa = get(alternativa, "valor", 0);
        notaFinal += +nota_alternativa;
      }
    });

    setasDesvio.forEach((setaDesvio) => {
      const nota = setaDesvio.valor * setaDesvio.quantidade;
      notaFinal += +nota;
    });

    notaFinal += +notaInicial;

    notaFinal = notaFinal < 0 ? 0 : notaFinal;

    this.setState({ notaFinal });

    let newValues = { ...values };

    if (activeAccordion === "main") {
      if (marcouCompetencia && values.correcao_situacao_id !== null) {
        this.setState({ limpouCompetencias: false });
      }

      if (
        limpouCompetencias &&
        marcouCompetencia &&
        values.correcao_situacao_id !== null
      ) {
        newValues = {
          ...values,
          correcao_situacao_id: null,
        };
        this.formApi.setValues(newValues);
      }

      if (!limpouCompetencias && values.correcao_situacao_id !== null) {
        newValues = Object.keys(values).reduce((acc, key) => {
          if (key && !key.includes("indicador")) {
            return {
              ...acc,
              [key]: values[key],
            };
          }
          return acc;
        }, {});
        this.setState({ limpouCompetencias: true }, () => {
          this.formApi.setValues(newValues);
        });
      }
    }

    this.updateSubmitButtonsDisabledProp(newValues);
  }

  alterarNotaIndicador(indicador, valor) {
    const { notasIndicadores, indicadores } = this.state;

    const notaMaxima = Decimal(indicadores.find(i => i.id === indicador).total_pontos);
    const novaNota = Decimal(+notasIndicadores[indicador]).plus(Decimal(+valor));

    if (novaNota.lte(notaMaxima)) {
      notasIndicadores[indicador] = novaNota.toFixed();
    } else {
      notasIndicadores[indicador] = notaMaxima.toFixed();
    }

    return Object.keys(notasIndicadores).reduce((acc, key) => {
      const nota = +notasIndicadores[key] > 0 ? notasIndicadores[key] : 0;
      return Decimal(nota).plus(Decimal(acc)).toFixed();
    }, 0);
  }

  addDeviationArrow(deviationArrow) {
    const { setasDesvio } = this.state;
    if (deviationArrow) {
      const setaAdicionada = setasDesvio.find(
        (seta) => +seta.desvio === +deviationArrow.id
      );
      if (setaAdicionada) {
        setaAdicionada.quantidade += 1;
      } else {
        const data = {
          desvio: deviationArrow.id,
          valor: deviationArrow.valor,
          quantidade: 1,
        };
        this.setState({ setasDesvio: [...setasDesvio, data] });
      }
      const notaFinal = this.alterarNotaIndicador(
        deviationArrow.indicador,
        deviationArrow.valor
      );
      if (+deviationArrow.valor !== 0) {
        this.setState({ notaFinal });
      }
    }
  }

  removeDeviationArrow(deviationArrow) {
    const { setasDesvio } = this.state;
    if (deviationArrow) {
      const setaRemovida = setasDesvio.find(
        (seta) => +seta.desvio === +deviationArrow.id
      );
      if (setaRemovida) {
        setaRemovida.quantidade -= 1;
      } else {
        const data = {
          desvio: deviationArrow.id,
          valor: deviationArrow.valor,
          quantidade: 0,
        };
        this.setState({ setasDesvio: [...setasDesvio, data] });
      }
      const notaFinal = this.alterarNotaIndicador(
        deviationArrow.indicador,
        -deviationArrow.valor
      );
      if (+deviationArrow.valor !== 0) {
        this.setState({ notaFinal });
      }
    }
  }

  updateSubmitButtonsDisabledProp(values) {
    const salvarCorrecaoDisabled = false;
    const salvarProblemaImagemDisabled = !values.tipo_id;
    const duvidaPedagogicaDisabled = !(
      values.pergunta &&
      (values.situacao_id || values.tipo_id)
    );

    if (values.tipo_id !== undefined && values.situacao_id === undefined) {
      this.setState({ disableSituacao: true });
    } else if (
      values.tipo_id === undefined &&
      values.situacao_id !== undefined
    ) {
      this.setState({ disableCompetencia: true });
    } else {
      this.setState({ disableSituacao: false, disableCompetencia: false });
    }
    this.setState({
      salvarCorrecaoDisabled,
      salvarProblemaImagemDisabled,
      duvidaPedagogicaDisabled,
    });
  }

  getAccordionClass = (accordion) => {
    const { accordions } = this.state;
    return accordions[accordion] ? "show" : "hidden";
  };

  getAccordionArrow = (accordion) => {
    const { accordions } = this.state;
    return accordions[accordion] ? "fa-angle-up" : "fa-angle-down";
  };

  getPostData() {
    const { formState, values, setasDesvio } = this.state;
    const lines = this.redacao.lines.map(lineToObject);
    const arrows = this.redacao.arrows.map(lineToObject);
    const draw = get(this.redacao, "draw");
    const angulo_imagem = draw ? draw.rotation() : 0;
    const desvios = Object.keys(formState.values).reduce((acc, key) => {
      if (
        key.includes("desvios") &&
        formState.values[key] &&
        formState.values[key] > 0
      ) {
        const indicador_id = key.split("-")[1];
        const alternativa_key = `indicador${indicador_id}`;
        const alternativa =
          get(formState, `values[${alternativa_key}]`) ||
          get(formState, "values[indicador]");
        return [
          ...acc,
          {
            alternativa,
            desvios: formState.values[key],
          },
        ];
      }
      return acc;
    }, []);

    const alternativas = Object.keys(formState.values).reduce((acc, key) => {
      if (key.includes("indicador")) {
        return [...acc, formState.values[key]];
      }
      return acc;
    }, []);

    return {
      ...values,
      ...formState.values,
      alternativas,
      desvios,
      angulo_imagem,
      correcao: JSON.stringify({
        lines,
      }),
      setas: JSON.stringify({
        arrows,
      }),
      setas_desvio: setasDesvio,
    };
  }

  fetchDiarias() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    axios
      .get(`${API_URL}/correcoes/${id}/diarias`, getAxiosConfig())
      .then((response) =>
        this.setState({
          total_correcoes_diarias: response.data.total,
          cota_diaria: response.data.cota_diaria,
          tipo_cota: response.data.tipo_cota,
        })
      );
  }

  fetchContador() {
    axios
      .get(`${API_URL}/correcoes/contador`, getAxiosConfig())
      .then((response) => {
        const { contador } = response.data;
        this.setState({ contador: Date.now() + contador });
      });
  }

  fetchSituacoes = () => {
    axios
      .get(`${API_URL}/correcoes/situacoes`, getAxiosConfig())
      .then((response) => {
        this.setState({
          situacoes: response.data.map((d) => ({
            value: d.id,
            label: d.descricao,
          })),
        });
      });
  };

  fetchCorrigidas() {
    axios.get(`${API_URL}/correcoes/`, getAxiosConfig()).then((response) => {
      const { ...corrigidas } = response.data.records;
      this.setState({
        corrigidas: corrigidas[0].total_corrigidas,
      });
    });
  }

  fetchHistorico() {
    const {
      redacao
    } = this.state;
    if (!redacao) {
      return;
    }
    axios
      .get(`${API_URL}/v2/historico-correcao/${redacao}`, getAxiosConfig())
      .then((response) => {
        this.setState({ historicoCorrecoes: response.data });
      });
  }

  fetchGabarito() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return axios
      .get(`${API_URL}/correcoes/${id}/gabarito`, getAxiosConfig())
      .then((response) => {
        const gabarito = response.data;
        this.setState({
          gabarito,
          loadingGabarito: false,
        });
      });
  }

  fetchCorrecao = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const { historicoFetched } = this.state;
    axios
      .get(`${API_URL}/correcoes/${id}`, getAxiosConfig())
      .then((response) => {
        const {
          correcao,
          setas,
          link_imagem_recortada,
          id_correcao,
          possui_competencia_5,
          peso_competencia,
          redacao,
          tipo_auditoria_id,
          pendente,
          angulo_imagem,
          projeto_id,
          resposta_esperada,
          ...data
        } = response.data;

        axios
          .all([
            this.fetchGrade(projeto_id),
            this.fetchDeviationArrow(projeto_id),
            this.fetchProjetoPreferencias(projeto_id),
          ])
          .then(() => this.setState({ fetchCorrecaoSuccess: true }))
          .catch(this.handleError);

        const shapes = getShapesWithSetas(correcao, setas);
        this.setState(
          {
            shapes,
            peso_competencia,
            possui_competencia_5,
            id_correcao: id,
            link_imagem_recortada,
            redacao,
            tipo_auditoria_id,
            pendente,
            angulo_imagem,
            projeto_id,
            resposta_esperada,
            mostrarAbandonarBanca: response.data.mostrar_abandonar_banca,
          },
          () => {
            this.formApi.setValues(data);
            if (link_imagem_recortada === null) {
              this.setState({ fetchImagemError: true });
            }
            this.fetchOcorrencia();
            if (this.hasPermission("pode_corrigir_auditoria")) {
              this.fetchCorrecoesPendentes();
            }

            if (response.data.mostrar_abandonar_banca) {
              this.props.history.push({
                url: this.props.location.pathname,
                search: "?banca=true",
              });
            }
          }
        );
        if (
          this.hasPermission("show_historico_correcoes") &&
          this.hasFeature("show_historico_correcoes")
        ) {
          if (!historicoFetched) {
            this.fetchHistorico(redacao);
            this.setState({ historicoFetched: true });
          }
        }
        fetchRecursoOrientacoes(redacao)
          .then((responseData) => {
            this.setState({ orientacoesAoCorretor: responseData.orientacoes_ao_corretor });
          })
          .catch(this.handleError);
      })
      .catch(this.handleError);
    this.setState({ visible: false });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  fetchOcorrencia() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (this.hasFeature("ocorrencia")) {
      axios
        .get(`${API_URL}/tickets/one`, {
          ...getAxiosConfig(),
          params: {
            correction: id,
            ordering: "-resolved_at",
            page_size: 1,
          },
        })
        .then((response) => {
          const ocorrenciaRespondida = response.data;
          if (ocorrenciaRespondida) {
            this.setState({ ocorrenciaRespondida });
          }
        });
    }
  }

  onInitCanvas(callback) {
    this.setState({ fetchImagemSuccess: true });
    if (callback) callback();
  }

  onClose() {
    const { history } = this.props;
    if (this.state.sair) {
      history.push("/correcoes");
    } else {
      this.setState({ ...initialState }, this.buscarMaisUm);
    }
    this.setState({ visible: false });
  }

  renderTableGabarito() {
    const { loadingGabarito, situacoes } = this.state;

    const notas_modal = this.state.formState.values;
    if (notas_modal === undefined) {
      return "";
    }
    const situacao = situacoes.find(
      (x) => x.value === notas_modal.correcao_situacao_id
    );
    let situacao_nota_correcao;
    if (situacao !== undefined) {
      situacao_nota_correcao = situacao.label;
    }
    const { notaFinal } = this.state;
    const notas_gabarito = this.state.gabarito;
    const situacao_nota_referencia = notas_gabarito.correcao_situacao;
    const columnSituacaoNota = [
      {
        Header: "SUA CORREÇÃO",
        columns: [
          {
            Header: "SITUAÇÃO",
            Cell: (
              <div className="situacao-modal-gabarito">
                <span>{situacao_nota_correcao}</span>
              </div>
            ),
          },
        ],
      },
    ];
    const columnsNota = [
      {
        Header: "SUA CORREÇÃO",
        minWidth: 360,
        columns: [
          {
            Header: "SOMA",
            maxWidth: 70,
            Cell: <div>{notaFinal}</div>,
          },
          {
            Header: "CI",
            maxWidth: 55,
            Cell: (d) => (
              <div>
                {situacao_nota_correcao != null ? "-" : d.original.competencia1}
              </div>
            ),
          },
          {
            Header: "CII",
            maxWidth: 55,
            Cell: (d) => (
              <div>
                {situacao_nota_correcao != null ? "-" : d.original.competencia2}
              </div>
            ),
          },
          {
            Header: "CIII",
            maxWidth: 55,
            Cell: (d) => (
              <div>
                {situacao_nota_correcao != null ? "-" : d.original.competencia3}
              </div>
            ),
          },
          {
            Header: "CIV",
            maxWidth: 55,
            Cell: (d) => (
              <div>
                {situacao_nota_correcao != null ? "-" : d.original.competencia4}
              </div>
            ),
          },
          {
            Header: "CV",
            maxWidth: 55,
            Cell: (d) => (
              <div>
                {situacao_nota_correcao != null
                  ? "-"
                  : d.original.competencia5 === -1
                    ? "DDH"
                    : d.original.competencia5}
              </div>
            ),
          },
        ],
      },
    ];
    const columnSituacaoReferencia = [
      {
        Header: "REFERÊNCIA",
        columns: [
          {
            Header: "SITUAÇÃO",
            Cell: (
              <div className="situacao-modal-gabarito">
                <span>{situacao_nota_referencia}</span>
              </div>
            ),
          },
        ],
      },
    ];
    const columnsReferencia = [
      {
        Header: "REFERÊNCIA",
        minWidth: 360,
        columns: [
          {
            Header: "SOMA",
            maxWidth: 70,
            Cell: <div>{parseInt(this.state.gabarito.nota_final, 10)}</div>,
          },
          {
            Header: "CI",
            maxWidth: 55,
            Cell: <div>{this.state.gabarito.id_competencia1}</div>,
          },
          {
            Header: "CII",
            maxWidth: 55,
            Cell: <div>{this.state.gabarito.id_competencia2}</div>,
          },
          {
            Header: "CIII",
            maxWidth: 55,
            Cell: <div>{this.state.gabarito.id_competencia3}</div>,
          },
          {
            Header: "CIV",
            maxWidth: 55,
            Cell: <div>{this.state.gabarito.id_competencia4}</div>,
          },
          {
            Header: "CV",
            maxWidth: 55,
            Cell: <div>{this.state.gabarito.id_competencia5}</div>,
          },
        ],
      },
    ];

    if (loadingGabarito) {
      return <Loading />;
    }

    return (
      <Fragment>
        <ReactTable
          data={[notas_modal]}
          className="-highlight table-gabarito"
          defaultPageSize={1}
          previousText=""
          nextText=""
          noDataText="Sem conteúdo"
          pageText="Pág."
          ofText="de"
          rowsText="registros"
          resizable={false}
          showPagination={false}
          columns={
            situacao_nota_correcao != null ? columnSituacaoNota : columnsNota
          }
        />
        <ReactTable
          data={[notas_gabarito]}
          className="-highlight table-gabarito mt-3"
          defaultPageSize={1}
          previousText=""
          nextText=""
          noDataText="Sem conteúdo"
          pageText="Pág."
          ofText="de"
          rowsText="registros"
          resizable={false}
          showPagination={false}
          columns={
            situacao_nota_referencia !== "Sem problemas"
              ? columnSituacaoReferencia
              : columnsReferencia
          }
        />
      </Fragment>
    );
  }

  onCloseLogout() {
    const { history } = this.props;
    this.setState({
      visibleExercicioFinal: false,
    });
    history.push("/");
  }

  handlePular({ sair, next_id }) {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ submittingPular: true });
    const data = {
      proxima_correcao: next_id,
    };
    axios
      .post(`${API_URL}/correcoes/${id}/pular`, data, getAxiosConfig())
      .then(() => {
        this.setState({ submittingPular: false });
        if (sair) {
          history.push("/correcoes");
        } else if (next_id) {
          window.location.replace(`${BASE_URL}/correcoes/${next_id}`);
        } else {
          this.buscarMaisUm();
        }
      })
      .catch(this.handleError);
  }

  salvarCorrecao({ sair }) {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    const { projeto_id } = this.state;
    this.setState({ submitting: true });
    const data = {
      ...this.getPostData(),
      status_id: StatusCorrecao.FINALIZADA,
      projeto_id,
    };
    axios
      .patch(`${API_URL}/correcoes/${id}`, data, getAxiosConfig())
      .then(() => {
        if (sair) {
          this.setState({ sair: true });
          toast.success(dictionary.SUCESSO_PATCH_CORRECAO, toastConfig);
          if (this.hasFeature("exibe_gabarito")) {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
            this.setState({ visible: true });
            this.fetchGabarito();
          } else if (
            this.hasFeature("iniciar_no_primeiro_buscar_mais_um") &&
            this.state.corrigidas === this.state.cota_diaria - 1
          ) {
            this.setState({
              visibleExercicioFinal: true,
            });
          } else if (this.hasPermission("pode_corrigir_auditoria")) {
            history.push("/");
          } else {
            history.push("/correcoes");
          }
        } else {
          this.setState({ sair: false });
          toast.success(dictionary.SUCESSO_PATCH_CORRECAO, toastConfig);
          if (this.hasFeature("exibe_gabarito")) {
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
            this.setState({ visible: true });
            this.fetchGabarito();
          } else if (
            this.hasFeature("iniciar_no_primeiro_buscar_mais_um") &&
            this.state.corrigidas === this.state.cota_diaria - 1
          ) {
            this.setState({
              visibleExercicioFinal: true,
            });
          } else {
            this.setState({ ...initialState }, () => {
              const errorCallback = () => history.push("/correcoes");
              this.buscarMaisUm(errorCallback, projeto_id);
            });
          }
        }
      })
      .catch((error) => {
        const networkError = !error.response;
        if (networkError) {
          if (this.tentativasSalvar > MAX_TENTATIVAS_SALVAR) {
            toast.error(dictionary.NETWORK_ERROR, toastConfig);
            this.setState({ submitting: false });
            this.tentativasSalvar = 0;
          } else {
            this.tentativasSalvar += 1;
            setTimeout(
              () => this.salvarCorrecao({ sair }),
              INTERVALO_TENTATIVAS_SALVAR
            );
          }
        } else {
          this.setState({ submitting: false });
          this.handleError(error);
        }
      });
  }

  salvarOcorrencia({ sair, ocorrencia_categoria }) {
    const {
      history,
      match: {
        params: { id },
      },
    } = this.props;
    this.setState({ submitting: true });
    const data = {
      ...this.getPostData(),
      categoria_id: ocorrencia_categoria,
      status_id: StatusCorrecao.OCORRENCIA,
    };

    data.dados_correcao = data.correcao;
    data.correcao = id;

    axios
      .post(`${API_URL}/ocorrencias`, data, getAxiosConfig())
      .then(() => {
        if (sair) {
          history.push("/correcoes");
          toast.success("Ocorrência salva com sucesso!", toastConfig);
        } else {
          this.setState({ ...initialState }, this.buscarMaisUm);
          toast.success("Ocorrência salva com sucesso!", toastConfig);
        }
      })
      .catch((error) => {
        const networkError = !error.response;
        if (networkError) {
          if (this.tentativasSalvar > MAX_TENTATIVAS_SALVAR) {
            toast.error(dictionary.NETWORK_ERROR, toastConfig);
            this.setState({ submitting: false });
            this.tentativasSalvar = 0;
          } else {
            this.tentativasSalvar += 1;
            setTimeout(
              () => this.salvarOcorrencia({ sair, ocorrencia_categoria }),
              INTERVALO_TENTATIVAS_SALVAR
            );
          }
        } else {
          this.setState({ submitting: false });
          this.handleError(error);
        }
      });
  }

  handleAccordion(accordion) {
    const { accordions } = this.state;
    const newAccordions = {};
    Object.keys(accordions).forEach((ac) => {
      newAccordions[ac] = ac === accordion && !accordions[accordion];
    });
    this.setState({ accordions: newAccordions });
  }

  saveValues(callback) {
    this.setState({ values: this.formApi.getState().values }, callback);
  }

  restoreValues() {
    const { values } = this.state;
    this.formApi.setValues({
      ...values,
      ...this.formApi.getState().values,
    });
  }

  showAccordion(activeAccordion) {
    this.saveValues(() => this.setState({ activeAccordion }));
  }

  showMainAccordion() {
    this.restoreValues();
    this.setState({ activeAccordion: "main" });
  }

  handleSalvarCorrecao = ({ sairAposSalvarCorrecao }) => {
    this.salvarCorrecao({ sair: sairAposSalvarCorrecao });
  };

  handleShowTicket = (action) => {
    const actionType = action || null;
    this.setState({
      actionType,
    });
  };

  handleChangeTicketContentType = (ticketType) => {
    if (ticketType) {
      this.handleShowTicket("show_ticket_only");
    } else {
      this.handleShowTicket();
    }
    this.setState({
      ticketContentType: ticketType,
    });
  };

  renderDesvios(indicador_id, grade) {
    const { formState, alternativas } = this.state;

    const alternativa_key = grade.multipla_escolha
      ? `indicador${indicador_id}`
      : "indicador";
    const alternativa_id = get(formState.values, alternativa_key);
    const alternativa = get(alternativas, alternativa_id);
    if (
      alternativa &&
      alternativa.exige_desvios &&
      alternativa.indicador === indicador_id
    ) {
      return (
        <div className="desvios-input">
          <TextInputGroup
            type="number"
            min="1"
            label="No. Erros:"
            field={`desvios-${indicador_id}`}
            placeholder="0"
          />
        </div>
      );
    }
    return null;
  }

  renderObservacao() {
    const propostaDeIntervencaoItens = [
      { label: 'Agente:', field: 'observacao_agente', placeholder: 'Registre aqui se o estudante identifica o ator social indicado para a execução da ação proposta.' },
      { label: 'Ação:', field: 'observacao_acao', placeholder: 'Registre aqui se o estudante propõe uma ação que tenha caráter interventivo.' },
      { label: 'Modo:', field: 'observacao_modo', placeholder: 'Registre aqui se o estudante identifica à maneira e/ou os recursos pelos quais a ação é realizada.' },
      { label: 'Efeito:', field: 'observacao_efeito', placeholder: 'Registre aqui se o estudante identifica os resultados pretendidos ou alcançados pela ação proposta.' },
      { label: 'Detalhamento', field: 'observacao_detalhamento', placeholder: 'Registre aqui se o estudante acrescenta informações à ação, ao agente, ao modo/meio ou ao efeito.' },
    ];
    return (
      <>
        {propostaDeIntervencaoItens.map(item => (
          <TextAreaInput key={item.label} label={item.label} field={item.field} placeholder={item.placeholder} rows="4" className="w-100 indicador-descricao" />
        ))}
      </>
    );
  }

  renderAlternativas(grade, indicador) {
    const { notasIndicadores } = this.state;

    if (get(indicador, "alternativas", []).length === 0) {
      return (
        <div className="action-correcao">
          <center>
            <article className="input-nota-final">
              <label>NOTA COMPETÊNCIA: </label>
              <span>
                {" "}
                {notasIndicadores[indicador.id] > 0
                  ? notasIndicadores[indicador.id]
                  : 0}
              </span>
            </article>
          </center>
        </div>
      );
    }
    return (
      <div className="card-body p-0 d-flex justify-content-between align-items-center">
        <RadioBoxInput
          field={
            grade.multipla_escolha ? `indicador${indicador.id}` : "indicador"
          }
          options={get(indicador, "alternativas", []).map((a) => ({
            value: a.id,
            label: a.descricao,
          }))}
        />
        {this.renderDesvios(indicador.id, grade)}
      </div>
    );
  }

  renderGrades() {
    const { grades } = this.state;

    return (
      <>
        {grades.map((grade) => (
          <Fragment key={grade.id}>
            {get(grade, "competencias", []).map((competencia) => (
              <div className="avaliador-table-labels" key={competencia.id}>
                <h3 className="no-border-text-title indicador-descricao">
                  {competencia.nome}
                </h3>
                {get(competencia, "indicadores", []).map((indicador) => (
                  <Fragment key={indicador.id}>
                    <div className="card-header-competencia-left">
                      {indicador.descricao}
                    </div>
                    {this.renderAlternativas(grade, indicador)}
                  </Fragment>
                ))}
                {competencia.habilitar_area_de_observacao ? this.renderObservacao() : ''}
                <hr />
              </div>
            ))}
          </Fragment>
        ))}
      </>
    );
  }

  renderMainAccordion(formState) {
    const {
      activeAccordion,
      submitting,
      ocorrenciaRespondida,
      salvarCorrecaoDisabled,
      possui_competencia_5,
      historicoCorrecoes,
      correcoesPendentes,
      pendentesFetched,
      actionType,
      ticketContentType,
      indicadores,
      notaFinal,
      resposta_esperada,
      situacoes,
      projeto_id,
      orientacoesAoCorretor,
    } = this.state;

    const { history } = this.props;

    const errorCallback = () => history.push("/correcoes");
    const buscarMaisUm = () => {
      this.buscarMaisUm(errorCallback, projeto_id);
    };

    if (activeAccordion !== "main") {
      return null;
    }
    if (actionType === "show_ticket_only") {
      return (
        <section className="correcao-respostas">
          <NewTicketCard
            indicadores={indicadores}
            onChangeAccordion={this.handleAccordion}
            getAccordionArrow={this.getAccordionArrow}
            getPostData={this.getPostData()}
            getAccordionClass={this.getAccordionClass}
            onShowTicket={this.handleShowTicket}
            onError={this.handleError}
            onTicketSended={buscarMaisUm}
            actionType={actionType}
            possui_competencia_5={possui_competencia_5}
            ticketContentType={ticketContentType}
            onChangeTicketContentType={this.handleChangeTicketContentType}
          />
        </section>
      );
    }

    return (
      <section className="correcao-respostas">
        <div id="collapse">
          {ocorrenciaRespondida && (
            <DetalhesOcorrencia
              ocorrencia={ocorrenciaRespondida}
              edit={false}
              sair={false}
            />
          )}

          {/* Ações */}
          <div className="action-correcao">
            <div className="row">
              <div className="col-md-6 action1 pr-1">
                <EnviarSairButton
                  onClick={() =>
                    this.handleSalvarCorrecao({ sairAposSalvarCorrecao: true })
                  }
                  submitting={submitting}
                  disabled={submitting || salvarCorrecaoDisabled}
                  clickOnDisabledMessage={
                    dictionary.PATCH_CORRECAO_PREENCHER_CAMPOS
                  }
                />
              </div>
              <div className="col-md-6 action2 pl-1">
                <EnviarBuscarMaisUmButton
                  onClick={() =>
                    this.handleSalvarCorrecao({ sairAposSalvarCorrecao: false })
                  }
                  disabled={submitting || salvarCorrecaoDisabled}
                  submitting={submitting}
                  clickOnDisabledMessage={
                    dictionary.PATCH_CORRECAO_PREENCHER_CAMPOS
                  }
                />
              </div>
            </div>
          </div>
          {/* Resposta esperada */}
          {resposta_esperada && (
            <div className="avaliador-table-labels">
              <h3 className="no-border-text-title indicador-descricao">
                RESPOSTA ESPERADA
              </h3>
              <div
                className="card-header-competencia-left"
                dangerouslySetInnerHTML={{ __html: resposta_esperada }}
              />
              <hr />
            </div>
          )}

          {/* Orientações ao corretor: */}
          {this.hasPermission("show_historico_correcoes") &&
            this.hasFeature("show_historico_correcoes") && 
            orientacoesAoCorretor && (
            <div className="card card-correcao-accordion">
              <button
                type="button"
                className="card-header-correcao"
                onClick={() => this.handleAccordion("orientacoesAoCorretor")}
              >
                ORIENTAÇÕES AO CORRETOR
                <i
                  className={`fas ${this.getAccordionArrow(
                    "orientacoesAoCorretor"
                  )} float-right`}
                />
              </button>
              <div
                id="orientacoes-ao-corretor"
                className={`collapse ${this.getAccordionClass(
                  "orientacoesAoCorretor"
                )}`}
              >
                <div className="mt-3 mb-3 text-center">
                  {
                    orientacoesAoCorretor &&
                    <div className="answer-box">
                      <QuestionHeader
                        title={`Orientações ao corretor:`}
                        subtitle={``}
                      />
                      <p style={{ textAlign: "justify" }}>{orientacoesAoCorretor}</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          )}

          {/* Historico */}
          {this.hasPermission("show_historico_correcoes") &&
            this.hasFeature("show_historico_correcoes") && (
            <div className="card card-correcao">
              <button
                type="button"
                className="card-header-correcao"
                onClick={() => this.handleAccordion("correcoesAnteriores")}
              >
                CORREÇÕES ANTERIORES
                <i
                  className={`fas ${this.getAccordionArrow(
                    "correcoesAnteriores"
                  )} float-right`}
                />
              </button>
              <div
                id="correcao-historico"
                className={`collapse ${this.getAccordionClass(
                  "correcoesAnteriores"
                )}`}
              >
                <div className="mt-3 mb-3 text-center">
                  {/* {historicoCorrecoes.map((correcao, index) => ( *** FLUXO ANTERIOR, MANTER PARA FUTURAS ALTERACOES/CORRECOES ***
                    <div key={correcao.id} className="tabela-historico">
                      <div className="avaliador-table-labels">
                        <label className="avaliador-title-label">
                          {`Avaliador ${index + 1}${this.hasPermission(
                            "pode_ver_nome_avaliador_historico"
                          )
                            ? ":"
                            : ""
                            }`}{" "}
                        </label>
                        {this.hasPermission(
                          "pode_ver_nome_avaliador_historico"
                        ) && (
                            <label className="avaliador-name-label">
                              {correcao.avaliador}
                            </label>
                          )}
                      </div>
                      <table className="table-tag">
                        <tbody>
                          <tr className="table-header">
                            <th className="table-data">Soma</th>
                            <th className="table-data">I</th>
                            <th className="table-data">II</th>
                            <th className="table-data">III</th>
                            <th className="table-data">IV</th>
                            {possui_competencia_5 && (
                              <th className="table-data">V</th>
                            )}
                          </tr>
                          {!correcao.show_situacao && (
                            <tr className="table-row">
                              <td className="table-data">{correcao.soma}</td>
                              <td className="table-data">
                                {correcao.competencia1}
                              </td>
                              <td className="table-data">
                                {correcao.competencia2}
                              </td>
                              <td className="table-data">
                                {correcao.competencia3}
                              </td>
                              <td className="table-data">
                                {correcao.competencia4}
                              </td>
                              {possui_competencia_5 && (
                                <td className="table-data">
                                  {correcao.competencia5 === -1
                                    ? "DDH"
                                    : correcao.competencia5}
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {correcao.show_situacao && (
                        <label className="table-situacao">
                          {correcao.situacao}
                        </label>
                      )}
                    </div>
                  ))} */}
                  <HistoricoCorrecoes
                    historicoCorrecoes={historicoCorrecoes.filter(correcao => correcao.tipo !== "Recurso")}
                    verNomeAvaliadorHistorico={false}
                    fetching={false}
                    showNotaFinal={false}
                    redacao={{}}
                    verHeader={false}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Competências */}
          <div className="card card-correcao-accordion">
            <button
              type="button"
              className="card-header-correcao"
              onClick={() => this.handleAccordion("competencias")}
              data-target="#correca-respostas-competencias"
            >
              COMPETÊNCIAS
              <i
                className={`fas ${this.getAccordionArrow(
                  "competencias"
                )} float-right`}
              />
            </button>
            <div
              id="correcao-respostas-competencias"
              className={`collapse ${this.getAccordionClass("competencias")}`}
              aria-labelledby="header-itens"
              data-parent="#collapse"
            >
              {this.renderGrades()}
            </div>
            <div className="action-correcao">
              <center>
                <article className="input-nota-final">
                  <label>NOTA FINAL: </label>
                  <span> {+notaFinal}</span>
                </article>
              </center>
            </div>
          </div>

          {/* Situações */}
          <div className="card card-correcao">
            <button
              type="button"
              className="card-header-correcao"
              onClick={() => this.handleAccordion("situacoes")}
            >
              ELEGIBILIDADE
              <i
                className={`fas ${this.getAccordionArrow(
                  "situacoes"
                )} float-right`}
              />
            </button>

            <div
              id="correcao-respostas-situacoes"
              className={`collapse ${this.getAccordionClass("situacoes")}`}
            >
              <div className="card-body radio-situacoes">
                <RadioInput
                  field="correcao_situacao_id"
                  options={situacoes}
                  error={formState.errors.situacao}
                />
              </div>
            </div>
          </div>

          {/* Ocorrências */}
          {this.hasFeature("ocorrencia") &&
            !this.hasPermission("pode_corrigir_auditoria") && (
              <NewTicketCard
                indicadores={indicadores}
                onShowTicket={this.handleShowTicket}
                onChangeAccordion={this.handleAccordion}
                getAccordionArrow={this.getAccordionArrow}
                getAccordionClass={this.getAccordionClass}
                onChangeTicketContentType={this.handleChangeTicketContentType}
                ticketContentType={ticketContentType}
              />
            )}

          {/* Correçoes Pendentes */}
          {this.hasPermission("pode_corrigir_auditoria") && (
            <div className="card card-correcao border-bottom-card">
              <button
                type="button"
                className="card-header-correcao"
                onClick={() => this.handleAccordion("correcoesPendentes")}
              >
                CORREÇÕES PENDENTES
                <i
                  className={`fas ${this.getAccordionArrow(
                    "correcoesPendentes"
                  )} float-right`}
                />
              </button>
              <div
                id="correcoes-pendentes"
                className={`collapse ${this.getAccordionClass(
                  "correcoesPendentes"
                )}`}
              >
                <div className="mt-3 mb-3 text-center">
                  {!pendentesFetched && <Loading />}
                  {correcoesPendentes.length === 0 && pendentesFetched && (
                    <p>Nenhuma correção pendente.</p>
                  )}
                  {correcoesPendentes.map((correcao) => (
                    <button
                      type="button"
                      className="button-link"
                      onClick={() =>
                        this.handlePular({ sair: false, next_id: correcao.id })
                      }
                    >
                      #{correcao.redacao}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          {this.getAccordionClass("criarOcorrencia") === "hidden" && (
            <div className="action-correcao">
              <div className="row">
                <div className="col-md-6 action1 pr-1">
                  <EnviarSairButton
                    onClick={() =>
                      this.handleSalvarCorrecao({
                        sairAposSalvarCorrecao: true,
                      })
                    }
                    disabled={submitting || salvarCorrecaoDisabled}
                    submitting={submitting}
                    clickOnDisabledMessage={
                      dictionary.PATCH_CORRECAO_PREENCHER_CAMPOS
                    }
                  />
                </div>
                <div className="col-md-6 action2 pl-1">
                  <EnviarBuscarMaisUmButton
                    onClick={() =>
                      this.handleSalvarCorrecao({
                        sairAposSalvarCorrecao: false,
                      })
                    }
                    disabled={submitting || salvarCorrecaoDisabled}
                    submitting={submitting}
                    clickOnDisabledMessage={
                      dictionary.PATCH_CORRECAO_PREENCHER_CAMPOS
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {this.hasPermission("pode_corrigir_auditoria") && (
            <div className="action-correcao">
              <hr />
              <div className="row">
                <div className="col-md-6 action1 pr-1">
                  <PularSairButton
                    onClick={() =>
                      this.handlePular({ sair: true, next_id: undefined })
                    }
                    disabled={submitting}
                  />
                </div>
                <div className="col-md-6 action2 pl-1">
                  <PularBuscarMaisUmButton
                    onClick={() =>
                      this.handlePular({ sair: false, next_id: undefined })
                    }
                    disabled={submitting}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }

  resizableRedacao(column) {
    const { tamanho_coluna_redacao, tamanho_coluna_competencia } = this.state;
    if (column === "redacao" && tamanho_coluna_redacao < 9) {
      this.setState(
        {
          tamanho_coluna_redacao: tamanho_coluna_redacao + 1,
          tamanho_coluna_competencia: tamanho_coluna_competencia - 1,
        },
        () => {
          this.redacao.fitBackgroundImage();
        }
      );
    }
    if (column === "competencia" && tamanho_coluna_redacao > 5) {
      this.setState(
        {
          tamanho_coluna_competencia: tamanho_coluna_competencia + 1,
          tamanho_coluna_redacao: tamanho_coluna_redacao - 1,
        },
        () => {
          this.redacao.fitBackgroundImage();
        }
      );
    }
  }

  handleChangeState = (formState) => {
    const { hasAlertaDDH } = this.state;
    this.setState({
      formState,
    });
    const competencia5 = this.formApi.getValue("competencia5");
    if (competencia5 === -1 && hasAlertaDDH) {
      this.setState({ alertaDDH: true, hasAlertaDDH: false });
    } else if (competencia5 !== -1) {
      this.setState({
        hasAlertaDDH: true,
      });
    }
  };

  handleCloseAlertaDDH = () => {
    const { values } = this.state.formState;
    const resetCompetencia5Value = {
      ...values,
      competencia5: null,
    };
    this.formApi.setValues(resetCompetencia5Value);
    this.setState({
      alertaDDH: false,
    });
  };

  render() {
    const {
      shapes,
      link_imagem_recortada,
      total_correcoes_diarias,
      cota_diaria,
      visibleExercicioFinal,
      contador,
      fetchCorrecaoSuccess,
      fetchImagemSuccess,
      loadingGabarito,
      fetchImagemError,
      redacao,
      pendente,
      angulo_imagem,
      tamanho_coluna_redacao,
      tamanho_coluna_competencia,
      mostrarAbandonarBanca,
      tipo_cota,
      alertaDDH,
      submitting,
      arrows,
      exibir_campo_observacao,
      projetoPreferencias,
    } = this.state;

    return (
      <section>
        <Form
          onChange={this.handleChangeState}
          onValueChange={this.onValueChange}
          getApi={(formApi) => {
            this.formApi = formApi;
          }}
        >
          {({ formState }) => {
            if (!fetchCorrecaoSuccess) {
              return <Loading />;
            }

            const showRedacao = fetchImagemSuccess || fetchImagemError;

            return (
              <Fragment>
                {!showRedacao && <Loading />}
                <div style={{ display: showRedacao ? "block" : "none" }}>
                  <BreadcrumbCorrecao
                    id_redacao={redacao}
                    total_correcoes_diarias={total_correcoes_diarias}
                    cota_diaria={cota_diaria}
                    tipo_cota={tipo_cota}
                    contador={contador}
                    pendente={pendente}
                    limitar_tempo_avaliador={this.hasFeature(
                      "limitar_tempo_avaliador"
                    )}
                    mostrarAbandonarBanca={mostrarAbandonarBanca}
                    correcaoId={this.props.match.params.id}
                    labels={{
                      cota: this.getMessage("LABEL_COTA"),
                      corrigidasSemana: this.getMessage(
                        "LABEL_CORRIGIDAS_SEMANA"
                      ),
                      corrigidasDia: this.getMessage("LABEL_CORRIGIDAS_DIA"),
                      corrigidas: this.getMessage("LABEL_CORRIGIDAS_TOTAL"),
                    }}
                    projetoPreferencias={projetoPreferencias}
                  />
                  <div className="container-fluid">
                    <div className="page-content-correcao">
                      <div className="row">
                        <section
                          className={`col-12 col-md-${tamanho_coluna_redacao}  p-0 col-redacao`}
                        >
                          <ReactTooltip
                            place="right"
                            type="dark"
                            effect="solid"
                            id="tooltip-resize"
                          />
                          <button
                            type="button"
                            className={`btn-fixed _${tamanho_coluna_redacao}x hidden-xs`}
                            onClick={() => this.resizableRedacao("redacao")}
                            data-tip="AUMENTAR REDAÇÃO"
                            data-for="tooltip-resize"
                          >
                            <i className="fas fa-arrow-right" />
                          </button>
                          <button
                            type="button"
                            className={`btn-fixed _${tamanho_coluna_redacao}x hidden-xs mt-5 `}
                            onClick={() => this.resizableRedacao("competencia")}
                            data-tip="DIMINUIR REDAÇÃO"
                            data-for="tooltip-resize"
                          >
                            <i className="fas fa-arrow-left" />
                          </button>
                          <Redacao
                            ref={(node) => {
                              this.redacao = node;
                            }}
                            src={link_imagem_recortada}
                            shapes={shapes}
                            show={fetchImagemSuccess}
                            onInitCanvas={this.onInitCanvas}
                            fetchImagemError={fetchImagemError}
                            arrowTool={this.hasFeature(
                              "exibe_ferramenta_ortografia"
                            )}
                            addDeviationArrow={this.addDeviationArrow}
                            removeDeviationArrow={this.removeDeviationArrow}
                            angulo_imagem={angulo_imagem}
                            tamanho_coluna_redacao={tamanho_coluna_redacao}
                            arrows={arrows}
                            canDownloadImage={false}
                            exibir_campo_observacao={exibir_campo_observacao}
                          />
                        </section>
                        <div
                          className={`col-12 col-md-${tamanho_coluna_competencia} p-0 col-competencias`}
                        >
                          {this.renderMainAccordion(formState)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          }}
        </Form>
        <Footer />
        <ModalGabarito
          visible={this.state.visible}
          onClose={this.onClose}
          title="Gabarito"
          content={this.renderTableGabarito()}
          showFooter={!loadingGabarito}
        />
        <ModalConfirmacao
          visible={visibleExercicioFinal}
          onClose={this.onCloseLogout}
          title="Exercício finalizado!"
          content={this.getMessage("EXERCICIO_FINALIZADO")}
          buttonFunction={this.onCloseLogout}
          textSim="OK"
        />
        <ModalConfirmacao
          visible={alertaDDH}
          title="DDH selecionado"
          onClose={this.handleCloseAlertaDDH}
          content={this.getMessage("ALERTA_DDH")}
          buttonFunction={() => {
            this.setState({ alertaDDH: false });
          }}
          textSim="Sim"
          textNao="Não"
          submitting={submitting}
        />
      </section>
    );
  }
}

Correcao.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Correcao;
