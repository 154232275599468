import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../components';
import FollowGeralTable from '../pages/v2/acompanhamento/FollowGeralTable';
import AcompanhamentoDeSituacoes from '../pages/v2/acompanhamento/situacoes/AcompanhamentoDeSituacoes';

const SwitchAcompanhamento = () => {
  return (
    <Switch>
      <PrivateRoute
        permission="pode_visualizar_acompanhamento"
        path="/acompanhamento/geral"
        component={FollowGeralTable}
      />
      <PrivateRoute
        permission="pode_visualizar_acompanhamento_situacoes"
        path="/acompanhamento/situacoes"
        component={AcompanhamentoDeSituacoes}
      />
    </Switch>
  );
};

export default SwitchAcompanhamento;
