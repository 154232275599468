import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  StyledContainer,
  StyledPage,
} from './styles/StyledCreateProjectCorrecao';
import SettingsProjectCorrecao from './pages/SettingsProjectCorrecao';
import CreateSkillsForProject from './pages/CreateSkillsForProject';

const CreateProjectCorrecao = () => {
  const [projectAndGrade, setProjectAndGrade] = useState();
  const [indexPage, setIndexPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [projectData, setProjectData] = useState({
    correcaoMultiplaEscolha: false,
    utilizacaoDeRecurso: false,
    configurarVistaRedacao: false,
  });
  return (
    <StyledPage>
      <StyledContainer>
        {indexPage === 1 && (
          <SettingsProjectCorrecao
            projectData={projectData}
            setProjectData={setProjectData}
            setIndexPage={setIndexPage}
            setProjectAndGrade={setProjectAndGrade}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {indexPage === 2 && (
          <CreateSkillsForProject
            setIndexPage={setIndexPage}
            projectAndGrade={projectAndGrade}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </StyledContainer>
    </StyledPage>
  );
};

export default withRouter(CreateProjectCorrecao);
