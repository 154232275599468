import axios from "axios";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { ComponentBase } from "../../base";
import VistaProvaExterna from "../../components/v2/vista-de-prova/VistaProvaExterna";
import { API_URL } from "../../consts";
import { fetchVistaProva } from "../../services/banco_redacoes";
import { getShapesWithSetas } from "../../utils";

class VistaProvaExternaPage extends ComponentBase {
  state = {
    redacao: undefined,
    redacaoOriginal: undefined,
    desvios: [],
    shapes: {},
    fetching: false,
    fetchImagemError: false,
    historicoCorrecoes: [],
    observacoesCompetencia: [],
    descricaoCompetencia: "",
    fetchingHistoricoCorrecoes: false,
    activeImage: "RECORTADA",
  };

  getImagem = () => {
    const { redacao, shapes, desvios, redacaoOriginal, activeImage } =
      this.state;

    if (activeImage === "ORIGINAL") {
      return {
        redacao: redacaoOriginal,
        desvios: [],
        showLegend: false,
        shapes: {
          lines: [],
          arrows: [],
        },
      };
    }
    return {
      redacao,
      shapes,
      desvios,
      showLegend: true,
    };
  };

  fetchVistaProva = () => {
    const { match } = this.props;
    const { token, projetoId } = match.params;
    this.setState({ fetching: true, fetchingHistoricoCorrecoes: true });
    const params = { token, projeto: projetoId };

    // TODO: Alterar nomeclatura da váriavel redacao para correcao
    // Variavél chamada de redacao corresponde a uma correção
    // Não foi alterada por segurança e urgência inicial da tarefa
    fetchVistaProva(params)
      .then((redacao) => {
        this.setState({
          redacao,
          historicoCorrecoes: get(redacao, "correcao_detalhada")
            ? [redacao.correcao_detalhada]
            : [],
          observacoesCompetencia: get(redacao, "observacao_competencias")
            ? JSON.parse(redacao.observacao_competencias)
            : [],
          descricaoCompetencia: get(redacao, "competencia_descricao"),
          redacaoOriginal: {
            ...redacao,
            src: redacao.src_imagem_original,
          },
          fetchImagemError: !redacao.src,
        });
        const shapes = getShapesWithSetas(redacao.correcao, redacao.setas);
        this.setState({
          shapes,
        });
        this.fetchDesvios({ projeto: redacao.projeto_id });
      })
      .catch(() => toast.error("Nenhuma redação encontrada."))
      .finally(() =>
        this.setState({ fetching: false, fetchingHistoricoCorrecoes: false })
      );
  };

  fetchDesvios = (params) => {
    axios
      .get(`${API_URL}/grades/setas_desvio`, { params })
      .then((response) => this.setState({ desvios: response.data }));
  };

  componentDidMount = () => {
    this.fetchVistaProva();
  };

  render() {
    const {
      fetching,
      fetchImagemError,
      historicoCorrecoes,
      observacoesCompetencia,
      descricaoCompetencia,
      fetchingHistoricoCorrecoes,
      activeImage,
      desvios,
      redacao,
    } = this.state;
    const { match } = this.props;
    const { token } = match.params;

    return (
      <div className="container">
        <div className="page-container-border">
          <div className="row">
            <div className="col">
              <h1 className="text-title">
                Questão {get(redacao, "projeto")}
                <Link
                  style={{ fontSize: "15px", marginLeft: "5px" }}
                  to={`/vista-prova-externa/${token}`}
                >
                  Voltar
                </Link>
              </h1>
            </div>
          </div>
          <ul className="nav nav-tabs mb-3">
            <li className="nav-item active">
              <button
                type="button"
                className={`nav-link ${
                  activeImage === "ORIGINAL" ? "active" : ""
                }`}
                onClick={() => this.setState({ activeImage: "ORIGINAL" })}
              >
                ORIGINAL
              </button>
            </li>
            <li className="nav-item ml-4">
              <button
                type="button"
                className={`nav-link ${
                  activeImage === "RECORTADA" ? "active" : ""
                }`}
                onClick={() => this.setState({ activeImage: "RECORTADA" })}
              >
                RECORTADA
              </button>
            </li>
          </ul>
          <VistaProvaExterna
            verDadosCandidato={this.hasPermission("ver_dados_candidato")}
            verNomeAvaliadorHistorico={this.hasPermission(
              "pode_ver_nome_avaliador_historico"
            )}
            historicoCorrecoes={historicoCorrecoes}
            observacoesCompetencia={observacoesCompetencia}
            descricaoCompetencia={descricaoCompetencia}
            fetchImagemError={fetchImagemError}
            fetchingHistoricoCorrecoes={fetchingHistoricoCorrecoes}
            fetching={fetching}
            desvios={desvios}
            {...this.getImagem()}
          />
        </div>
      </div>
    );
  }
}

VistaProvaExternaPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
};

export default withRouter(VistaProvaExternaPage);
