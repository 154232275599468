import React, { useEffect, useState } from 'react';
import { Form } from 'informed';
import { API_URL } from '../../../consts';
import Axios from 'axios';
import VerticalBarChart from '../../../components/v2/graficos/VerticalBarChart';
import { toast } from 'react-toastify';
import SelectInput from '../../../components/SelectInput';
import HorizontalBarChart from '../../../components/v2/graficos/HorizontalBarChart';

const fakeData = [
  {
    "projeto_id": 702,
    "escola_id": 194,
    "etapa_id": 85,
    "nr_total_redacoes_concluidas": 268,
    "nr_redacoes_concluidas_com_notas_normais": 267,
    "media_geral_nota": 501.52,
    "media_geral_nota_competencia": 110.52,
    "competencia_1": {
      "nome": "Competencia I",
      "porcentagem_0": 40,
      "porcentagem_40": 0,
      "porcentagem_80": 20,
      "porcentagem_120": 20,
      "porcentagem_160": 0,
      "porcentagem_200": 20,
      "media_nota": 72,
    },
    "competencia_2": {
      "nome": "Competencia II",
      "porcentagem_0": 20,
      "porcentagem_40": 20,
      "porcentagem_80": 10,
      "porcentagem_120": 10,
      "porcentagem_160": 20,
      "porcentagem_200": 20,
      "media_nota": 80,
    },
    "competencia_3": {
      "nome": "Competencia III",
      "porcentagem_0": 10,
      "porcentagem_40": 20,
      "porcentagem_80": 20,
      "porcentagem_120": 20,
      "porcentagem_160": 20,
      "porcentagem_200": 10,
      "media_nota": 93.33,
    },
    "competencia_4": {
      "nome": "Competencia IV",
      "porcentagem_0": 20,
      "porcentagem_40": 10,
      "porcentagem_80": 10,
      "porcentagem_120": 20,
      "porcentagem_160": 20,
      "porcentagem_200": 20,
      "media_nota": 108.24, 
    },
    "competencia_5": {
      "nome": "Competencia V",
      "porcentagem_0": 20,
      "porcentagem_40": 20,
      "porcentagem_80": 20,
      "porcentagem_120": 20,
      "porcentagem_160": 10,
      "porcentagem_200": 10,
      "media_nota": 100, 
    },
  },
  {
    "projeto_id": 702,
    "escola_id": 194,
    "etapa_id": 90,
    "nr_total_redacoes_concluidas": 257,
    "nr_redacoes_concluidas_com_notas_normais": 257,
    "media_geral_nota": 505.75,
    "media_geral_nota_competencia": 115.75,
    "competencia_1": {
      "nome": "Competência A",
      "porcentagem_0": 30,
      "porcentagem_40": 10,
      "porcentagem_80": 20,
      "porcentagem_120": 15,
      "porcentagem_160": 10,
      "porcentagem_200": 15,
      "media_nota": 70.5
    },
    "competencia_2": {
      "nome": "Competência B",
      "porcentagem_0": 15,
      "porcentagem_40": 20,
      "porcentagem_80": 10,
      "porcentagem_120": 15,
      "porcentagem_160": 20,
      "porcentagem_200": 20,
      "media_nota": 85.2
    },
    "competencia_3": {
      "nome": "Competência C",
      "porcentagem_0": 10,
      "porcentagem_40": 10,
      "porcentagem_80": 20,
      "porcentagem_120": 25,
      "porcentagem_160": 15,
      "porcentagem_200": 20,
      "media_nota": 92.1
    },
    "competencia_4": {
      "nome": "Competência D",
      "porcentagem_0": 20,
      "porcentagem_40": 15,
      "porcentagem_80": 10,
      "porcentagem_120": 20,
      "porcentagem_160": 20,
      "porcentagem_200": 15,
      "media_nota": 106.8
    },
    "competencia_5": {
      "nome": "Competência E",
      "porcentagem_0": 15,
      "porcentagem_40": 15,
      "porcentagem_80": 20,
      "porcentagem_120": 25,
      "porcentagem_160": 15,
      "porcentagem_200": 10, 
      "media_nota": 112.5
      
    }
  },
];

function AnaliseResultadoNotasCompetencia() {
  const [data, setData] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [escolas, setEscolas] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [FormApi, setFormApi] = useState();
  const [formValues, setFormValues] = useState();
  const [fetching, setFetching] = useState(false);

  const configDataGroupBar = () => {
    if (data.length !== 0) {
      return {
        data: {
          labels: [
            `${data.competencia_1.nome}`,
            `${data.competencia_2.nome}`,
            `${data.competencia_3.nome}`,
            `${data.competencia_4.nome}`,
            `${data.competencia_5.nome}`,
          ],
          datasets: [
            {
              label: '0',
              backgroundColor: [
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(54, 162, 235, 0.5)',
              ],
              borderWidth: 1,
              data: [
                data.competencia_1.porcentagem_0,
                data.competencia_2.porcentagem_0,
                data.competencia_3.porcentagem_0,
                data.competencia_4.porcentagem_0,
                data.competencia_5.porcentagem_0,
              ],
              stack: 'Stack 0',
            },
            {
              label: '40',
              backgroundColor: [
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
              ],
              borderColor: [
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
                'rgba(255, 206, 86, 0.5)',
              ],
              borderWidth: 1,
              data: [
                data.competencia_1.porcentagem_40,
                data.competencia_2.porcentagem_40,
                data.competencia_3.porcentagem_40,
                data.competencia_4.porcentagem_40,
                data.competencia_5.porcentagem_40,
              ],
              stack: 'Stack 0',
            },
            {
              label: '80',
              backgroundColor: [
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
              ],
              borderColor: [
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
                'rgba(75, 192, 192, 0.5)',
              ],
              borderWidth: 1,
              data: [
                data.competencia_1.porcentagem_80,
                data.competencia_2.porcentagem_80,
                data.competencia_3.porcentagem_80,
                data.competencia_4.porcentagem_80,
                data.competencia_5.porcentagem_80,
              ],
              stack: 'Stack 0',
            },
            {
              label: '120',
              backgroundColor: [
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
              ],
              borderColor: [
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(153, 102, 255, 0.5)',
              ],
              borderWidth: 1,
              data: [
                data.competencia_1.porcentagem_120,
                data.competencia_2.porcentagem_120,
                data.competencia_3.porcentagem_120,
                data.competencia_4.porcentagem_120,
                data.competencia_5.porcentagem_120,
              ],
              stack: 'Stack 0',
            },
            {
              label: '160',
              backgroundColor: [
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
              ],
              borderColor: [
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(255, 159, 64, 0.5)',
              ],
              borderWidth: 1,
              data: [
                data.competencia_1.porcentagem_160,
                data.competencia_2.porcentagem_160,
                data.competencia_3.porcentagem_160,
                data.competencia_4.porcentagem_160,
                data.competencia_5.porcentagem_160,
              ],
              stack: 'Stack 0',
            },
            {
              label: '200',
              backgroundColor: [
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
              ],
              borderColor: [
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
                'rgba(0, 159, 64, 0.5)',
              ],
              borderWidth: 1,
              data: [
                data.competencia_1.porcentagem_200,
                data.competencia_2.porcentagem_200,
                data.competencia_3.porcentagem_200,
                data.competencia_4.porcentagem_200,
                data.competencia_5.porcentagem_200,
              ],
              stack: 'Stack 0',
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stacked: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stacked: true,
                  max: 100,
                },
              },
            ],
          },
        },
      };
    }
  };
  const configDataVerticalBar = () => {
    if (data.length !== 0) {
      return {
        data: {
          labels: [
            `${data.competencia_5.nome}`,
            `${data.competencia_4.nome}`,
            `${data.competencia_3.nome}`,
            `${data.competencia_2.nome}`,
            `${data.competencia_1.nome}`,
          ],
          datasets: [
            {
              label: 'Valores',
              backgroundColor: 'rgba(75,192,192,0.2)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(75,192,192,0.4)',
              hoverBorderColor: 'rgba(75,192,192,1)',
              data: [
                data.competencia_5.media_nota,
                data.competencia_4.media_nota,
                data.competencia_3.media_nota,
                data.competencia_2.media_nota,
                data.competencia_1.media_nota,
              ],
            },
            {
              label: 'Média',
              data: [
                { x: data.media_geral_nota_competencia },
                { x: data.media_geral_nota_competencia },
                { x: data.media_geral_nota_competencia },
                { x: data.media_geral_nota_competencia },
                { x: data.media_geral_nota_competencia },
              ],
              backgroundColor: 'rgba(255,0,0,0.2)',
              borderColor: 'rgba(255,0,0,1)',
              borderWidth: 1,
              showLine: true,
              lineTension: 0,
              pointStyle: 'cross',
            },
          ],
        },
        options: {
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 5,
                },
              },
            ],
          },
        },
      };
    }
  };

  const fetchData = async () => {
    setFetching(true);
    console.log(formValues.values.projeto, formValues.values.escola, formValues.values.etapa);
    if (formValues.values.projeto && formValues.values.escola && formValues.values.etapa) {
      const findData = fakeData.find(r => r.projeto_id == formValues.values.projeto && r.escola_id == formValues.values.escola && r.etapa_id == formValues.values.etapa)
      if(findData){
        setData(findData);
      } else {
        setData(findData);
        toast.error('Nenhum dado encontrado com os parametros selecionados.');
      }
    // try {
    //   const params = {
    //     projeto_id: formValues.values.projetos,
    //     escola_id: formValues.values.escolas,
    //     etapa_id: formValues.values.etapas,
    //     turma_id: formValues.values.turmas,
    //   };
    //   const res = await Axios.get(`${API_URL}/urldadosdorelatorio`, { params });
    //   setData(res.data);
    //   if (res.data.length === 0) {
    //     toast.error('Nenhum dado encontrado com os parametros selecionados.');
    //   }
    // } catch (err) {
    //   toast.error('Erro ao buscar os dados de relátorio, tente novamente.');
    // }
    } else {
      toast.warn('Necessário selecionar os campos PROJETO, ESCOLA e ETAPA para buscar os dados.');
    }
    setFetching(false);
  };

  const fetchProjetos = async () => {
    try {
      const res = await Axios.get(`${API_URL}/projeto/all`);
      setProjetos(res.data.map(x => ({
        value: x.id,
        label: x.descricao,
      })));
    } catch (err) {
      toast.error('Erro ao buscar projetos, tente novamente.');
    }
  };
  const fetchEscolas = async () => {
    try {
      const res = await Axios.get(`${API_URL}/inep/escolas`);
      setEscolas(res.data.map(r => ({
        value: r.id,
        label: r.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar escolas, tente novamente.');
    }
  };
  const fetchEtapas = async () => {
    try {
      const res = await Axios.get(`${API_URL}/inep/etapas?projetos__id=${formValues.values.projeto}`);
      setEtapas(res.data.map(r => ({
        value: r.id,
        label: r.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar etapas, tente novamente.');
    }
  };
  const fetchTurmas = async () => {
    try {
      const params = {
        etapa: formValues.values.etapa,
        escola: formValues.values.escola,
        projetos__id: formValues.values.projeto,
      };
      const res = await Axios.get(`${API_URL}/inep/turmas`, { params });
      setTurmas(res.data.map(x => ({
        value: x.id,
        label: x.nome,
      })));
    } catch (err) {
      toast.error('Erro ao buscar turmas, tente novamente.');
    }
  };

  useEffect(() => {
    if (data) {
      configDataGroupBar();
      configDataVerticalBar();
    }
  }, [data]);

  useEffect(() => {
    fetchProjetos();
  }, []);

  const handleChange = (field) => {
    if (field === 'Escolas') {
      FormApi.setValue('escola', '');
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEscolas();
      setEtapas();
      setTurmas();
      fetchEscolas();
    }
    if (field === 'Etapas') {
      FormApi.setValue('etapa', '');
      FormApi.setValue('turma', '');
      setEtapas();
      setTurmas();
      fetchEtapas();
    }
    if (field === 'Turmas') {
      FormApi.setValue('turma', '');
      setTurmas();
      fetchTurmas();
    }
  };

  const handleFocus = (fieldNecessario, fieldSelecionado) => {
    if (!formValues.values[fieldNecessario]) {
      toast.warn(`Selecione o campo ${fieldNecessario} antes de selecionar o campo ${fieldSelecionado}`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1
        style={{
          fontFamily: 'pulic-sans',
          fontSize: '38px',
          marginTop: '10px',
        }}
      >
        Análise de Notas por Competência
      </h1>
      <Form
        onChange={(formState) => setFormValues(formState)}
        getApi={(formApi) => setFormApi(formApi)}
        style={{
          display: 'flex',
          width: '80%',
          height: '120px',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '50px',
        }}
      >
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Projeto"
            field="projeto"
            options={projetos}
            onChange={() => handleChange('Escolas')}
          />
        </div>
        <div
          style={{ width: '45%', margin: '5px' }}
        >
          <SelectInput
            label="Escola"
            field="escola"
            options={escolas}
            onChange={() => handleChange('Etapas')}
            onFocus={() => handleFocus('projeto', 'escola')}
          />
        </div>
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Etapa"
            field="etapa"
            options={etapas}
            onChange={() => handleChange('Turmas')}
            onFocus={() => handleFocus('escola', 'etapa')}
          />
        </div>
        <div
          style={{ width: '20%', margin: '5px' }}
        >
          <SelectInput
            label="Turma"
            field="turma"
            options={turmas}
            onFocus={() => handleFocus('etapa', 'turma')}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            onClick={() => fetchData()}
            disabled={fetching}
            className="btn btn-primary"
          >
            {fetching ? 'BUSCANDO...' : 'BUSCAR'}
          </button>
        </div>
      </Form>
      {
        data && data.length !== 0 && (
          <>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <HorizontalBarChart
                data={configDataVerticalBar().data}
                titleChart="Notas por Competência"
                options={configDataVerticalBar().options}                
              />
              <VerticalBarChart
                data={configDataGroupBar().data}
                titleChart="Porcentagem por Competência"
                options={configDataGroupBar().options}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <table className="table table-striped"
                style={{
                  width: '90%',
                }}
              >
                <thead>
                  <tr
                    style={{
                      fontFamily: 'pulic-sans',
                    }}
                  >
                    <th scope="col">Corrigidas</th>
                    <th scope="col">Com notas Normal</th>
                    <th scope="col">Média nota Geral</th>
                    <th scope="col">Média nota Geral Competência</th>
                    <th scope="col">{`Média nota ${data.competencia_1.nome}`}</th>
                    <th scope="col">{`Média nota ${data.competencia_2.nome}`}</th>
                    <th scope="col">{`Média nota ${data.competencia_3.nome}`}</th>
                    <th scope="col">{`Média nota ${data.competencia_4.nome}`}</th>
                    <th scope="col">{`Média nota ${data.competencia_5.nome}`}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.nr_total_redacoes_concluidas}</td>
                    <td>{data.nr_redacoes_concluidas_com_notas_normais}</td>
                    <td>{data.media_geral_nota}</td>
                    <td>{data.media_geral_nota_competencia}</td>
                    <td>{data.competencia_1.media_nota}</td>
                    <td>{data.competencia_2.media_nota}</td>
                    <td>{data.competencia_3.media_nota}</td>
                    <td>{data.competencia_4.media_nota}</td>
                    <td>{data.competencia_5.media_nota}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )
      }
    </div>
  );
}

export default AnaliseResultadoNotasCompetencia;
