import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const HorizontalBarChart = ({ data, titleChart, options, width, height }) => {
  return (
    <div style={{
      width: `${width ? `${width}` : '400px'}`,
      height: `${height ? `${height}` : '400px'}`,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}
    >
      <h2
        style={{
          fontFamily: 'pulic-sans',
          fontSize: '25px',
          marginBottom: '10px',
          textAlign: 'center',
        }}
      >
        {titleChart}
      </h2>
      <div style={{
        width: '100%',
        height: '100%',
      }}
      >
        <HorizontalBar options={options} data={data} />
      </div>
    </div>
  );
};

export default HorizontalBarChart;
