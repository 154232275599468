import React from 'react';
import { get } from 'lodash';
import Loading from './Loading';
import { ComponentBase } from '../base';
import { login, setAuthorizedToken } from '../services';
import { setFormErrors } from '../services/utils';

class ExternalLogin extends ComponentBase {
  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = () => {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    const iframe = params.get('iframe') || false;
    const redirect = params.get('redirect') || '';

    const values = {
      username: params.get('identifier'),
      password: 'sesialagoas@2024',
    };

    login(values)
      .then((response) => {
        const { token } = response.data;
        setAuthorizedToken(token);
        localStorage.setItem('iframe', JSON.stringify(!!iframe));
        history.push(`/${atob(redirect)}`);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            const errors = get(error, 'response.data', {});
            const { non_field_errors, ...fieldErrors } = errors;
            this.setState({ non_field_errors: non_field_errors || [] });
            setFormErrors(this.formApi, fieldErrors);
          } else {
            alert('Ocorreu um erro no servidor.');
          }
        } else if (error.request) {
          alert('Erro de conexão com o servidor.');
        } else {
          alert('Erro desconhecido.');
        }
        this.setState({ loggingIn: false });
      });
  };

  render() {
    return (
      <div className="container">
        <div
          className="d-flex"
          style={{ alignItems: 'center', flexDirection: 'column' }}
        >
          <Loading width="80px" />
          <span>Autenticando...</span>
        </div>
      </div>
    );
  }
}

export default ExternalLogin;
