import React, { useState, useEffect } from 'react';
import { withFormState } from 'informed';
import PropTypes from 'prop-types';
import {
  TextAreaInput, ReactSelectInput, EnviarSairButton, EnviarBuscarMaisUmButton,
  Loading,
} from '../../..';
import TicketContentSection from './TicketContentSection';
import dictionary from '../../../../dictionary';
import { API_URL } from '../../../../consts';
import axios from 'axios';
import { getAxiosConfig }  from '../../../../services/'



const TicketContentType = ({
  ticketContentType,
  onChangeTicketContentType,
  isSendButtonDisabled,
  onSendTicket,
  sendingTicket,
}) => {
  const [types, setTypes] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchTicketTypes = () => {
    axios.get(`${API_URL}/types/list_types`, getAxiosConfig())
      .then((response) => {
        setTypes(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Erro ao buscar os tipos de ticket:', err);
        setIsLoading(false);
      });
  };


useEffect(() => {
  fetchTicketTypes();
}, []);
  
  const PROBLEMA_IMAGEM = types && types.filter(ticketType => ticketType.category === 2);
  const DUVIDA_PEDAGOGICA = types && types.filter(ticketType => ticketType.category === 1);

  switch (ticketContentType) {
    case 'DUVIDA_PEDAGOGICA':

      return !isLoading ? (
        <TicketContentSection
          title="DÚVIDA PEDAGÓGICA"
          onSendTicket={onSendTicket}
          onChangeTicketContentType={onChangeTicketContentType}
          ticketContentType={ticketContentType}
          isSendButtonDisabled={isSendButtonDisabled}
          sendingTicket={sendingTicket}
        >
            <ReactSelectInput
            isClearable
            label="DÚVIDA PEDAGÓGICA"
            field="type"
            options={DUVIDA_PEDAGOGICA}
          />          
          <TextAreaInput label="OBSERVAÇÃO" field="question" placeholder="Descreva o motivo da ocorrência" rows="3" />
        </TicketContentSection>
      ) :
        <Loading width="80px" />;

    case 'PROBLEMA_IMAGEM':
      return !isLoading ? (
        <TicketContentSection
          title="PROBLEMA DE IMAGEM"
          onSendTicket={onSendTicket}
          onChangeTicketContentType={onChangeTicketContentType}
          ticketContentType={ticketContentType}
          isSendButtonDisabled={isSendButtonDisabled}
          sendingTicket={sendingTicket}
        >
          <ReactSelectInput
            isClearable
            label="PROBLEMA DE IMAGEM"
            field="type"
            options={PROBLEMA_IMAGEM}
          />
          <TextAreaInput
            label="OBSERVAÇÃO"
            field="question"
            placeholder="Descreva o motivo da ocorrência"
            rows="3"
          />
        </TicketContentSection>
      ) :
        <Loading width="80px" />;
    default:
      return (
        <div className="card-body">
          <button
            type="button"
            onClick={() => onChangeTicketContentType('DUVIDA_PEDAGOGICA')}
            className="btn bg-warning-color w-100"
          >
            DÚVIDAS PEDAGÓGICAS
          </button>
          <button
            type="button"
            onClick={() => onChangeTicketContentType('PROBLEMA_IMAGEM')}
            className="btn bg-warning-color w-100 mt-2 mb-2"
          >
            PROBLEMAS DE IMAGEM
          </button>
          <div className="row mt-3">
            <div className="col-md-6 pr-1">
              <EnviarSairButton
                onClick={() => onSendTicket({ sair: true })}
                disabled={sendingTicket || !isSendButtonDisabled()}
                clickOnDisabledMessage={dictionary.POST_OCORRENCIA_PREENCHER_CAMPOS}
              />
            </div>
            <div className="col-md-6 pl-1">
              <EnviarBuscarMaisUmButton
                onClick={() => onSendTicket()}
                disabled={sendingTicket || !isSendButtonDisabled()}
                clickOnDisabledMessage={dictionary.POST_OCORRENCIA_PREENCHER_CAMPOS}
              />
            </div>
          </div>
        </div>
      );
  }
};

TicketContentType.propTypes = {
  ticketContentType: PropTypes.string,
  onChangeTicketContentType: PropTypes.func,
  possui_competencia_5: PropTypes.bool,
  formState: PropTypes.shape({
    values: PropTypes.object,
  }).isRequired,
};

TicketContentType.defaultProps = {
  ticketContentType: '',
  onChangeTicketContentType: () => {},
  possui_competencia_5: false,
};

export default withFormState(TicketContentType);
