import { get, size } from "lodash";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import TableContext from "../../contexts/TableContext";
import { FooterTable } from "../../components";
import Table from "../../components/Table";

const ProjectsList = () => {
  const tableContext = useContext(TableContext);

  const columns = [
    {
      accessor: "id",
      Header: "ID",
      width: 70,
      Footer: () => <FooterTable media="Total" />,
    },
    {
      accessor: "title",
      Header: "Título",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "description",
      Header: "Descrição",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "startDate",
      Header: "Data Início",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "endDate",
      Header: "Data Término",
      Footer: () => <FooterTable media="-" />,
    },
    {
      accessor: "edit",
      width: 70,
      filterable: false,
      Footer: (row) => <FooterTable media={size(get(row, "data"))} />,
      Cell: (cell) => (
        <Link
          className="btn"
          to={`/configurar-projeto/${get(cell, "original.id")}`}
        >
          <i className="fa fa-edit" />
        </Link>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="page-container-border">
        <h1 className="text-title no-border">Projetos</h1>
        <hr className="m-0" />
        <div className="w-100 d-flex justify-content-end mt-2 mb-2">
          <button className="btn btn-primary shadow-sm rounded px-4 py-2 btn-custom">
            <Link
              className="text-center"
              to="/configurar-projeto"
              style={{
                display: "inline-block",
                width: "auto",
                textAlign: "center",
                color: "white",
              }}
            >
              Criar Projeto
            </Link>
          </button>
        </div>

        <Table className="m-2" columns={columns} {...tableContext} />
      </div>
    </div>
  );
};

export default ProjectsList;
