import axios from "axios";
import { API_URL } from "../../../consts";
import { getAxiosConfig } from "../../../services";

export const fetchProjectsData = (params = {}) => {
  return axios
    .get(`${API_URL}/projeto/list-projects`, { params, ...getAxiosConfig() })
    .then((response) => {
      const data = response.data.map((projeto) => ({
        id: projeto.id,
        title: projeto.titulo,
        description: projeto.descricao,
        startDate: projeto.data_inicio,
        endDate: projeto.data_termino,
        external_id: projeto.external_id,
      }));
      return data;
    })
    .catch((error) => {
      console.error("Erro ao buscar dados:", error);
      throw error;
    });
};

export default fetchProjectsData;
