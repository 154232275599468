import React from 'react';
import { Bar } from 'react-chartjs-2';

const ComparativeBarChart = ({ chartData }) => {
  const { chartColors, notasRedacao1, notasRedacao2, maxNoteAplication } = chartData;
  const options = {
    title: {
      display: true,
      text: 'Comparativo de notas',
      fontSize: 25,
      fontType: 'open-sans',
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Competencias e Nota Final',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: maxNoteAplication,
          },
          scaleLabel: {
            display: true,
            labelString: 'Pontos',
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
    },
  };

  const labels = ['Nota Final', 'Competência 1', 'Competência 2', 'Competência 3', 'Competência 4', 'Competência 5'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Prova 1',
        data: notasRedacao1,
        backgroundColor: 'rgba(165,170,217,1)',
        barPercentage: 0.6,
        categoryPercentage: 1,
        z: 0,
        order: 1,
      },
      {
        label: 'Prova 2',
        data: notasRedacao2,
        backgroundColor: chartColors,
        barPercentage: 0.4,
        categoryPercentage: 1,
        z: 1,
        borderRadius: 2,
      },
    ],
  };

  return (
  <div style={{ padding: "15px" }} >
    <Bar options={options} data={data} />
  </div>
  );
};

export default ComparativeBarChart;
