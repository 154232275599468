import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Countdown from 'react-countdown-now';
import { toast } from 'react-toastify';
import Rodal from 'rodal';
import ModalConfirmacao from './ModalConfirmacao';
import { toastConfig } from '../utils';
import dictionary from '../dictionary';
import { logout } from '../services';
import { ComponentBase } from '../base';
import AbandonarBancaButton from './AbandonarBancaButton';

class BreadcrumbCorrecao extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  renderer = ({
    hours, minutes, seconds, completed,
  }) => {
    if (completed && !this.state.visible) { this.setState({ visible: true }); }
    return (
      <article className="countdown-correcao">
        <span>Seu exercício encerra em:
          <span className="countdown-style ml-1">{hours}:{minutes}:{seconds}
          </span>
        </span>
      </article>
    );
  };

  getLabelCorrigidas = (tipo_cota) => {
    const { labels } = this.props;
    if (tipo_cota === 'S') {
      return labels.corrigidasSemana;
    }
    if (tipo_cota === 'D') {
      return labels.corrigidasDia;
    }
    if (tipo_cota === 'U') {
      return labels.corrigidas;
    }
  }

  handleLogout() {
    const { history } = this.props;
    toast.error(dictionary.TEMPO_ESGOTOU, toastConfig);
    this.setState({ visible: false });
    logout();
    history.push('/login');
  }

  render() {
    const { showModal } = this.state;
    const {
      id_redacao,
      total_correcoes_diarias,
      cota_diaria,
      contador,
      limitar_tempo_avaliador,
      pendente,
      tipo_cota,
      labels,
      mostrarAbandonarBanca,
      correcaoId,
      projetoPreferencias,
    } = this.props;

    return (
      <Fragment>
        <div className="breadcrumbs-bg pt-2 pb-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-12 col-md">
                <div className="text-breadcrumb">
                  <span className="link">
                    <Link to="/correcoes"> CORREÇÕES / </Link>
                  </span>
                  <span className="ml-1">Nova Correção <strong>#{id_redacao}</strong> {pendente && '(PENDENTE)'}</span>
                  {projetoPreferencias && projetoPreferencias.show_link_proposta_redacao && (
                    <button
                      style={{ marginLeft: '1rem' }}
                      type="button"
                      className="btn btn-primary"
                      onClick={() => window.open(`${projetoPreferencias.link_proposta_redacao}`, '_blank')}
                    >Proposta de Redação
                    </button>
                  )}
                </div>
              </div>
              {limitar_tempo_avaliador && (
                <div className="col-12 col-12 col-md">
                  <Countdown
                    date={contador}
                    renderer={this.renderer}
                  />
                </div>
              )}
              <div className="col-12 col-12 col-md">
                <div className="text-breadcrumb right">
                  <span className="mr-3"><strong>{this.getLabelCorrigidas(tipo_cota)}: </strong> {total_correcoes_diarias}</span>
                  <span><strong>{labels.cota}: </strong> {cota_diaria}</span>
                </div>
              </div>
              {mostrarAbandonarBanca && (
                <div className="col-12 col-12 col-md text-right">
                  <AbandonarBancaButton correcaoId={correcaoId} onModalChange={() => this.setState({ showModal: true })} />
                </div>
              )}
            </div>
          </div>
        </div>
        <Rodal
          closeOnEsc
          visible={showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <div className="text-left">
            <p>Ao sair da 4ª correção, você e os auxiliares serão redirecionados à página inicial.</p>
            <p>A correção da redação atual será retomada ao se iniciar nova 4ª correção, clicando em &quot;buscar + 1&quot; novamente.</p>
          </div>
        </Rodal>
        <ModalConfirmacao
          visible={this.state.visible}
          onClose={this.handleLogout}
          title="Exercício final encerrado"
          content="Seu tempo para realizar o exercício esgotou."
          buttonFunction={this.handleLogout}
          textSim="OK"
        />
      </Fragment>
    );
  }
}


BreadcrumbCorrecao.propTypes = {
  id_correcao: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total_correcoes_diarias: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cota_diaria: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contador: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limitar_tempo_avaliador: PropTypes.oneOfType([PropTypes.bool], [PropTypes.number]),
  labels: PropTypes.shape({
    cota: PropTypes.string,
    corrigidasSemana: PropTypes.string,
    corrigidasDia: PropTypes.string,
    corrigidas: PropTypes.string,
  }),
  mostrarAbandonarBanca: PropTypes.bool,
  correcaoId: PropTypes.string.isRequired,
};

BreadcrumbCorrecao.defaultProps = {
  id_correcao: '...',
  total_correcoes_diarias: '...',
  cota_diaria: '...',
  limitar_tempo_avaliador: false,
  pendente: false,
  mostrarAbandonarBanca: false,
};

export default withRouter(BreadcrumbCorrecao);
